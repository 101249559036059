import React from 'react'

import Column from '../components/Column'
import Container from '../components/Container'
import Header from '../components/Header'
import Main from '../components/Main'
import PregnantList from '../components/PregnantList'
import PregnantProfile from '../components/PregnantProfile'
import Row from '../components/Row'

export default () => (
  <Container>
    <Header />
    <Main>
      <Row>
        <Column className="column-33">
          <PregnantList />
        </Column>
        <Column>
          <PregnantProfile />
        </Column>
      </Row>
    </Main>
  </Container>
)
