import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import PregnantType from '../types/pregnant'

export default (pregnant: PregnantType): [any] => {
  const { gestationId }: any = useParams()
  const [gestation, setGestation] = useState(null as any)

  useEffect(() => {
    const data = pregnant?.gestations?.filter(
      ({ uid }) => String(uid) === String(gestationId),
    )[0]
    setGestation(data)
  }, [gestationId, pregnant])

  return [gestation]
}
