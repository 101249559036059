import React from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Column from '../components/Column'
import Container from '../components/Container'
import Field from '../components/Field'
import Header from '../components/Header'
import Loading from '../components/Loading'
import Main from '../components/Main'
import Row from '../components/Row'
import usePregnant from '../hooks/pregnant'
import theme from '../utils/theme'

const Form = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  display: block;
  padding: ${theme?.main?.spacing} ${theme?.main?.spacing}
    calc(${theme?.main?.spacing} / 3);
  position: relative;
`
const Grid = styled.div`
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 49%;
    }
  }
`
const CustomGrid = styled(Grid)`
  @media (min-width: 480px) {
    & > div {
      width: 33%;
      &:first-child {
        width: 66%;
      }
    }
  }
`
const ButtonGroup = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  button + button {
    margin-left: 20px;
  }
`

export default () => {
  const [pregnant, handleChange, handleSubmit, handleBack] = usePregnant()

  if (pregnant == null) return <Loading />

  return (
    <Container>
      <Header />
      <Main>
        <Row>
          <Column>
            <Form>
              <Field label="Informações da paciente" type="legend" />
              <Field
                defaultValue={pregnant?.name}
                label="Nome*"
                name="name"
                onChange={handleChange}
                type="text"
              />
              <Grid>
                <Field
                  defaultValue={pregnant?.birthdate}
                  label="Data de nascimento*"
                  name="birthdate"
                  onChange={handleChange}
                  type="birthdate"
                />
                <Field
                  defaultValue={pregnant?.cpf}
                  label="CPF*"
                  name="cpf"
                  onChange={handleChange}
                  type="cpf"
                />
              </Grid>
              <Grid>
                <Field
                  defaultValue={pregnant?.phone}
                  label="Telefone*"
                  name="phone"
                  onChange={handleChange}
                  type="phone"
                />
                <Field
                  defaultValue={pregnant?.naturality}
                  label="Naturalidade"
                  name="naturality"
                  onChange={handleChange}
                  type="text"
                />
              </Grid>
              <Field
                defaultValue={pregnant?.address}
                label="Endereço"
                name="address"
                onChange={handleChange}
                type="text"
              />
              <Grid>
                <Field
                  defaultValue={pregnant?.job}
                  label="Profissão"
                  name="job"
                  onChange={handleChange}
                  type="text"
                />
                <Field
                  defaultValue={pregnant?.healthPlans}
                  label="Plano de Saúde"
                  name="healthPlans"
                  onChange={handleChange}
                  type="healthPlans"
                />
              </Grid>
              <CustomGrid>
                <Field
                  defaultValue={pregnant?.husband}
                  label="Nome do Companheiro"
                  name="husband"
                  onChange={handleChange}
                  type="text"
                />
                <Field
                  defaultValue={pregnant?.maritalStatus}
                  label="Estado Cívil"
                  name="maritalStatus"
                  onChange={handleChange}
                  type="maritalStatus"
                />
              </CustomGrid>
            </Form>
          </Column>
          <Column>
            <Form onSubmit={handleSubmit}>
              <Field label="História Obstétrica" type="legend" />
              <Field
                defaultValue={pregnant?.obstetricRisk}
                label="Risco Obstétrico (use ',' para separar os riscos)"
                name="obstetricRisk"
                onChange={handleChange}
                placeholder="ex: diabetes, obesidade, hipertensão"
                type="text"
              />
              <Field
                defaultValue={pregnant?.previousPathological}
                label="História patológica pregressa"
                name="previousPathological"
                onChange={handleChange}
                type="text"
              />
              <Field
                defaultValue={pregnant?.obstetricBackground}
                label="Antecedentes obstétricos"
                name="obstetricBackground"
                onChange={handleChange}
                type="text"
              />
              <Field
                defaultValue={pregnant?.vaccines}
                label="Vacinas"
                name="vaccines"
                onChange={handleChange}
                placeholder="Hepatite B, H1N1, DTPA"
                type="text"
              />
              <Field
                defaultValue={pregnant?.allergy}
                label="Alergias"
                name="allergy"
                onChange={handleChange}
                type="text"
              />
              <Field
                defaultValue={pregnant?.note}
                label="Observações"
                name="note"
                onChange={handleChange}
                type="text"
              />
            </Form>
          </Column>
        </Row>
        <ButtonGroup>
          <Button onClick={handleBack} size="large">
            Voltar
          </Button>
          <Button onClick={handleSubmit} size="large">
            Salvar
          </Button>
        </ButtonGroup>
      </Main>
    </Container>
  )
}
