import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  children?: any | any[]
  className?: string
  title?: string
}

const Box = styled.div`
  background: rgb(253, 242, 247);
  background: linear-gradient(
    0deg,
    rgba(253, 242, 247, 1) 0%,
    rgba(255, 247, 240, 1) 100%
  );
  border-radius: calc(${theme?.main?.radius} / 3);
  overflow: hidden;
  padding: calc(${theme?.main?.spacing} / 1.5);
  position: relative;
`
const Title = styled.div.attrs({
  className: 'Title',
})`
  color: ${theme?.color?.primary};
  display: block;
  font-weight: ${theme?.font?.bold};
  max-width: 83%;
  padding: 0 0 calc(${theme?.main?.spacing} / 2);
  text-transform: uppercase;
`

export default ({ children = '', className = '', title = '' }: PropType) => (
  <Box className={`Box ${className}`.trim()}>
    {title && <Title>{title}</Title>}
    {children}
  </Box>
)
