import React from 'react'
import styled from 'styled-components/macro'

type PropType = {
  children?: any
  className?: string
}

const List = styled.ul`
  list-style: none;
  &:last-child {
    margin-bottom: 0;
  }
`

export default ({ children = '', className = '' }: PropType) => (
  <List className={className}>{children}</List>
)
