import React from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Column from '../components/Column'
import Container from '../components/Container'
import Field from '../components/Field'
import Header from '../components/Header'
import Loading from '../components/Loading'
import Main from '../components/Main'
import Row from '../components/Row'
import useAuth from '../hooks/auth'
import useDoctor from '../hooks/doctor'
import useUpdateEmail from '../hooks/updateEmail'
import theme from '../utils/theme'

const Form = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  display: block;
  left: 50%;
  margin-bottom: 100px;
  max-width: 1000px;
  padding: ${theme?.main?.spacing} ${theme?.main?.spacing}
    calc(${theme?.main?.spacing} * -2);
  position: relative;
  text-align: center;
  transform: translateX(-50%);
  button {
    bottom: calc(${theme?.main?.spacing} * -3.25);
    left: 0;
    position: absolute;
  }
`
const Grid = styled.div`
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 32%;
    }
  }
`

export default () => {
  const [auth] = useAuth()
  const [doctor, handleChange, handleSubmit] = useDoctor()
  const [updateEmail, handleChangeEmail, handleSubmitEmail] = useUpdateEmail()

  if (!doctor?.uid) return <Loading />

  return (
    <Container>
      <Header />
      <Main>
        <Row>
          <Column>
            <Form onSubmit={handleSubmit}>
              <Field label="Informações pessoais" type="legend" />
              <Field
                defaultValue={doctor?.name}
                label="Digite seu nome*"
                name="name"
                onChange={handleChange}
                type="text"
              />
              <Grid>
                <Field
                  defaultValue={doctor?.birthdate}
                  label="Digite sua data de nascimento*"
                  name="birthdate"
                  onChange={handleChange}
                  type="birthdate"
                />
                <Field
                  defaultValue={doctor?.gender}
                  label="Digite seu género*"
                  name="gender"
                  onChange={handleChange}
                  type="genderTypes"
                />
                <Field
                  defaultValue={doctor?.cpf}
                  label="Digite seu CPF*"
                  name="cpf"
                  onChange={handleChange}
                  type="cpf"
                />
              </Grid>
              <Grid>
                <Field
                  defaultValue={doctor?.crm}
                  label="CRM*"
                  name="crm"
                  onChange={handleChange}
                  type="number"
                />
                <Field
                  defaultValue={doctor?.phone}
                  label="Digite seu telefone*"
                  name="phone"
                  onChange={handleChange}
                  type="phone"
                />
                <Field
                  defaultValue={String(doctor?.showPhone)}
                  label="Aceita receber ligação do paciente?*"
                  name="showPhone"
                  onChange={handleChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                  type="select"
                />
              </Grid>
              <Button size="large">Atualizar dados pessoais</Button>
            </Form>
          </Column>
          <Column className="column-33">
            <Form onSubmit={handleSubmitEmail}>
              <Field label="Informações do acesso" type="legend" />
              <Field
                defaultValue={auth?.email}
                disabled
                label="Seu email atual"
                name="currentEmail"
                type="email"
              />
              <Field
                defaultValue={updateEmail?.newEmail}
                name="newEmail"
                label="Digite seu novo email*"
                onChange={handleChangeEmail}
                type="email"
              />
              <Field
                defaultValue={updateEmail?.password}
                name="password"
                label="Digite sua senha*"
                onChange={handleChangeEmail}
                type="password"
              />
              <Button size="large">Atualizar email de acesso</Button>
            </Form>
          </Column>
        </Row>
      </Main>
    </Container>
  )
}
