import { init } from '@sentry/browser'

import isLocalhost from '../utils/localhost'

export default {
  initialize: () => {
    if (!isLocalhost) {
      return init({
        dsn: process?.env?.REACT_APP_APM,
        environment: process?.env?.NODE_ENV,
      })
    }
  },
}
