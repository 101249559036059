import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  children?: any
  className?: string
  onClick?: any
  size?: 'large' | 'normal' | 'small'
}

const Button = styled.button`
  align-items: center;
  background: ${theme?.color?.initial};
  border-color: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  color: ${theme?.color?.primary};
  font-size: 11px;
  font-weight: 700;
  height: 38px;
  letter-spacing: 1px;
  line-height: 1px;
  justify-content: center;
  padding: 0 30px;
  text-transform: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s linear;
  white-space: nowrap;
  &:hover {
    opacity: 0.5;
  }
`
const ButtonLarge = styled(Button)`
  height: 48px;
  padding: 0 50px;
`
const ButtonSmall = styled(Button)`
  height: 24px;
  padding: 0 15px;
`

export default ({
  children = '',
  className = '',
  onClick,
  size = 'normal',
}: PropType) =>
  size === 'large' ? (
    <ButtonLarge className={className} onClick={onClick}>
      {children}
    </ButtonLarge>
  ) : size === 'small' ? (
    <ButtonSmall className={className} onClick={onClick}>
      {children}
    </ButtonSmall>
  ) : (
    <Button className={className} onClick={onClick}>
      {children}
    </Button>
  )
