import React from 'react'
import styled from 'styled-components/macro'

import Link from '../components/Link'
import Icon from '../components/Icon'

type PropType = {
  href?: string
}

const Logo = styled(Link)`
  display: inline-block;
  height: 62.5px;
  overflow: hidden;
  position: relative;
  width: 175px;
  svg {
    height: 175px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &:hover {
      opacity: 0.15;
    }
  }
`

export default ({ href = '/' }: PropType) => (
  <Logo href={href}>
    <Icon type="logo" />
  </Logo>
)
