import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components/macro'

import Loading from '../components/Loading'
import useAuth from '../hooks/auth'
import ConsultationPage from '../pages/consultation'
import LoginPage from '../pages/login'
import PregnantEditPage from '../pages/pregnantEdit'
import PregnantsPage from '../pages/pregnants'
import RecoverPage from '../pages/recover'
import RegisterDoctorPage from '../pages/registerDoctor'
import RegisterPregnantPage from '../pages/registerPregnant'
import SettingsPage from '../pages/settings'
import tracker from '../utils/tracker'
import theme from '../utils/theme'

const GlobalStyle = createGlobalStyle`
  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  body {
    background: #fae1e3;
    font-size: 15px;
    font-weight: ${theme?.font?.regular};
  }

  .swal-modal {
    width: 500px;
    .swal-title {
      color: ${theme?.color?.primary};
    }
    .swal-footer {
      text-align: center;
    }
    .swal-button {
      background: ${theme?.color?.primary};
      border-radius: 20px;
      line-height: 1;
      font-size: 15px;
      font-weight: 600;
      text-transform: capitalize;
      &:not([disabled]):hover {
        background: ${theme?.color?.primary};
        opacity: .75;
      }
      &.swal-button--cancel {
        background: ${theme?.color?.quaternary};
        &:not([disabled]):hover {
          background: ${theme?.color?.quaternary};
          opacity: .75;
        }
      }
    }
  }
`

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  tracker(rest?.location?.pathname)
  const [auth] = useAuth()
  if (auth == null) return <Loading />

  return (
    <Route
      {...rest}
      render={props =>
        auth.uid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}
const PublicRoute = ({ component: Component, ...rest }: any) => {
  tracker(rest?.location?.pathname)

  return <Route {...rest} render={props => <Component {...props} />} />
}
const RedictRoute = ({ component: Component, ...rest }: any) => {
  tracker(rest?.location?.pathname)

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )}
    />
  )
}

export default () => (
  <BrowserRouter>
    <GlobalStyle />
    <Switch>
      <PrivateRoute
        component={PregnantEditPage}
        exact
        path="/:doctorId/pregnants/:pregnantId/edit"
      />
      <PrivateRoute
        component={ConsultationPage}
        exact
        path="/:doctorId/pregnants/:pregnantId/gestations/:gestationId"
      />
      <PrivateRoute
        component={PregnantsPage}
        exact
        path={['/:doctorId/pregnants', '/:doctorId/pregnants/:pregnantId']}
      />
      <PrivateRoute
        component={RegisterPregnantPage}
        exact
        path="/:doctorId/register"
      />
      <PrivateRoute component={SettingsPage} exact path="/:doctorId/settings" />
      <PublicRoute component={LoginPage} exact path="/login" />
      <PublicRoute component={RecoverPage} exact path="/recuperar-senha" />
      <PublicRoute component={RegisterDoctorPage} exact path="/registrar" />
      <PublicRoute component={RedictRoute} exact path="/" />
      <PublicRoute component={RedictRoute} />
    </Switch>
  </BrowserRouter>
)
