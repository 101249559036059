import AccompanimentType from '../types/accompaniment'
import ConfigType from '../types/config'
import DoctorType from '../types/doctor'
import EventType from '../types/event'
import ExamType from '../types/exam'
import NoteType from '../types/note'
import PregnantType from '../types/pregnant'
import ReminderType from '../types/reminder'
import UltrasonogramType from '../types/ultrasonogram'

export function parseArray (
  value: any = [],
  date: string = 'createdAt',
): Array<any> {
  if (!value || typeof value !== 'object') return []
  return Object?.values(value || {})?.sort(
    (a: any, b: any) =>
      // @ts-ignore
      new Date(b[String(date)]) - new Date(a[String(date)]),
  )
}

export function parseHeight (value: any): number {
  return parseInt(String(value).replace(/\D/g, ''))
}

export function parseWeight (value: any): number {
  return parseFloat(
    (parseInt(String(value).replace(/\D/g, '')) / 10).toFixed(1),
  )
}

export function config (config: any): ConfigType {
  return {
    ...config,
    birthTypes: parseArray(config?.birthTypes || {}) as Array<any>,
    genderTypes: parseArray(config?.genderTypes || {}) as Array<any>,
    gestationalWeeks: parseArray(config?.gestationalWeeks || {}) as Array<any>,
    healthPlans: parseArray(config?.healthPlans || {}) as Array<any>,
    maritalStatus: parseArray(config?.maritalStatus || {}) as Array<any>,
    gestationTypes: parseArray(config?.gestationTypes || {}) as Array<any>,
    birthResults: parseArray(config?.birthResults || {}) as Array<any>,
    examOptions1: parseArray(config?.examOptions1 || {}) as Array<any>,
    examOptions2: parseArray(config?.examOptions2 || {}) as Array<any>,
  } as ConfigType
}

export function doctor (doctor: any): DoctorType {
  return {
    ...doctor,
    pregnants: parseArray(doctor?.pregnant) as Array<string>,
  } as DoctorType
}

export function pregnant (pregnant: any): PregnantType {
  const sortGestation = (a: any, b: any) =>
    // @ts-ignore
    new Date(b?.dum || b?.createdAt) - new Date(a?.dum || a?.createdAt)

  const data = {
    ...pregnant,
    events: parseArray(pregnant?.events, 'date') as Array<EventType>,
    notes: parseArray(pregnant?.notes) as Array<NoteType>,
    reminders: parseArray(pregnant?.reminders, 'date') as Array<ReminderType>,
    gestations: parseArray(pregnant?.gestations)
      ?.sort(sortGestation)
      ?.map((gestation: any) => ({
        ...gestation,
        accompaniments: parseArray(gestation?.accompaniments, 'date') as Array<
          AccompanimentType
        >,
        exams: parseArray(gestation?.exams, 'date') as Array<ExamType>,
        ultrasonograms: parseArray(gestation?.ultrasonograms, 'date') as Array<
          UltrasonogramType
        >,
      })),
  } as PregnantType

  return fixMask(data)
}

export function authorizedUsers (users: any): Array<String> {
  return Object?.values(users || {}) as Array<String>
}

function fixMask (pregnant: PregnantType): PregnantType {
  const fix = (value?: string): string => String(value || '').replace(/_/g, '')

  return {
    ...pregnant,
    gestations: pregnant?.gestations?.map(gestation => {
      gestation.height = fix(gestation?.height)
      gestation.weight = fix(gestation?.weight)
      return {
        ...gestation,
        accompaniments: gestation?.accompaniments?.map(accompaniment => {
          accompaniment.bloodPressure = fix(accompaniment?.bloodPressure)
          accompaniment.weight = fix(accompaniment?.weight)
          accompaniment.fetalWeight = fix(accompaniment?.fetalWeight)
          accompaniment.uterineHeight = fix(accompaniment?.uterineHeight)
          return accompaniment
        }),
      }
    }),
  } as PregnantType
}

export default {
  config,
  doctor,
  parseArray,
  parseHeight,
  parseWeight,
  pregnant,
  authorizedUsers,
}
