export const validation = (cpf: string): boolean => {
  var result: boolean = true
  cpf = String(cpf).replace(/\D/g, '')
  if (String(cpf).length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false
  }

  ;[9, 10].forEach((j: number) => {
    var soma = 0
    var r
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach((e, i) => {
        soma += parseInt(e) * (j + 2 - (i + 1))
      })
    r = soma % 11
    r = r < 2 ? 0 : 11 - r
    if (String(r) !== cpf.substring(j, j + 1)) {
      result = false
    }
  })
  return result
}

export default {
  validation,
}
