import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import RegisterGestationType from '../types/registerGestation'
import api from '../utils/api'
import dialog from '../utils/dialog'

const initialRegisterGestation = {
  height: '',
  weight: '',
  name: '',
  birthType: 'Vaginal',
  place: '',
  dum: '',
  d1usg: '',
  ig1usg: '',
  obstetricRisk: '',
  previousPathological: '',
  obstetricBackground: '',
  vaccines: '',
  allergy: '',
  note: '',
  gestationType: 'Única',
  isFinished: false,
}

export default (): [RegisterGestationType, any, any] => {
  const history = useHistory()
  const { doctorId, pregnantId }: any = useParams()
  const [registerGestation, setRegisterGestation] = useState(
    initialRegisterGestation,
  )

  useEffect(() => {
    api
      .getPregnant(pregnantId)
      .then(
        ({
          obstetricRisk = '',
          previousPathological = '',
          obstetricBackground = '',
          vaccines = '',
          allergy = '',
          note = '',
        }) => {
          setRegisterGestation({
            ...initialRegisterGestation,
            obstetricRisk,
            previousPathological,
            obstetricBackground,
            vaccines,
            allergy,
            note,
          } as any)
        },
      )
  }, [pregnantId, history])

  const handleChange = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      let { name, value } = event?.target
      var data = { ...registerGestation, [String(name)]: value }
      setRegisterGestation(data)
    },
    [registerGestation],
  )

  const handleSubmit = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      dialog?.confirm(() => {
        api
          .registerGestation(doctorId, pregnantId, registerGestation)
          .then(gestation => {
            if (!gestation?.uid) return
            history?.push(
              `/${doctorId}/pregnants/${pregnantId}/gestations/${gestation?.uid}`,
            )
            // to improve
            setTimeout(() => history?.go(0), 200)
          })
      })
    },
    [doctorId, pregnantId, registerGestation, history],
  )

  return [registerGestation, handleChange, handleSubmit]
}
