import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  children?: any
  className?: string
  maxWidth?: string
}

const Container = styled.div.attrs(({ className }) => ({
  className: `container ${className}`,
}))`
  max-width: ${({ title }) => (title ? title : `${theme?.main?.width}`)};
  position: relative;
`

export default ({ children = '', className = '', maxWidth }: PropType) => (
  <Container className={className} title={maxWidth}>
    {children}
  </Container>
)
