import React from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Column from '../components/Column'
import Field from '../components/Field'
import Logo from '../components/Logo'
import Row from '../components/Row'
import useRegister from '../hooks/registerDoctor'
import theme from '../utils/theme'

const Register = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  display: block;
  left: 50%;
  max-width: 500px;
  padding: calc(${theme?.main?.spacing} / 2) ${theme?.main?.spacing}
    ${theme?.main?.spacing};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  button {
    bottom: calc(${theme?.main?.spacing} * -3.25);
    left: 0;
    position: absolute;
  }
  a {
    margin: calc(${theme?.main?.spacing} * 0.5) 0;
  }
`

export default () => {
  const [doctor, handleChange, handleSubmit] = useRegister()

  return (
    <Register onSubmit={handleSubmit}>
      <Logo />
      <Field
        defaultValue={doctor?.name}
        label="Digite seu nome*"
        name="name"
        onChange={handleChange}
        type="text"
      />
      <Row>
        <Column>
          <Field
            defaultValue={doctor?.birthdate}
            label="Digite sua data de nascimento*"
            name="birthdate"
            onChange={handleChange}
            type="birthdate"
          />
        </Column>
        <Column>
          <Field
            defaultValue={doctor?.gender}
            label="Digite seu género*"
            name="gender"
            onChange={handleChange}
            type="genderTypes"
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Field
            defaultValue={doctor?.cpf}
            label="Digite seu CPF*"
            name="cpf"
            onChange={handleChange}
            type="cpf"
          />
        </Column>
        <Column>
          <Field
            defaultValue={doctor?.crm}
            label="CRM*"
            name="crm"
            onChange={handleChange}
            type="number"
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Field
            defaultValue={doctor?.phone}
            label="Digite seu telefone*"
            name="phone"
            onChange={handleChange}
            type="phone"
          />
        </Column>
        <Column>
          <Field
            defaultValue={String(doctor?.showPhone)}
            label="Compartilhar telefone*"
            name="showPhone"
            onChange={handleChange}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            type="select"
          />
        </Column>
      </Row>
      <Field
        defaultValue={doctor?.email}
        label="Digite seu email*"
        name="email"
        onChange={handleChange}
        type="email"
      />
      <Field
        defaultValue={doctor?.cpf}
        label="Digite sua senha*"
        name="password"
        onChange={handleChange}
        type="password"
      />
      <Button size="large">Registrar</Button>
    </Register>
  )
}
