export const color = {
  initial: '#fff',
  primary: '#af4461',
  secondary: '#fae1e3',
  tertiary: '#7c7c7c',
  quaternary: '#dedede',
  fifternary: '#5a5a5a',
  shadow: 'rgba(#af4461, .15)',
  gradient: 'linear-gradient(105deg, #f2c9cd 0%, #af4461 100%)',
  green: '#849484',
  pink: '#D77995',
  alert: '#af4461',
}

export const font = {
  thin: '200',
  regular: '400',
  bold: '600',
  black: '800',
  tiny: '10px',
  small: '12px',
  normal: '14px',
  large: '16px',
  extra: '18px',
  primary: `-apple-system, system-ui, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  secondary: `'Lucida Console', Monaco, monospace`,
}

export const main = {
  radius: '30px',
  spacing: '25px',
  width: '1440px',
}

export default {
  color,
  font,
  main,
}
