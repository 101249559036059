import { useEffect, useState } from 'react'

import MainUltrasonogramType from '../types/mainUltrasonogram'
import date from '../utils/date'

export default (
  defaultValue?: MainUltrasonogramType,
  mainValue?: MainUltrasonogramType,
): [MainUltrasonogramType | undefined, any, any, any, any] => {
  const [ultrasonograms, setUltrasonograms] = useState(defaultValue)

  useEffect(() => {
    setUltrasonograms({
      morfo1t: {
        date: defaultValue?.morfo1t?.date || mainValue?.morfo1t?.date,
        name: defaultValue?.morfo1t?.name || mainValue?.morfo1t?.name,
        status: defaultValue?.morfo1t?.status || mainValue?.morfo1t?.status,
        igWeek: defaultValue?.morfo1t?.igWeek || mainValue?.morfo1t?.igWeek,
        igDay: defaultValue?.morfo1t?.igDay || mainValue?.morfo1t?.igDay,
        details: defaultValue?.morfo1t?.details || mainValue?.morfo1t?.details,
      },
      morfo2t: {
        date: defaultValue?.morfo2t?.date || mainValue?.morfo2t?.date,
        name: defaultValue?.morfo2t?.name || mainValue?.morfo2t?.name,
        status: defaultValue?.morfo2t?.status || mainValue?.morfo2t?.status,
        igWeek: defaultValue?.morfo2t?.igWeek || mainValue?.morfo2t?.igWeek,
        igDay: defaultValue?.morfo2t?.igDay || mainValue?.morfo2t?.igDay,
        details: defaultValue?.morfo2t?.details || mainValue?.morfo2t?.details,
      },
      ecofetal: {
        date: defaultValue?.ecofetal?.date || mainValue?.ecofetal?.date,
        name: defaultValue?.ecofetal?.name || mainValue?.ecofetal?.name,
        status: defaultValue?.ecofetal?.status || mainValue?.ecofetal?.status,
        igWeek: defaultValue?.ecofetal?.igWeek || mainValue?.ecofetal?.igWeek,
        igDay: defaultValue?.ecofetal?.igDay || mainValue?.ecofetal?.igDay,
        details:
          defaultValue?.ecofetal?.details || mainValue?.ecofetal?.details,
      },
      doppler: {
        date: defaultValue?.doppler?.date || mainValue?.doppler?.date,
        name: defaultValue?.doppler?.name || mainValue?.doppler?.name,
        status: defaultValue?.doppler?.status || mainValue?.doppler?.status,
        igWeek: defaultValue?.doppler?.igWeek || mainValue?.doppler?.igWeek,
        igDay: defaultValue?.doppler?.igDay || mainValue?.doppler?.igDay,
        details: defaultValue?.doppler?.details || mainValue?.doppler?.details,
      },
    })
  }, [defaultValue, mainValue])

  const morfo1t = (dum: string): string =>
    dum
      ? `${date.getDumDiffWeeks(dum, 12)} à ${date.getDumDiffWeeks(dum, 14)}`
      : 'Aguardando dados'
  const morfo2t = (dum: string): string =>
    dum
      ? `${date.getDumDiffWeeks(dum, 20)} à ${date.getDumDiffWeeks(dum, 24)}`
      : 'Aguardando dados'
  const ecofetal = (dum: string): string =>
    dum
      ? `${date.getDumDiffWeeks(dum, 24)} à ${date.getDumDiffWeeks(dum, 30)}`
      : 'Aguardando dados'
  const doppler = (dum: string): string =>
    dum
      ? `${date.getDumDiffWeeks(dum, 32)} à ${date.getDumDiffWeeks(dum, 36)}`
      : 'Aguardando dados'

  return [ultrasonograms, morfo1t, morfo2t, ecofetal, doppler]
}
