import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import LoginType from '../types/login'
import api from '../utils/api'

const initialLogin = {
  email: '',
  password: '',
} as LoginType

export default (): [LoginType, any, any] => {
  const history = useHistory()
  const [login, setLogin] = useState(initialLogin)

  const handleChange = useCallback(
    async (event: any) => {
      event?.preventDefault()
      event?.stopPropagation()
      const { name, value } = event?.target
      setLogin({ ...login, [String(name)]: value })
    },
    [login],
  )

  const handleSubmit = useCallback(
    async (event: any) => {
      event?.preventDefault()
      event?.stopPropagation()
      api.login(login).then(doctor => {
        if (!doctor?.uid || !doctor?.crm) return
        history?.push(`/${doctor?.uid}/pregnants`)
      })
    },
    [history, login],
  )

  return [login, handleChange, handleSubmit]
}
