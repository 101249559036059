import React from 'react'

import Button from './Button'
import Field from './Field'
import Modal from './Modal'
import useFinishGestation from '../hooks/finishGestation'

type PropType = {
  pregnant: any
  gestation: any
  onClick: any
  open: boolean
}

export default ({ pregnant, gestation, onClick, open = false }: PropType) => {
  const [finishGestation, handleChange, handleSubmit] = useFinishGestation(
    pregnant,
    gestation,
  )

  if (!open) return <></>

  return (
    <Modal width={480} onClick={onClick} title="Finalizar gestação atual">
      <>
        <form onSubmit={handleSubmit}>
          <Field
            defaultValue={finishGestation?.deliveryDate || ''}
            label="Data de encerramento"
            name="deliveryDate"
            onChange={handleChange}
            type="date"
          />
          <Field
            defaultValue={finishGestation?.result || finishGestation?.birthType}
            label="Resultado"
            name="result"
            onChange={handleChange}
            type="birthResults"
          />
          <Field
            defaultValue={finishGestation?.place || ''}
            label="Local do parto"
            name="place"
            onChange={handleChange}
            type="text"
          />
          <Field
            defaultValue={finishGestation?.finalWeight || ''}
            label="Peso do bebê"
            name="finalWeight"
            onChange={handleChange}
            type="babyWeight"
          />
          <Button>Salvar</Button>
        </form>
      </>
    </Modal>
  )
}
