export function bloodPressure (value: string): string {
  const numbers = String(value).replace(/\D/g, '')

  if (
    (numbers.length === 3 || numbers.length === 4) &&
    parseInt(numbers.substr(0, 3)) > 300
  )
    return numbers.replace(/^(\d{2})(\d)/, '$1/$2')

  return numbers.replace(/^(\d{3})(\d)/, '$1/$2')
}

export function cpf (value: string): string {
  return String(value)
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{2})$/, '$1-$2')
}

export function fetalWeight (event: any): string {
  event.currentTarget.maxLength = 4
  event.currentTarget.value = String(event?.currentTarget?.value).replace(
    /\D/g,
    '',
  )
  if (event?.currentTarget?.length > 4)
    event.currentTarget.value = event?.currentTarget?.value?.slice(0, 3)
  return event
}

export function height (event: any): string {
  event.currentTarget.maxLength = 3
  event.currentTarget.value = String(event?.currentTarget?.value).replace(
    /\D/g,
    '',
  )
  if (event?.currentTarget?.length > 3)
    event.currentTarget.value = event?.currentTarget?.value?.slice(0, 2)
  return event
}

export function phone (value: string): string {
  return String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(\d{5})(\d{4})$/, '$1-$2')
}

export function weight (event: any): string {
  event.currentTarget.maxLength = 5
  if (event?.key === 'Backspace' || event?.key === 'Delete') return ''
  event.currentTarget.value = String(event?.currentTarget?.value)
    .replace(/\D/g, '')
    .replace(/(\d)(\d{1})$/, '$1,$2')
  return event
}

export function uterineHeight (event: any): string {
  event.currentTarget.maxLength = 4
  if (event?.key === 'Backspace' || event?.key === 'Delete') return ''
  event.currentTarget.value = String(event?.currentTarget?.value)
    .replace(/\D/g, '')
    .replace(/(\d)(\d{1})$/, '$1,$2')
  return event
}

export function babyWeight (event: any): string {
  event.currentTarget.maxLength = 4
  event.currentTarget.value = String(event?.currentTarget?.value).replace(
    /\D/g,
    '',
  )
  if (event?.currentTarget?.length > 4)
    event.currentTarget.value = event?.currentTarget?.value?.slice(0, 3)
  return event
}

export default {
  bloodPressure,
  cpf,
  fetalWeight,
  height,
  phone,
  weight,
  uterineHeight,
  babyWeight,
}
