import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import Logo from '../components/Logo'
import Loading from '../components/Loading'
import useDoctor from '../hooks/doctor'
import useLogout from '../hooks/logout'
import theme from '../utils/theme'

const Header = styled.header`
  align-items: center;
  background: ${theme?.color?.initial};
  border-bottom-left-radius: ${theme?.main?.radius};
  border-bottom-right-radius: ${theme?.main?.radius};
  box-shadow: 0 01px 10px 01px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0 30px 10px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(${theme?.main?.width} - 40px);
  max-width: 100%;
  z-index: 2;
`
const HeaderMenu = styled.div`
  text-align: right;
`
const CustomButton = styled.span`
  cursor: pointer;
  text-transform: lowercase;
  &:hover {
    opacity: 0.3;
  }
`

export default () => {
  const history = useHistory()
  const [doctor] = useDoctor()
  const [handleLogout] = useLogout()

  if (!doctor?.uid) return <Loading />

  return (
    <Header>
      <Logo href={doctor ? `/${doctor?.uid}/pregnants` : '/'} />
      <HeaderMenu>
        <div>
          <strong>
            {doctor == null
              ? 'loading..'
              : `Dr${doctor.gender === 'female' ? 'a' : ''}. ${doctor.name}`}
          </strong>
        </div>
        {window.location.pathname.includes('settings') ? (
          <CustomButton onClick={() => history?.goBack()}>Voltar</CustomButton>
        ) : (
          <CustomButton
            onClick={() => history?.push(`/${doctor.uid}/settings`)}
          >
            Configurações
          </CustomButton>
        )}
        {' | '}
        <CustomButton onClick={() => handleLogout()}>sair</CustomButton>
      </HeaderMenu>
    </Header>
  )
}
