import date from '../utils/date'
import imc from '../utils/imc'
import normalize from '../utils/normalize'
import number from '../utils/number'

const weightGainExpected = (height: string, weight: string): Array<number> => {
  const currentIMC = imc(height, weight)
  if (!height || !weight || !currentIMC) return []
  if (currentIMC < 20) return [12.5, 18]
  if (currentIMC >= 20 && currentIMC < 26) return [11.5, 16]
  if (currentIMC >= 26 && currentIMC < 29) return [7, 11.5]
  return [5, 9]
}

export default (): [any, any, any, any, any, any] => {
  const getIgdum = (startDate: string = '', endDate: string = ''): string => {
    if (!startDate || !endDate) return ''

    const days = date.daysDiff(new Date(startDate), new Date(endDate))
    const rest = days % 7 !== 0 ? `${days % 7}/7` : ''

    return `${parseInt(String(days / 7).replace('-', ''))} ${String(
      rest,
    ).replace('-', '')}`
  }

  const getIg1usg = (
    startDate: string = '',
    endDate: string = '',
    ig1usg: number = 0,
  ): string => {
    if (!startDate || !endDate || !ig1usg) return ''

    const days =
      date.daysDiff(new Date(startDate), new Date(endDate)) + ig1usg * 7
    const rest = days % 7 !== 0 ? `${parseInt(String(days % 7))}/7` : ''

    return `${parseInt(String(days / 7).replace('-', ''))} ${String(
      rest,
    ).replace('-', '')}`
  }

  const getDiffWeight = (
    initialWeight: any = '',
    currentWeight: any = '',
  ): string => {
    if (!initialWeight || !currentWeight) return ''
    let diff = `${normalize?.parseWeight(currentWeight) -
      normalize?.parseWeight(initialWeight)}`

    diff = parseFloat(diff).toFixed(1)

    return `${
      !diff.includes('-') && diff !== '0' ? `+${diff}` : diff
    } kg`.replace('.', ',')
  }

  const getDiffFetalWeight = (
    initialWeight: any = '',
    currentWeight: any = '',
  ): string => {
    if (!initialWeight || !currentWeight) return ''
    const diff = `${number?.parse(currentWeight) -
      number?.parse(initialWeight)}`
    return `${!diff.includes('-') && diff !== '0' ? `+${diff}` : diff} g`
  }

  const checkBloodPressure = (value: string = ''): boolean => {
    let [first, second] = value?.split('/')
    first = String(number?.parse(first))
    second = String(number?.parse(second))
    if (!value || !first || first.length < 2 || !second || second.length < 1)
      return false
    return parseInt(first) >= 140 || parseInt(second) >= 90 ? true : false
  }

  const checkWeightGain = (
    height: string = '',
    initialWeight: string = '',
    currentWeight: string = '',
  ): boolean => {
    if (!initialWeight || !currentWeight || !height) return false
    const weightGain =
      normalize?.parseWeight(currentWeight) -
      normalize?.parseWeight(initialWeight)
    const [minor, major] = weightGainExpected(height, initialWeight)
    return !major || weightGain < major ? false : true
  }

  return [
    getIgdum,
    getIg1usg,
    getDiffWeight,
    getDiffFetalWeight,
    checkBloodPressure,
    checkWeightGain,
  ]
}
