import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import Box from '../components/Box'
import Loading from '../components/Loading'
import NewGestationModal from '../components/NewGestationModal'
import PregnantType from '../types/pregnant'
import theme from '../utils/theme'
import FinishGestationModal from './FinishGestationModal'
import useGestation from '../hooks/gestation'

type PropType = {
  pregnant: PregnantType
}

const GestationTabs = styled.div`
  display: flex;
`
const Tab = styled(Box)`
  background: transparent;
  color: ${theme?.color?.primary};
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  text-transform: uppercase;
  &.true,
  &:hover {
    background: rgb(253, 242, 247);
    background: linear-gradient(
      0deg,
      rgba(253, 242, 247, 1) 0%,
      rgba(255, 247, 240, 1) 100%
    );
  }
`
const ButtonTab = styled(Box)`
  background: ${theme?.color?.primary};
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  text-transform: uppercase;
`
const Tabs = styled.div`
  overflow-x: none;
  max-width: 100%;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &:-webkit-scrollbar {
    display: none;
  }
`
const Plus = styled.span``

const getGestationName = (
  value: number = -1,
  size: number = 0,
  isFinished: boolean = false,
) => {
  if (value === -1 || size === 0 || value === 0)
    return isFinished ? `Última Gestação` : `Gestação Atual`
  return `${size - value}º Gestação`
}

export default ({ pregnant }: PropType) => {
  const history = useHistory()
  const { doctorId, gestationId, pregnantId }: any = useParams()
  const [gestation] = useGestation(pregnant)

  const [toggleAddModal, setToggleAddModal] = useState(false)
  const [toggleFinishModal, setToggleFinishModal] = useState(false)

  if (
    !pregnant ||
    !Array.isArray(pregnant?.gestations) ||
    !pregnant?.gestations?.length
  )
    return <Loading />

  return (
    <GestationTabs>
      {pregnant?.gestations?.[0]?.isFinished ? (
        <Plus onClick={() => setToggleAddModal(true)}>
          <ButtonTab>
            <span>+</span>
          </ButtonTab>
        </Plus>
      ) : (
        <Plus onClick={() => setToggleFinishModal(true)}>
          <ButtonTab>
            <span>Finalizar Gestação</span>
          </ButtonTab>
        </Plus>
      )}
      <NewGestationModal
        onClick={() => setToggleAddModal(false)}
        open={toggleAddModal}
      />
      <FinishGestationModal
        pregnant={pregnant}
        gestation={gestation}
        onClick={() => setToggleFinishModal(false)}
        open={toggleFinishModal}
      />
      <Tabs>
        {pregnant?.gestations?.map(({ uid, isFinished }, index, array) => (
          <Tab className={String(uid === gestationId)} key={uid}>
            <span
              onClick={() =>
                history?.push(
                  `/${doctorId}/pregnants/${pregnantId}/gestations/${uid}`,
                )
              }
            >
              {getGestationName(index, array.length, isFinished)}
            </span>
          </Tab>
        ))}
      </Tabs>
    </GestationTabs>
  )
}
