import React from 'react'
import styled from 'styled-components/macro'

import Box from '../components/Box'
import Field from '../components/Field'
import List from '../components/List'
import useMainUltrasonograms from '../hooks/mainUltrasonograms'
import MainUltrasonogramType from '../types/mainUltrasonogram'
import UltrasonogramType from '../types/ultrasonogram'
import dateUtils from '../utils/date'
import theme from '../utils/theme'

type PropType = {
  isFinished?: boolean
  defaultValue?: {
    mainUltrasonograms?: MainUltrasonogramType
    ultrasonogram?: UltrasonogramType
  }
  dum?: string
  mainUltrasonograms?: MainUltrasonogramType
  onChange?: any
  ultrasonograms?: Array<UltrasonogramType>
}

const Ultrasonogram = styled.div`
  ul {
    height: 430px;
    margin-bottom: 00px;
    overflow: hidden;
    overflow-y: scroll;
  }
`
const Item = styled.li`
  background: ${theme?.color?.initial};
  border-radius: calc(${theme?.main?.radius} / 3);
  // cursor: pointer;
  display: block;
  margin-bottom: calc(${theme?.main?.spacing} / 2);
  padding: calc(${theme?.main?.spacing} / 2);
  // &:hover {
  //   opacity: .6;
  // }
`
const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin: 10px 0;
  width: 90% !important;
`
const Title = styled.div`
  color: ${theme?.color?.primary};
  display: block;
  font-weight: ${theme?.font?.bold};
  text-transform: uppercase;
`
const Name = styled.div`
  color: ${theme?.color?.primary};
`
const Value = styled.div`
  color: ${theme?.color?.tertiary};
`
const Status = styled.div`
  div {
    display: inline;
  }
`
const FieldDate = styled(Field)`
  border: none !important;
  height: 22px !important;
  margin: 0 !important;
  padding: 0 0 0 5px !important;
  width: 100% !important;
  &:disabled {
    background: transparent !important;
    border: none !important;
  }
`

const FieldTitle = styled(Field)`
  border: none !important;
  height: 22px !important;
  color: ${theme?.color?.primary};
  display: block;
  font-weight: ${theme?.font?.bold};
  padding: 0 !important;
  margin: 0 !important;
  text-transform: uppercase;
`

const FieldStatus = styled(Field)`
  height: 22px !important;
  margin: 0 !important;
  padding: 0 10px 0 5px !important;
  display: inline-block;
  width: auto !important;
  &:disabled {
    opacity: 1;
    background: transparent !important;
    border: none !important;
  }
`
const Line = styled.div`
  color: ${theme?.color?.primary};
  display: block;
  font-weight: ${theme?.font?.bold};
  height: 1px;
  line-height: 1;
  width: 100%;
  padding: 20px 0 35px;
  text-transform: uppercase;
`
const optionsStatus = [
  { label: 'Normal', value: true },
  { label: 'Alterado', value: false },
]
const FieldText = styled(Field)`
  text-align: center;
  height: 22px !important;
  margin: 0 !important;
  padding: 0 0 0 0 !important;
  width: 35px !important;
  &:disabled {
    background: transparent !important;
    border: none !important;
  }
`
const FieldDetails = styled(FieldText)`
text-align: left;
height: 27px !important;
padding: 5px 5px !important;
width: 95% !important;
position: relative;
`

function getAge(igWeek: string = '0', igDay: string = '0'): string {
  return (
    (igWeek && igWeek > '0'
      ? igWeek + (igWeek > '1' ? ' semanas' : ' semana')
      : '') +
    (igWeek && igWeek > '0' && igDay && igDay > '0' ? ' e ' : '') +
    (igDay && igDay > '0' ? igDay + (igDay > '1' ? ' dias' : ' dia') : '')
  )
}

export default ({
  isFinished = false,
  defaultValue,
  dum = '',
  mainUltrasonograms,
  onChange,
  ultrasonograms = [],
}: PropType) => {
  const [
    reduceUltrasonograms,
    morfo1t,
    morfo2t,
    ecofetal,
    doppler,
  ] = useMainUltrasonograms(
    defaultValue?.mainUltrasonograms,
    mainUltrasonograms,
  )

  return (
    <Ultrasonogram>
      <Box title="Ultrassonografias">
        <List>
          <Item>
            <Title>{reduceUltrasonograms?.morfo1t?.name}</Title>
            {!isFinished ? (
              <Grid>
                <div>
                  <Name>Data:</Name>
                  <Value>
                    <FieldDate
                      defaultValue={reduceUltrasonograms?.morfo1t?.date}
                      max={
                        dum
                          ? dateUtils?.min(
                            dateUtils?.getSumByDay(dum, 280),
                            dateUtils?.getNewDate(),
                          )
                          : dateUtils?.getNewDate()
                      }
                      name="mainUltrasonograms.morfo1t.date"
                      onChange={onChange}
                      type="date"
                    />
                  </Value>
                </div>
                {reduceUltrasonograms?.morfo1t?.date ? (
                  <div>
                    <Name>Status:</Name>{' '}
                    <Value>
                      <FieldStatus
                        defaultValue={reduceUltrasonograms?.morfo1t?.status}
                        name="mainUltrasonograms.morfo1t.status"
                        onChange={onChange}
                        options={optionsStatus}
                        type="select"
                      />
                    </Value>
                  </div>
                ) : null}
              </Grid>
            ) : (
                <Grid>
                  <Status>
                    <Name>Data: </Name>
                    <Value>
                      <strong>
                        {reduceUltrasonograms?.morfo1t?.date
                          ? dateUtils?.parse(reduceUltrasonograms?.morfo1t?.date)
                          : 'Não Inserido'}
                      </strong>
                    </Value>
                  </Status>
                  {reduceUltrasonograms?.morfo1t?.date ? (
                    <Status>
                      <Name>Status: </Name>{' '}
                      <Value>
                        <strong>
                          {reduceUltrasonograms?.morfo1t?.status === 'true'
                            ? 'Normal'
                            : 'Alterado'}
                        </strong>
                      </Value>
                    </Status>
                  ) : null}
                </Grid>
              )}
            {reduceUltrasonograms?.morfo1t?.date ? (
              !isFinished ? (
                <div>
                  <Name>Idade Gestacional:</Name>
                  <Value>
                    <Status>
                      <FieldText
                        name="mainUltrasonograms.morfo1t.igWeek"
                        defaultValue={reduceUltrasonograms?.morfo1t?.igWeek}
                        onChange={onChange}
                        max="42"
                        type="number"
                      />
                      <Value>
                        <strong> semanas e </strong>
                      </Value>
                      <FieldText
                        defaultValue={reduceUltrasonograms?.morfo1t?.igDay}
                        name="mainUltrasonograms.morfo1t.igDay"
                        onChange={onChange}
                        max="6"
                        type="number"
                      />
                      <Value>
                        <strong> dias</strong>
                      </Value>
                    </Status>
                  </Value>
                </div>
              ) : (reduceUltrasonograms?.morfo1t?.igWeek &&
                reduceUltrasonograms?.morfo1t?.igWeek !== '0') ||
                (reduceUltrasonograms?.morfo1t?.igDay &&
                  reduceUltrasonograms?.morfo1t?.igDay !== '0') ? (
                    <Status>
                      <Name>Idade Gestacional: </Name>
                      <Value>
                        <Status>
                          <strong>
                            {getAge(
                              reduceUltrasonograms?.morfo1t?.igWeek,
                              reduceUltrasonograms?.morfo1t?.igDay,
                            )}
                          </strong>
                        </Status>
                      </Value>
                    </Status>
                  ) : null
            ) : null}
            {reduceUltrasonograms?.morfo1t?.date ? (<Status style={{ marginTop: 10 }}>
              <FieldDetails
                defaultValue={reduceUltrasonograms?.morfo1t?.details}
                placeholder="Observações"
                name="mainUltrasonograms.morfo1t.details"
                onChange={onChange}
                type="text"
              /> </Status>) : null}
            <Status style={{ marginTop: 10 }}>
              <Name>Janela de Exames:</Name>{' '}
              <Value>
                <strong>{morfo1t(dum)}</strong>
              </Value>
            </Status>
          </Item>
          <Item>
            <Title>{reduceUltrasonograms?.morfo2t?.name}</Title>
            {!isFinished ? (
              <Grid>
                <div>
                  <Name>Data:</Name>
                  <Value>
                    <FieldDate
                      defaultValue={reduceUltrasonograms?.morfo2t?.date}
                      max={
                        dum
                          ? dateUtils?.min(
                            dateUtils?.getSumByDay(dum, 280),
                            dateUtils?.getNewDate(),
                          )
                          : dateUtils?.getNewDate()
                      }
                      name="mainUltrasonograms.morfo2t.date"
                      onChange={onChange}
                      type="date"
                    />
                  </Value>
                </div>
                {reduceUltrasonograms?.morfo2t?.date ? (
                  <div>
                    <Name>Status:</Name>{' '}
                    <Value>
                      <FieldStatus
                        defaultValue={reduceUltrasonograms?.morfo2t?.status}
                        name="mainUltrasonograms.morfo2t.status"
                        onChange={onChange}
                        options={optionsStatus}
                        type="select"
                      />
                    </Value>
                  </div>
                ) : null}
              </Grid>
            ) : (
                <Grid>
                  <Status>
                    <Name>Data: </Name>
                    <Value>
                      <strong>
                        {reduceUltrasonograms?.morfo2t?.date
                          ? dateUtils?.parse(reduceUltrasonograms?.morfo2t?.date)
                          : 'Não Inserido'}
                      </strong>
                    </Value>
                  </Status>
                  {reduceUltrasonograms?.morfo2t?.date ? (
                    <Status>
                      <Name>Status: </Name>{' '}
                      <Value>
                        <strong>
                          {reduceUltrasonograms?.morfo2t?.status === 'false'
                            ? 'Alterado'
                            : 'Normal'}
                        </strong>
                      </Value>
                    </Status>
                  ) : null}
                </Grid>
              )}
            {reduceUltrasonograms?.morfo2t?.date ? (
              !isFinished ? (
                <div>
                  <Name>Idade Gestacional:</Name>
                  <Value>
                    <Status>
                      <FieldText
                        name="mainUltrasonograms.morfo2t.igWeek"
                        defaultValue={reduceUltrasonograms?.morfo2t?.igWeek}
                        onChange={onChange}
                        max="42"
                        type="number"
                      />
                      <Value>
                        <strong> semanas e </strong>
                      </Value>
                      <FieldText
                        defaultValue={reduceUltrasonograms?.morfo2t?.igDay}
                        name="mainUltrasonograms.morfo2t.igDay"
                        onChange={onChange}
                        max="6"
                        type="number"
                      />
                      <Value>
                        <strong> dias</strong>
                      </Value>
                    </Status>
                  </Value>
                </div>
              ) : (reduceUltrasonograms?.morfo2t?.igWeek &&
                reduceUltrasonograms?.morfo2t?.igWeek !== '0') ||
                (reduceUltrasonograms?.morfo2t?.igDay &&
                  reduceUltrasonograms?.morfo2t?.igDay !== '0') ? (
                    <Status>
                      <Name>Idade Gestacional: </Name>
                      <Value>
                        <Status>
                          <strong>
                            {getAge(
                              reduceUltrasonograms?.morfo2t?.igWeek,
                              reduceUltrasonograms?.morfo2t?.igDay,
                            )}
                          </strong>
                        </Status>
                      </Value>
                    </Status>
                  ) : null
            ) : null}
             {reduceUltrasonograms?.morfo2t?.date ? (<Status style={{ marginTop: 10 }}>
              <FieldDetails
                defaultValue={reduceUltrasonograms?.morfo2t?.details}
                placeholder="Observações"
                name="mainUltrasonograms.morfo2t.details"
                onChange={onChange}
                type="text"
              /> </Status>) : null}
            <Status style={{ marginTop: 10 }}>
              <Name>Janela de Exames:</Name>{' '}
              <Value>
                <strong>{morfo2t(dum)}</strong>
              </Value>
            </Status>
          </Item>
          <Item>
            <Title>{reduceUltrasonograms?.ecofetal?.name}</Title>
            {!isFinished ? (
              <Grid>
                <div>
                  <Name>Data:</Name>
                  <Value>
                    <FieldDate
                      defaultValue={reduceUltrasonograms?.ecofetal?.date}
                      max={
                        dum
                          ? dateUtils?.min(
                            dateUtils?.getSumByDay(dum, 280),
                            dateUtils?.getNewDate(),
                          )
                          : dateUtils?.getNewDate()
                      }
                      name="mainUltrasonograms.ecofetal.date"
                      onChange={onChange}
                      type="date"
                    />
                  </Value>
                </div>
                {reduceUltrasonograms?.ecofetal?.date ? (
                  <div>
                    <Name>Status:</Name>{' '}
                    <Value>
                      <FieldStatus
                        defaultValue={reduceUltrasonograms?.ecofetal?.status}
                        name="mainUltrasonograms.ecofetal.status"
                        onChange={onChange}
                        options={optionsStatus}
                        type="select"
                      />
                    </Value>
                  </div>
                ) : null}
              </Grid>
            ) : (
                <Grid>
                  <Status>
                    <Name>Data: </Name>
                    <Value>
                      <strong>
                        {reduceUltrasonograms?.ecofetal?.date
                          ? dateUtils?.parse(reduceUltrasonograms?.ecofetal?.date)
                          : 'Não Inserido'}
                      </strong>
                    </Value>
                  </Status>
                  {reduceUltrasonograms?.ecofetal?.date ? (
                    <Status>
                      <Name>Status: </Name>{' '}
                      <Value>
                        <strong>
                          {reduceUltrasonograms?.ecofetal?.status === 'false'
                            ? 'Alterado'
                            : 'Normal'}
                        </strong>
                      </Value>
                    </Status>
                  ) : null}
                </Grid>
              )}
            {reduceUltrasonograms?.ecofetal?.date ? (
              !isFinished ? (
                <div>
                  <Name>Idade Gestacional:</Name>
                  <Value>
                    <Status>
                      <FieldText
                        name="mainUltrasonograms.ecofetal.igWeek"
                        defaultValue={reduceUltrasonograms?.ecofetal?.igWeek}
                        onChange={onChange}
                        max="42"
                        type="number"
                      />
                      <Value>
                        <strong> semanas e </strong>
                      </Value>
                      <FieldText
                        defaultValue={reduceUltrasonograms?.ecofetal?.igDay}
                        name="mainUltrasonograms.ecofetal.igDay"
                        onChange={onChange}
                        max="6"
                        type="number"
                      />
                      <Value>
                        <strong> dias</strong>
                      </Value>
                    </Status>
                  </Value>
                </div>
              ) : (reduceUltrasonograms?.ecofetal?.igWeek &&
                reduceUltrasonograms?.ecofetal?.igWeek !== '0') ||
                (reduceUltrasonograms?.ecofetal?.igDay &&
                  reduceUltrasonograms?.ecofetal?.igDay !== '0') ? (
                    <Status>
                      <Name>Idade Gestacional: </Name>
                      <Value>
                        <Status>
                          <strong>
                            {getAge(
                              reduceUltrasonograms?.ecofetal?.igWeek,
                              reduceUltrasonograms?.ecofetal?.igDay,
                            )}
                          </strong>
                        </Status>
                      </Value>
                    </Status>
                  ) : null
            ) : null}
             {reduceUltrasonograms?.ecofetal?.date ? (<Status style={{ marginTop: 10 }}>
              <FieldDetails
                defaultValue={reduceUltrasonograms?.ecofetal?.details}
                placeholder="Observações"
                name="mainUltrasonograms.ecofetal.details"
                onChange={onChange}
                type="text"
              /> </Status>) : null}
            <Status style={{ marginTop: 10 }}>
              <Name>Janela de Exames:</Name>{' '}
              <Value>
                <strong>{ecofetal(dum)}</strong>
              </Value>
            </Status>
          </Item>
          <Item>
            <Title>{reduceUltrasonograms?.doppler?.name}</Title>
            {!isFinished ? (
              <Grid>
                <div>
                  <Name>Data:</Name>
                  <Value>
                    <FieldDate
                      defaultValue={reduceUltrasonograms?.doppler?.date}
                      max={
                        dum
                          ? dateUtils?.min(
                            dateUtils?.getSumByDay(dum, 280),
                            dateUtils?.getNewDate(),
                          )
                          : dateUtils?.getNewDate()
                      }
                      name="mainUltrasonograms.doppler.date"
                      onChange={onChange}
                      type="date"
                    />
                  </Value>
                </div>
                {reduceUltrasonograms?.doppler?.date ? (
                  <div>
                    <Name>Status:</Name>{' '}
                    <Value>
                      <FieldStatus
                        defaultValue={reduceUltrasonograms?.doppler?.status}
                        name="mainUltrasonograms.doppler.status"
                        onChange={onChange}
                        options={optionsStatus}
                        type="select"
                      />
                    </Value>
                  </div>
                ) : null}
              </Grid>
            ) : (
                <Grid>
                  <Status>
                    <Name>Data: </Name>
                    <Value>
                      <strong>
                        {reduceUltrasonograms?.doppler?.date
                          ? dateUtils?.parse(reduceUltrasonograms?.doppler?.date)
                          : 'Não Inserido'}
                      </strong>
                    </Value>
                  </Status>
                  {reduceUltrasonograms?.doppler?.date ? (
                    <Status>
                      <Name>Status: </Name>{' '}
                      <Value>
                        <strong>
                          {reduceUltrasonograms?.doppler?.status === 'false'
                            ? 'Alterado'
                            : 'Normal'}
                        </strong>
                      </Value>
                    </Status>
                  ) : null}
                </Grid>
              )}
            {reduceUltrasonograms?.doppler?.date ? (
              !isFinished ? (
                <div>
                  <Name>Idade Gestacional:</Name>
                  <Value>
                    <Status>
                      <FieldText
                        name="mainUltrasonograms.doppler.igWeek"
                        defaultValue={reduceUltrasonograms?.doppler?.igWeek}
                        onChange={onChange}
                        max="42"
                        type="number"
                      />
                      <Value>
                        <strong> semanas e </strong>
                      </Value>
                      <FieldText
                        defaultValue={reduceUltrasonograms?.doppler?.igDay}
                        name="mainUltrasonograms.doppler.igDay"
                        onChange={onChange}
                        max="6"
                        type="number"
                      />
                      <Value>
                        <strong> dias</strong>
                      </Value>
                    </Status>
                  </Value>
                </div>
              ) : (reduceUltrasonograms?.doppler?.igWeek &&
                reduceUltrasonograms?.doppler?.igWeek !== '0') ||
                (reduceUltrasonograms?.doppler?.igDay &&
                  reduceUltrasonograms?.doppler?.igDay !== '0') ? (
                    <Status>
                      <Name>Idade Gestacional: </Name>
                      <Value>
                        <Status>
                          <strong>
                            {getAge(
                              reduceUltrasonograms?.doppler?.igWeek,
                              reduceUltrasonograms?.doppler?.igDay,
                            )}
                          </strong>
                        </Status>
                      </Value>
                    </Status>
                  ) : null
            ) : null}
             {reduceUltrasonograms?.doppler?.date ? (<Status style={{ marginTop: 10 }}>
              <FieldDetails
                defaultValue={reduceUltrasonograms?.doppler?.details}
                placeholder="Observações"
                name="mainUltrasonograms.doppler.details"
                onChange={onChange}
                type="text"
              /> </Status>) : null}
            <Status style={{ marginTop: 10 }}>
              <Name>Janela de Exames:</Name>{' '}
              <Value>
                <strong>{doppler(dum)}</strong>
              </Value>
            </Status>
          </Item>
          {!isFinished ? (
            <Item>
              <FieldTitle
                defaultValue={defaultValue?.ultrasonogram?.name}
                name="ultrasonogram.name"
                onChange={onChange}
                type="text"
                placeholder="Adicional"
              ></FieldTitle>
              <Grid>
                <div>
                  <Name>Data:</Name>
                  <Value>
                    <FieldDate
                      defaultValue={defaultValue?.ultrasonogram?.date}
                      max={
                        dum
                          ? dateUtils?.min(
                            dateUtils?.getSumByDay(dum, 280),
                            dateUtils?.getNewDate(),
                          )
                          : dateUtils?.getNewDate()
                      }
                      name="ultrasonogram.date"
                      onChange={onChange}
                      type="date"
                    />
                  </Value>
                </div>
                {defaultValue?.ultrasonogram?.date ? (
                  <div>
                    <Name>Status:</Name>{' '}
                    <Value>
                      <FieldStatus
                        defaultValue={defaultValue?.ultrasonogram?.status}
                        name="ultrasonogram.status"
                        onChange={onChange}
                        options={optionsStatus}
                        type="select"
                      />
                    </Value>
                  </div>
                ) : null}
              </Grid>
              {defaultValue?.ultrasonogram?.date ? (
                <div>
                  <Name>Idade Gestacional:</Name>
                  <Value>
                    <Status>
                      <FieldText
                        name="ultrasonogram.igWeek"
                        defaultValue={defaultValue?.ultrasonogram?.igWeek}
                        onChange={onChange}
                        max="42"
                        type="number"
                      />
                      <Value>
                        <strong> semanas e </strong>
                      </Value>
                      <FieldText
                        defaultValue={defaultValue?.ultrasonogram?.igDay}
                        name="ultrasonogram.igDay"
                        onChange={onChange}
                        max="6"
                        type="number"
                      />
                      <Value>
                        <strong> dias</strong>
                      </Value>
                    </Status>
                  </Value>
                </div>
              ) : null}
              {defaultValue?.ultrasonogram?.date ? (<Status style={{ marginTop: 10 }}><FieldDetails
                defaultValue={defaultValue?.ultrasonogram?.details}
                placeholder="Observações"
                name="ultrasonogram.details"
                onChange={onChange}
                type="text"
              /> </Status>) : null}
            </Item>
          ) : (
              ''
            )}
          {Array.isArray(ultrasonograms) && ultrasonograms.length ? (
            <Line>Ultrassonografias Adicionais</Line>
          ) : null}
          {Array.isArray(ultrasonograms) && ultrasonograms.length
            ? ultrasonograms?.map(
              (
                { date, name, status, igWeek, igDay, details }: UltrasonogramType,
                index: number,
              ) => (
                <Item key={index}>
                  <Title>{name || 'Adicional'}</Title>
                  <Grid>
                    <Status>
                      <Name>Data: </Name>
                      <Value>
                        <strong>{dateUtils?.parse(date)}</strong>
                      </Value>
                    </Status>
                    <Status>
                      <Name>Status: </Name>{' '}
                      <Value>
                        <strong>
                          {status === 'false' ? 'Alterado' : 'Normal'}
                        </strong>
                      </Value>
                    </Status>
                  </Grid>
                  {(igWeek && igWeek > '0') || (igDay && igDay > '0') ? (
                    <Status>
                      <Name>Idade Gestacional: </Name>
                      <Value>
                        <Status>
                          <Value>
                            <strong>{getAge(igWeek, igDay)}</strong>
                          </Value>
                        </Status>
                      </Value>
                    </Status>
                  ) : (
                      null
                    )}
                  { details ? (
                    <Status style={{ marginTop: 10 }}>
                      <Name>Obs: </Name>
                      <Value>
                        <Status>
                          <Value>
                            <strong>{details}</strong>
                          </Value>
                        </Status>
                      </Value>
                    </Status>
                  ) : (
                      null
                    )}
                </Item>
              ),
            )
            : null}
        </List>
      </Box>
    </Ultrasonogram>
  )
}
