import { useEffect, useState } from 'react'

import ConfigType from '../types/config'
import api from '../utils/api'

const defaultSettings = {
  birthTypes: [''],
  genderTypes: [''],
  gestationalWeeks: [{ title: '', description: '' }],
  healthPlans: [''],
  maritalStatus: [''],
  gestationTypes: [''],
  birthResults: [''],
  examOptions1: [''],
  examOptions2: [''],
}

export default (): [ConfigType] => {
  const [config, setAuth] = useState(defaultSettings)

  useEffect(() => {
    api.getConfig().then(response => setAuth(response))
  }, [])

  return [config]
}
