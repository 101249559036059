import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Box from '../components/Box'
import Column from '../components/Column'
import Field from '../components/Field'
import List from '../components/List'
import Row from '../components/Row'
import useAccompaniment from '../hooks/accompaniment'
import useGestation from '../hooks/gestation'
import AccompanimentType from '../types/accompaniment'
import PregnantType from '../types/pregnant'
import date from '../utils/date'
import imc from '../utils/imc'
import theme from '../utils/theme'
import Modal from '../components/Modal'
import FetalWeightCurve from './FetalWeightCurve'
import PregnantImcCurve from './PregnantImcCurve'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import UterineHeightCurve from './UterineHeightCurve'

type PropType = {
  accompaniments: Array<AccompanimentType> | undefined
  defaultAccompaniment?: AccompanimentType
  onChange?: any
  pregnant: PregnantType
}

const Accompaniment = styled.div`
  margin-bottom: calc(${theme?.main?.spacing} / 1.3);
  position: relative;
  overflow: hidden;
  width: 1040px;
  ul {
    overflow: hidden;
    overflow-y: hidden;
  }
`
const Item = styled.li`
  align-items: center;
  display: block;
  height: 25px;
  line-height: 24px;
  font-size: 95%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  &.true {
    color: ${theme?.color?.initial} !important;
    background: ${theme?.color?.alert} !important;
    * {
      color: ${theme?.color?.initial} !important;
    }
    [data-suffix]:after {
      color: ${theme?.color?.initial} !important;
    }
  }
  input,
  input:disabled {
    background: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  [data-suffix]:after {
    color: ${theme?.color?.primary} !important;
    left: 35px;
    top: 24px;
  }
`
const Title = styled.div.attrs({
  className: 'Title',
})`
  align-items: center;
  color: ${theme?.color?.primary};
  display: inline-block;
  font-weight: ${theme?.font?.bold};
  justify-content: center;
  & + .Title {
    margin-left: 26%;
  }
`

const ClickableTitle = styled.div.attrs({
  className: 'ClickableTitle',
})`
  align-items: center;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
  font-weight: ${theme?.font?.bold};
  justify-content: center;
  text-decoration: underline;
  & + .Title {
    margin-left: 26%;
  }
`

const Date = styled.div.attrs({
  className: 'Date',
})`
  align-items: center;
  color: ${theme?.color?.primary};
  display: flex;
  font-weight: ${theme?.font?.bold};
  justify-content: center;
  transform: translateY(0.5px);
  text-transform: uppercase;
  overflow: hidden;
`
const CustomList = styled(List)`
  background: ${theme?.color?.initial};
  padding: 0;
  margin: 0;
  li:nth-child(even) {
    background: ${theme?.color?.secondary};
  }
  input {
    border: 0;
    color: ${theme?.color?.primary} !important;
    line-height: 20px;
    height: 20px !important;
    margin: 0 !important;
    &::placeholder {
      color: ${theme?.color?.primary} !important;
      opacity: 0.6 !important;
    }
  }
  svg {
    fill: ${theme?.color?.primary};
    height: 60%;
    transform: translate(-10px, -01px);
  }
`
const CustomItems = styled(List)`
  overflow: hidden !important;
  overflow-y: hidden !important;
  overflow-x: scroll !important;
  max-width: 800px;
  white-space: nowrap;
`
const CustomItem = styled.div`
  border-radius: 10px;
  margin-left: 25px;
  position: relative;
  display: inline-block;
  width: 200px;
  overflow: hidden;
  li {
    color: ${theme?.color?.primary};
    font-weight: ${theme?.font?.bold};
    padding-left: 10px;
  }
  &:first-child {
    margin-left: 0;
    .Date {
      padding-left: 47px;
    }
  }
  &.false * {
    background: ${theme?.color?.secondary};
    &:not(:first-child) * {
      visibility: hidden;
    }
  }
`
const CustomRow = styled(Row)`
  margin-top: -10px;
`
const ItemGroup = styled.div`
  position: relative;
  & > *:nth-child(2) {
    position: absolute;
    left: 80px;
    top: 0;
  }
`
const ItemGroupThree = styled.div`
  position: relative;
  & > *:nth-child(2) {
    position: absolute;
    left: 68px;
    top: 0;
  }
  & > *:nth-child(3) {
    position: absolute;
    left: 136px;
    top: 0;
  }
`

const OverflowItem = styled.div`
  background: ${theme?.color?.secondary};
  color: ${theme?.color?.primary};
  font-weight: ${theme?.font?.bold};
  border-radius: calc(${theme?.main?.radius} / 5);
  padding: 2px 10px;
  margin-top: 10px;
  box-shadow: 0 01px 10px 01px rgba(0, 0, 0, 0.15);
`

export default ({
  accompaniments = [],
  defaultAccompaniment,
  onChange,
  pregnant,
}: PropType) => {
  const [gestation] = useGestation(pregnant)
  const [
    getIgdum,
    getIg1usg,
    getDiffWeight,
    getDiffFetalWeight,
    checkBloodPressure,
    checkWeightGain,
  ] = useAccompaniment()
  const [toggleFetalCurve, setToggleFetalCurve] = useState(false)
  const [toggleImcCurve, setToggleImcCurve] = useState(false)
  const [toggleUterineHeightCurve, setToggleUterineHeightCurve] = useState(
    false,
  )

  return (
    <Accompaniment>
      <Box title="Acompanhamento">
        <CustomRow>
          <Column className="column-20">
            <List>
              <br />
              <Item>
                <Title>IG DUM / IG 1º USG</Title>
              </Item>
              <Item>
                <Title>Pressão Arterial</Title>
              </Item>
              <Item>
                <Title>Peso Atual (kg)</Title>
              </Item>
              <Item>
                <ClickableTitle onClick={() => setToggleImcCurve(true)}>
                  IMC
                </ClickableTitle>
                {toggleImcCurve && (
                  <Modal
                    className={String(toggleImcCurve)}
                    onClick={() => setToggleImcCurve(!toggleImcCurve)}
                    title="Curva - IMC Gravídico"
                  >
                    <>
                      <PregnantImcCurve
                        dum={gestation?.dum}
                        height={gestation?.height}
                        defaultAccompaniment={defaultAccompaniment}
                        accompaniments={accompaniments}
                      />
                    </>
                  </Modal>
                )}
              </Item>
              <Item>
                <ClickableTitle onClick={() => setToggleFetalCurve(true)}>
                  Peso Fetal (g)
                </ClickableTitle>
                {toggleFetalCurve && (
                  <Modal
                    className={String(toggleFetalCurve)}
                    onClick={() => setToggleFetalCurve(!toggleFetalCurve)}
                    title="Curva - Peso Fetal"
                  >
                    <>
                      <FetalWeightCurve
                        dum={gestation?.dum}
                        gestationType={gestation?.gestationType}
                        defaultAccompaniment={defaultAccompaniment}
                        accompaniments={accompaniments}
                      />
                    </>
                  </Modal>
                )}
              </Item>
              <Item>
                <Title>BCF</Title>
              </Item>
              <Item>
                <ClickableTitle
                  onClick={() => setToggleUterineHeightCurve(true)}
                >
                  Altura Uterina (cm)
                </ClickableTitle>
                {toggleUterineHeightCurve && (
                  <Modal
                    className={String(toggleUterineHeightCurve)}
                    onClick={() =>
                      setToggleUterineHeightCurve(!toggleUterineHeightCurve)
                    }
                    title="Curva - Altura Uterina"
                  >
                    <>
                      <UterineHeightCurve
                        dum={gestation?.dum}
                        defaultAccompaniment={defaultAccompaniment}
                        accompaniments={accompaniments}
                      />
                    </>
                  </Modal>
                )}
              </Item>
              <Item>
                <Title>Queixas</Title>
              </Item>
              <Item>
                <Title>Orientações</Title>
              </Item>
            </List>
          </Column>
          <Column>
            <CustomItems>
              {!gestation?.isFinished ? (
                <CustomItem
                  className={String(defaultAccompaniment?.date ? true : false)}
                >
                  <CustomList>
                    <Item>
                      <Date>
                        <Field
                          defaultValue={defaultAccompaniment?.date}
                          max={
                            gestation?.dum
                              ? date.min(
                                  date.getSumByDay(gestation?.dum, 280),
                                  date.getNewDate(),
                                )
                              : date.getNewDate()
                          }
                          name="accompaniment.date"
                          onChange={onChange}
                          type="date"
                        />
                      </Date>
                    </Item>
                    <Item>
                      <Title>
                        <ItemGroup>
                          <span>
                            {getIgdum(
                              gestation?.dum,
                              defaultAccompaniment?.date,
                            )}
                          </span>
                          <span>
                            {getIg1usg(
                              defaultAccompaniment?.date,
                              gestation?.d1usg,
                              gestation?.ig1usg,
                            )}
                          </span>
                        </ItemGroup>
                      </Title>
                    </Item>
                    <Item
                      className={String(
                        checkBloodPressure(defaultAccompaniment?.bloodPressure),
                      )}
                    >
                      <Title>
                        <Field
                          defaultValue={defaultAccompaniment?.bloodPressure}
                          placeholder="ex: 120/80"
                          name="accompaniment.bloodPressure"
                          onChange={onChange}
                          type="bloodPressure"
                        />
                      </Title>
                    </Item>
                    <Item
                      className={checkWeightGain(
                        gestation?.height,
                        gestation?.weight,
                        defaultAccompaniment?.weight,
                      )}
                    >
                      <Title>
                        <ItemGroup>
                          <Field
                            defaultValue={defaultAccompaniment?.weight}
                            placeholder="Peso"
                            name="accompaniment.weight"
                            onChange={onChange}
                            type="weight"
                          />
                          <div>
                            {getDiffWeight(
                              gestation?.weight,
                              defaultAccompaniment?.weight,
                            )}
                          </div>
                        </ItemGroup>
                      </Title>
                    </Item>
                    <Item>
                      <Title>
                        {imc(gestation?.height, defaultAccompaniment?.weight)}
                      </Title>
                    </Item>
                    <Item>
                      <Title>
                        {!gestation?.gestationType ||
                        gestation?.gestationType === 'Única' ? (
                          <ItemGroup>
                            <Field
                              defaultValue={defaultAccompaniment?.fetalWeight}
                              placeholder="Peso"
                              name="accompaniment.fetalWeight"
                              onChange={onChange}
                              type="fetalWeight"
                            />
                            <div>
                              {getDiffFetalWeight(
                                accompaniments[0]?.fetalWeight,
                                defaultAccompaniment?.fetalWeight,
                              )}
                            </div>
                          </ItemGroup>
                        ) : gestation?.gestationType === 'Gemelar' ? (
                          <ItemGroup>
                            <Field
                              defaultValue={defaultAccompaniment?.fetalWeight}
                              placeholder="Peso1"
                              name="accompaniment.fetalWeight"
                              onChange={onChange}
                              type="fetalWeight"
                            />
                            <Field
                              defaultValue={defaultAccompaniment?.fetalWeight2}
                              placeholder="Peso2"
                              name="accompaniment.fetalWeight2"
                              onChange={onChange}
                              type="fetalWeight"
                            />
                          </ItemGroup>
                        ) : (
                          <ItemGroupThree>
                            <Field
                              defaultValue={defaultAccompaniment?.fetalWeight}
                              placeholder="Peso1"
                              name="accompaniment.fetalWeight"
                              onChange={onChange}
                              type="fetalWeight"
                            />
                            <Field
                              defaultValue={defaultAccompaniment?.fetalWeight2}
                              placeholder="Peso2"
                              name="accompaniment.fetalWeight2"
                              onChange={onChange}
                              type="fetalWeight"
                            />
                            <Field
                              defaultValue={defaultAccompaniment?.fetalWeight3}
                              placeholder="Peso3"
                              name="accompaniment.fetalWeight3"
                              onChange={onChange}
                              type="fetalWeight"
                            />
                          </ItemGroupThree>
                        )}
                      </Title>
                    </Item>
                    <Item>
                      <Title>
                        {!gestation?.gestationType ||
                        gestation?.gestationType === 'Única' ? (
                          <Field
                            defaultValue={defaultAccompaniment?.bcf}
                            placeholder="BCF"
                            name="accompaniment.bcf"
                            onChange={onChange}
                            type="number"
                          />
                        ) : gestation?.gestationType === 'Gemelar' ? (
                          <ItemGroup>
                            <Field
                              defaultValue={defaultAccompaniment?.bcf}
                              placeholder="BCF1"
                              name="accompaniment.bcf"
                              onChange={onChange}
                              type="number"
                            />
                            <Field
                              defaultValue={defaultAccompaniment?.bcf2}
                              placeholder="BCF2"
                              name="accompaniment.bcf2"
                              onChange={onChange}
                              type="number"
                            />
                          </ItemGroup>
                        ) : (
                          <ItemGroupThree>
                            <Field
                              defaultValue={defaultAccompaniment?.bcf}
                              placeholder="BCF1"
                              name="accompaniment.bcf"
                              onChange={onChange}
                              type="number"
                            />
                            <Field
                              defaultValue={defaultAccompaniment?.bcf2}
                              placeholder="BCF2"
                              name="accompaniment.bcf2"
                              onChange={onChange}
                              type="number"
                            />
                            <Field
                              defaultValue={defaultAccompaniment?.bcf3}
                              placeholder="BCF3"
                              name="accompaniment.bcf3"
                              onChange={onChange}
                              type="number"
                            />
                          </ItemGroupThree>
                        )}
                      </Title>
                    </Item>
                    <Item>
                      <Title>
                        <Field
                          defaultValue={defaultAccompaniment?.uterineHeight}
                          placeholder="Altura"
                          name="accompaniment.uterineHeight"
                          onChange={onChange}
                          type="uterineHeight"
                        />
                      </Title>
                    </Item>
                    <Item>
                      <Title>
                        <Field
                          defaultValue={defaultAccompaniment?.complaints}
                          placeholder="Queixas"
                          name="accompaniment.complaints"
                          onChange={onChange}
                          type="text"
                        />
                      </Title>
                    </Item>
                    <Item>
                      <Title>
                        <Field
                          defaultValue={defaultAccompaniment?.prescription}
                          placeholder="Orientações"
                          name="accompaniment.prescription"
                          onChange={onChange}
                          type="text"
                        />
                      </Title>
                    </Item>
                  </CustomList>
                </CustomItem>
              ) : (
                ''
              )}
              {accompaniments?.map((accompaniment: any, index: number) => (
                <CustomItem key={index}>
                  <CustomList>
                    <Item>
                      {gestation?.isFinished ? (
                        <Date style={{ paddingLeft: 0 }}>
                          {date.parse(accompaniment?.date)}
                        </Date>
                      ) : (
                        <Date>{date.parse(accompaniment?.date)}</Date>
                      )}
                    </Item>
                    <Item>
                      <ItemGroup>
                        <div>
                          {getIgdum(gestation?.dum, accompaniment?.date)}
                        </div>
                        <div>
                          {getIg1usg(
                            accompaniment?.date,
                            gestation?.d1usg,
                            gestation?.ig1usg,
                          )}
                        </div>
                      </ItemGroup>
                    </Item>
                    <Item
                      className={String(
                        checkBloodPressure(accompaniment?.bloodPressure),
                      )}
                    >
                      {accompaniment?.bloodPressure}
                    </Item>
                    <Item
                      className={checkWeightGain(
                        gestation?.height,
                        gestation?.weight,
                        accompaniment?.weight,
                      )}
                    >
                      <ItemGroup>
                        <div>
                          {accompaniment?.weight
                            ? accompaniment?.weight + ' kg'
                            : ''}
                        </div>
                        <div>
                          {getDiffWeight(
                            gestation?.weight,
                            accompaniment?.weight,
                          )}
                        </div>
                      </ItemGroup>
                    </Item>
                    <Item>{imc(gestation?.height, accompaniment?.weight)}</Item>
                    <Item>
                      {!gestation?.gestationType ||
                      gestation?.gestationType === 'Única' ? (
                        <ItemGroup>
                          <div>
                            {accompaniment?.fetalWeight
                              ? accompaniment?.fetalWeight + ' g'
                              : ''}
                          </div>
                          <div>
                            {getDiffFetalWeight(
                              accompaniments[index + 1]?.fetalWeight,
                              accompaniment?.fetalWeight,
                            )}
                          </div>
                        </ItemGroup>
                      ) : gestation?.gestationType === 'Gemelar' ? (
                        <ItemGroup>
                          <div>
                            {accompaniment?.fetalWeight
                              ? accompaniment?.fetalWeight + ' g'
                              : ''}
                          </div>
                          <div>
                            {accompaniment?.fetalWeight2
                              ? accompaniment?.fetalWeight2 + ' g'
                              : ''}
                          </div>
                        </ItemGroup>
                      ) : (
                        <ItemGroupThree>
                          <div>
                            {accompaniment?.fetalWeight
                              ? accompaniment?.fetalWeight + ' g'
                              : ''}
                          </div>
                          <div>
                            {accompaniment?.fetalWeight2
                              ? accompaniment?.fetalWeight2 + ' g'
                              : ''}
                          </div>
                          <div>
                            {accompaniment?.fetalWeight3
                              ? accompaniment?.fetalWeight3 + ' g'
                              : ''}
                          </div>
                        </ItemGroupThree>
                      )}
                    </Item>
                    <Item>
                      {!gestation?.gestationType ||
                      gestation?.gestationType === 'Única' ? (
                        <ItemGroup>{accompaniment?.bcf}</ItemGroup>
                      ) : gestation?.gestationType === 'Gemelar' ? (
                        <ItemGroup>
                          <div>{accompaniment?.bcf}</div>
                          <div>{accompaniment?.bcf2}</div>
                        </ItemGroup>
                      ) : (
                        <ItemGroupThree>
                          <div>{accompaniment?.bcf}</div>
                          <div>{accompaniment?.bc2}</div>
                          <div>{accompaniment?.bcf3}</div>
                        </ItemGroupThree>
                      )}
                    </Item>
                    <Item>
                      {accompaniment?.uterineHeight
                        ? accompaniment?.uterineHeight + ' cm'
                        : ''}
                    </Item>
                    {accompaniment?.complaints ? (
                      <OverlayTrigger
                        key={'bottom'}
                        placement={'bottom'}
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Content>
                              <OverflowItem>
                                {accompaniment?.complaints}
                              </OverflowItem>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Item>{accompaniment?.complaints}</Item>
                      </OverlayTrigger>
                    ) : (
                      <Item>{accompaniment?.complaints}</Item>
                    )}
                    {accompaniment?.prescription ? (
                      <OverlayTrigger
                        key={'bottom'}
                        placement={'bottom'}
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Content>
                              <OverflowItem>
                                {accompaniment?.prescription}
                              </OverflowItem>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Item>{accompaniment?.prescription}</Item>
                      </OverlayTrigger>
                    ) : (
                      <Item>{accompaniment?.prescription}</Item>
                    )}
                  </CustomList>
                </CustomItem>
              ))}
            </CustomItems>
          </Column>
        </CustomRow>
      </Box>
    </Accompaniment>
  )
}
