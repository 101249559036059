import React from 'react'
import styled from 'styled-components/macro'

import Button from './Button'
import Field from './Field'
import Modal from './Modal'
import useUpdateGestation from '../hooks/updateGestation'
import usePregnant from '../hooks/pregnant'

type PropType = {
  gestation: any
  onClick: any
  open: boolean
}

const Grid = styled.div`
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    & > * {
      width: 100%;
      & + * {
        margin-left: 20px;
      }
    }
  }
`
const CustomGridFirst33 = styled(Grid)`
  @media (min-width: 480px) {
    & > * {
      width: 66%;
      &:first-child {
        width: 33%;
      }
    }
  }
`
const CustomGridAll33 = styled.div`
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    & > * {
      width: 100%;
      & + * {
        margin-left: 10px;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:nth-child(3) {
        width: 95%;
      }
    }
  }
`

export default ({ gestation, onClick, open }: PropType) => {
  const [pregnant, handleChangePrenant, handleSubmitPregnant] = usePregnant()
  const [
    updateGestation,
    handleChangeGestation,
    handleSubmitGestation,
  ] = useUpdateGestation(gestation)

  if (!open) return <></>

  return (
    <Modal
      className={String(open)}
      onClick={onClick}
      title="Editar dados gestacionais"
    >
      <>
        <form
          onSubmit={event => {
            handleSubmitGestation(event)
            handleSubmitPregnant(event)
          }}
        >
          <CustomGridFirst33>
            <Field
              defaultValue={updateGestation?.dum || ''}
              label="Data da Última Menstruação (DUM)"
              name="dum"
              onChange={handleChangeGestation}
              type="date"
            />
            <Field
              defaultValue={pregnant?.obstetricRisk || ''}
              label="Risco Obstétrico (use ',' para separar os riscos)"
              name="obstetricRisk"
              onChange={handleChangePrenant}
              placeholder="ex: diabetes, obesidade, hipertensão"
              type="text"
            />
          </CustomGridFirst33>
          <CustomGridFirst33>
            <Field
              defaultValue={updateGestation?.d1usg || ''}
              label="Data do 1º ultrassom (Data 1º USG)"
              name="d1usg"
              onChange={handleChangeGestation}
              type="date"
            />
            <Field
              defaultValue={pregnant?.previousPathological || ''}
              label="História patológica pregressa"
              name="previousPathological"
              onChange={handleChangePrenant}
              type="text"
            />
          </CustomGridFirst33>
          <CustomGridFirst33>
            <Field
              defaultValue={
                String(updateGestation?.d1usg)?.length
                  ? updateGestation?.ig1usg
                  : ''
              }
              disabled={String(updateGestation?.d1usg)?.length ? false : true}
              label="Idade gestacional no 1º ultrassom (IG 1º USG)"
              name="ig1usg"
              onChange={handleChangeGestation}
              type="ig1usg"
            />
            <Field
              defaultValue={pregnant?.obstetricBackground || ''}
              label="Antecedentes obstétricos"
              name="obstetricBackground"
              onChange={handleChangePrenant}
              type="text"
            />
          </CustomGridFirst33>
          <CustomGridAll33>
            <Field
              defaultValue={updateGestation?.gestationType || ''}
              label="Tipo de Gestação"
              name="gestationType"
              onChange={handleChangeGestation}
              type="gestationTypes"
            />
            <Field
              defaultValue={updateGestation?.height || ''}
              label="Altura"
              name="height"
              onChange={handleChangeGestation}
              placeholder="ex: 160 cm"
              type="height"
            />
            <Field
              defaultValue={updateGestation?.weight || ''}
              label="Peso"
              name="weight"
              onChange={handleChangeGestation}
              placeholder="ex: 50,0 kg"
              type="weight"
            />
          </CustomGridAll33>
          <CustomGridFirst33>
            <Field
              defaultValue={updateGestation?.name || ''}
              label="Nome do bebê"
              name="name"
              onChange={handleChangeGestation}
              type="text"
            />
            <Field
              defaultValue={pregnant?.vaccines || ''}
              label="Vacinas"
              name="vaccines"
              onChange={handleChangePrenant}
              placeholder="Hepatite B, H1N1, DTPA"
              type="text"
            />
          </CustomGridFirst33>
          <CustomGridFirst33>
            <Field
              defaultValue={updateGestation?.birthType || ''}
              label="Tipo do parto"
              name="birthType"
              onChange={handleChangeGestation}
              type="birthTypes"
            />
            <Field
              defaultValue={pregnant?.allergy || ''}
              label="Alergias"
              name="allergy"
              onChange={handleChangePrenant}
              type="text"
            />
          </CustomGridFirst33>
          <CustomGridFirst33>
            <Field
              defaultValue={updateGestation?.place || ''}
              label="Local do parto"
              name="place"
              onChange={handleChangeGestation}
              type="text"
            />
            <Field
              defaultValue={pregnant?.note || ''}
              label="Observações"
              name="note"
              onChange={handleChangePrenant}
              type="text"
            />
          </CustomGridFirst33>
          <Button>Salvar</Button>
        </form>
      </>
    </Modal>
  )
}
