import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import ConsultationType from '../types/consultation'
import api from '../utils/api'
import dialog from '../utils/dialog'
import storage from '../utils/storage'

const initialValue: ConsultationType = {
  accompaniment: {
    date: '',
    bloodPressure: '',
    weight: '',
    fetalWeight: '',
    fetalWeight2: '',
    fetalWeight3: '',
    bcf: '',
    bcf2: '',
    bcf3: '',
    uterineHeight: '',
    complaints: '',
    prescription: '',
  },
  exam: {
    date: '',
    hb: '',
    ht: '',
    glicemia: '',
    ttog: '',
    vdrl: 'Negativo',
    ts: '',
    toxo: 'Imune',
    rubeola: 'Imune',
    cmv: 'Imune',
    hiv: '',
    hcv: '',
    hbsag: 'Negativo',
    antihbs: 'Positivo',
    suuro: '',
    t4l: '',
    tsh: '',
    coombsind: '',
    vitamind: '',
    ferritin: '',
  },
  ultrasonogram: {
    date: '',
    name: '',
    ig: '',
    igWeek: '',
    igDay: '',
  },
  mainUltrasonograms: {
    morfo1t: {
      date: '',
      name: 'Morfo 1º Trimestre',
      ig: '',
      igWeek: '',
      igDay: '',
    },
    morfo2t: {
      date: '',
      name: 'Morfo 2º Trimestre',
      ig: '',
      igWeek: '',
      igDay: '',
    },
    ecofetal: {
      date: '',
      name: 'Eco Fetal',
      ig: '',
      igWeek: '',
      igDay: '',
    },
    doppler: {
      date: '',
      name: 'Doppler',
      ig: '',
      igWeek: '',
      igDay: '',
    },
  },
}

function reduceConsultation (
  consultation: ConsultationType,
  { name = '', value = '' }: any,
) {
  const field = name.split('.')[1]

  if (name.includes('accompaniment')) {
    return {
      ...consultation,
      accompaniment: {
        ...consultation?.accompaniment,
        [String(field)]: value,
      },
    }
  }

  if (name.includes('exam')) {
    return {
      ...consultation,
      exam: {
        ...consultation?.exam,
        [String(field)]: value,
      },
    }
  }

  if (name.includes('ultrasonogram')) {
    return {
      ...consultation,
      ultrasonogram: {
        ...consultation?.ultrasonogram,
        [String(field)]: value,
      },
    }
  }

  if (name.includes('mainUltrasonograms')) {
    name = name.split('.')[2]
    return {
      ...consultation,
      mainUltrasonograms: {
        ...consultation?.mainUltrasonograms,
        [String(field)]: {
          // @ts-ignore
          ...consultation?.mainUltrasonograms[String(field)],
          [String(name)]: value,
        },
      },
    }
  }

  return consultation
}

export default (): [ConsultationType, any, any, any, any, boolean] => {
  const history = useHistory()
  const { gestationId, doctorId, pregnantId }: any = useParams()
  const [loading, setLoading] = useState(false)
  const [consultation, setConsultation] = useState({
    ...initialValue,
    ...storage.get(`consultation-${pregnantId}-${gestationId}`),
  })

  useEffect(() => {
    setConsultation({
      ...initialValue,
      ...storage.get(`consultation-${pregnantId}-${gestationId}`),
    })
  }, [gestationId, pregnantId])

  const handleChange = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      const data = reduceConsultation(consultation, event?.target)
      setConsultation(data)
      storage.set(`consultation-${pregnantId}-${gestationId}`, data)
    },
    [consultation, gestationId, pregnantId],
  )

  const handleFinish = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      dialog?.confirm(() => {
        setLoading(true)
        api
          .registerConsultation({ gestationId, pregnantId, consultation })
          .then(() => {
            setLoading(false)
            storage.remove(`consultation-${pregnantId}-${gestationId}`)
            history?.push(`/${doctorId}/pregnants`)
          })
      })
    },
    [gestationId, pregnantId, consultation, history],
  )

  const handleSubmit = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      dialog?.confirm(() => {
        setLoading(true)
        api
          .registerConsultation({ gestationId, pregnantId, consultation })
          .then(() => {
            setInterval(() => {
              storage.remove(`consultation-${pregnantId}-${gestationId}`)
              history?.go(0)
              setLoading(false)
            }, 500)
          })
      })
    },
    [gestationId, pregnantId, consultation, history],
  )

  const handleBack = () => {
    dialog?.confirm(
      () => {
        storage.remove(`consultation-${pregnantId}-${gestationId}`)
        history?.push(`/${doctorId}/pregnants`)
      },
      () => {},
      'Ao confirmar, você perderá todas as informações que ainda não foram salvas.',
    )
  }

  return [
    consultation,
    handleChange,
    handleSubmit,
    handleFinish,
    handleBack,
    loading,
  ]
}
