import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../utils/api'
import dialog from '../utils/dialog'

const initialFinishGestation = {
  deliveryDate: '',
  finalWeight: '',
  result: '',
}

export default (pregnant: any, gestation: any): [any, any, any] => {
  const pregnantId = pregnant?.uid
  const gestationId = gestation?.uid

  const history = useHistory()
  const [finishGestation, setFinishGestation] = useState(
    initialFinishGestation as any,
  )

  useEffect(() => {
    if (!finishGestation?.uid || finishGestation?.uid !== gestationId)
      setFinishGestation({ ...initialFinishGestation, ...gestation })
  }, [finishGestation, gestation, gestationId])

  const handleChange = (event: any) => {
    const { name, value } = event?.target
    setFinishGestation({ ...finishGestation, [String(name)]: value })
  }

  const handleSubmit = useCallback(
    async (event: any) => {
      event?.preventDefault()
      event?.stopPropagation()
      dialog?.confirm(() => {
        api
          .finishGestation({
            pregnantId,
            gestationId,
            ...finishGestation,
          })
          .then(gestation => {
            setFinishGestation(gestation)
            history?.go(0)
          })
      })
    },

    [pregnantId, gestationId, finishGestation, history],
  )

  return [finishGestation, handleChange, handleSubmit]
}
