import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  children?: any
  className?: string
  href?: string
  onClick?: any
}

const Link = styled.a`
  color: ${theme?.color?.tertiary};
  transition: all 0.2s linear;
`

export default ({
  children = '',
  className = '',
  href = '',
  onClick,
}: PropType) =>
  href.includes('http') || href.includes('mailto') ? (
    <Link
      className={`Link ${className}`.trim()}
      href={href}
      rel="noopener"
      target="_blank"
    >
      {children}
    </Link>
  ) : (
    <Link className={`Link ${className}`.trim()} href={href} onClick={onClick}>
      {children}
    </Link>
  )
