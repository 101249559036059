import React from 'react'
import styled from 'styled-components/macro'

import Icon from '../components/Icon'
import theme from '../utils/theme'

type PropType = {
  children: JSX.Element | JSX.Element[]
  className?: string
  onClick?: any
  title?: string
  width?: number
}

const Modal = styled.div`
  background: rgba(0, 0, 0, 0.85);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  button,
  form {
    margin-bottom: 0;
  }
  button {
    background: ${theme?.color?.primary};
    border-color: ${theme?.color?.primary};
    color: ${theme?.color?.initial};
  }
`
const ModalContainer = styled.div`
  background: rgb(253, 242, 247);
  background: linear-gradient(
    0deg,
    rgba(253, 242, 247, 1) 0%,
    rgba(255, 247, 240, 1) 100%
  );
  border-radius: calc(${theme?.main?.radius} / 3);
  left: 50%;
  max-height: 90vh;
  max-width: calc(${theme?.main?.width} / 1.5);
  min-height: 100px;
  overflow: hidden;
  padding: calc(${theme?.main?.spacing} / 2);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`
const ModalHeader = styled.div`
  position: relative;
`
const ModalTitle = styled.div`
  border-bottom: solid 01px ${theme?.color?.primary};
  color: ${theme?.color?.primary};
  display: block;
  font-weight: ${theme?.font?.bold};
  padding: 0 0 calc(${theme?.main?.spacing} / 2) 0;
  margin: 0 -15px calc(${theme?.main?.spacing} / 1.5);
  text-align: center;
  text-transform: uppercase;
`
const ModalContent = styled.div`
  max-height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
`
const ModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  &:hover {
    opacity: 0.3;
  }
  svg {
    fill: ${theme?.color?.primary};
  }
`

export default ({
  children,
  className = '',
  onClick,
  title = '',
  width = 960,
}: PropType) => (
  <Modal>
    <ModalContainer style={{ width: width }}>
      <ModalHeader>
        <ModalClose onClick={onClick}>
          <Icon type="close" />
        </ModalClose>
        {title && <ModalTitle>{title}</ModalTitle>}
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  </Modal>
)
