import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../utils/api'

export default (): [any] => {
  const history = useHistory()

  const handleLogout = useCallback(
    async event => {
      event?.preventDefault()
      api.logout().then(() => history?.push('/login'))
    },
    [history],
  )

  return [handleLogout]
}
