import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import PregnantType from '../types/pregnant'
import api from '../utils/api'
import dialog from '../utils/dialog'

import usePregnants from '../hooks/pregnants'

export default (): [PregnantType, any, any, any] => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { pregnantId }: any = useParams()
  const [pregnant, setPregnant] = useState(null as PregnantType)
  const [pregnants] = usePregnants()

  useEffect(() => {
    if (pregnantId !== pregnant?.uid) {
      api.getPregnant(pregnantId).then(pregnant => {
        if (!pregnant?.name) {
          history?.push('/pregnants')
          return
        }
        setPregnant(pregnant)
      })
    }
  }, [pregnantId, pregnant, history])

  const handleChange = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      const { name, value } = event?.target
      setPregnant({
        ...pregnant,
        [String(name)]: value,
      } as PregnantType)
    },
    [pregnant],
  )

  const handleSubmit = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      dialog?.confirm(() => {
        api
          .updatePregnant(pregnant, pregnants, !pathname.includes('/edit'))
          .then(pregnant => {
            setPregnant(pregnant)
            if (pathname.includes('/edit')) history?.goBack()
            else history?.go(0)
          })
      })
    },
    [pregnant, pregnants, pathname, history],
  )

  const handleBack = (event: any) => {
    event?.preventDefault()
    event?.stopPropagation()
    dialog?.confirm(() => {
      history?.goBack()
    })
  }

  return [pregnant, handleChange, handleSubmit, handleBack]
}
