import React from 'react'
import styled from 'styled-components/macro'

import Box from '../components/Box'
import Column from '../components/Column'
import Field from '../components/Field'
import List from '../components/List'
import Row from '../components/Row'
import useExam from '../hooks/exam'
import ExamType from '../types/exam'
import date from '../utils/date'
import theme from '../utils/theme'

type PropType = {
  isFinished?: boolean
  dum?: string
  defaultExam?: ExamType
  exams?: Array<ExamType>
  onChange?: any
}

const Exams = styled.div`
  max-width: 610px;
  overflow: hidden;
  position: relative;
`
const Item = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 20px;
  font-size: 86%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  &.true {
    background: ${theme?.color?.primary} !important;
    span,
    input {
      color: white !important;
    }
  }
  input {
    background: transparent !important;
    border: 0 !important;
    color: ${theme?.color?.primary} !important;
    line-height: 20px;
    height: 20px !important;
    margin: 0 !important;
    padding: 0 10px !important;
    &::placeholder {
      color: ${theme?.color?.primary} !important;
      opacity: 0.6 !important;
    }
  }
`
const Title = styled.span.attrs({
  className: 'Title',
})`
  color: ${theme?.color?.primary};
  display: block;
  font-weight: ${theme?.font?.bold};
  text-transform: uppercase;
`
const Date = styled.div`
  color: ${theme?.color?.primary};
  display: block;
  font-size: 85%;
  font-weight: ${theme?.font?.bold};
  position: relative;
  text-transform: uppercase;
  text-align: center;
  height: 32px;
  input {
    background: transparent !important;
    border: 0 !important;
    color: ${theme?.color?.primary} !important;
    display: block !important;
    height: 32px !important;
    margin: 0 !important;
    max-width: 82.5%;
    padding: 0 !important;
    position: relative !important;
    text-align: center !important;
    text-transform: uppercase !important;
    transform: translate(25px, -05px);
    width: 82.5%;
  }
`
const CustomItem = styled.div`
  display: inline-block;
  margin-left: 15px;
  max-width: 130px;
  overflow: hidden;
  position: relative;
  width: 130px;
  &:first-child {
    margin-left: 0;
    max-width: 150px;
    padding-left: 0;
    width: 150px;
  }
`
const CustomList = styled(List)`
  background: ${theme?.color?.initial};
  border-radius: 10px;
  overflow: hidden;
  li:nth-child(even) {
    background: ${theme?.color?.secondary};
  }
  &.false {
    background: ${theme?.color?.secondary};
    opacity: 0.3;
    * {
      visibility: hidden;
    }
  }
`
const Content = styled.span`
  position: relative;
  width: 100%;
  color: ${theme?.color?.primary};
  display: block;
  padding-left: 10px;
  font-weight: ${theme?.font?.bold};
  &.true {
    background: ${theme?.color?.primary} !important;
    color: white !important;
  }
`
const CustomColumn = styled(Column)`
  overflow-x: scroll;
  margin: 0;
  white-space: nowrap;
`
const CustomRow = styled(Row)`
  margin-bottom: -4.5px;
  margin-top: -3px;
`
const Spacing = styled.div`
  padding: 15px 0;
`
const CustomField = styled(Field)`
  &.true {
    background: ${theme?.color?.primary} !important;
    color: white !important;
  }
`
export default ({
  isFinished = false,
  dum = '',
  defaultExam,
  exams = [],
  onChange,
}: PropType) => {
  const [
    hb,
    ht,
    glicemia,
    ttog,
    ttog1,
    ttog2,
    vdrl,
    ts,
    toxo,
    rubeola,
    cmv,
    hiv,
    hcv,
    hbsag,
    antihbs,
    suuro,
    t4l,
    tsh,
    coombsind,
    vitamind,
    ferritin,
  ] = useExam()

  return (
    <Exams>
      <Box title="Exames">
        <CustomRow>
          <Column className="column-20">
            <Spacing />
            <List>
              <Item>
                <Title>HB</Title>
              </Item>
              <Item>
                <Title>HT</Title>
              </Item>
              <Item>
                <Title>Glicemia</Title>
              </Item>
              <Item>
                <Title>TTOG</Title>
              </Item>
              <Item>
                <Title>VDRL</Title>
              </Item>
              <Item>
                <Title>TS</Title>
              </Item>
              <Item>
                <Title>TOXO</Title>
              </Item>
              <Item>
                <Title>Rubéola</Title>
              </Item>
              <Item>
                <Title>cmv</Title>
              </Item>
              <Item>
                <Title>HIV</Title>
              </Item>
              <Item>
                <Title>HCV</Title>
              </Item>
              <Item>
                <Title>HBSAG</Title>
              </Item>
              <Item>
                <Title>Anti HBs</Title>
              </Item>
              <Item>
                <Title>SU+URO</Title>
              </Item>
              <Item>
                <Title>T4L (0,7-1,5)</Title>
              </Item>
              <Item>
                <Title>TSH (0,3-2,5)</Title>
              </Item>
              <Item>
                <Title>coombs ind</Title>
              </Item>
              <Item>
                <Title>Vitamina D</Title>
              </Item>
              <Item>
                <Title>Ferritin</Title>
              </Item>
            </List>
          </Column>
          <CustomColumn>
            {!isFinished ? (
              <CustomItem>
                <Date>
                  <Field
                    defaultValue={defaultExam?.date}
                    max={
                      dum
                        ? date.min(
                            date.getSumByDay(dum, 280),
                            date.getNewDate(),
                          )
                        : date.getNewDate()
                    }
                    name="exam.date"
                    onChange={onChange}
                    type="date"
                  />
                </Date>
                <CustomList
                  className={String(defaultExam?.date ? true : false)}
                >
                  <Item className={`${hb(defaultExam?.hb)}`}>
                    <Field
                      defaultValue={defaultExam?.hb}
                      name="exam.hb"
                      onChange={onChange}
                      placeholder="HB"
                      type="text"
                    />
                  </Item>
                  <Item className={`${ht(defaultExam?.ht)}`}>
                    <Field
                      defaultValue={defaultExam?.ht}
                      name="exam.ht"
                      onChange={onChange}
                      placeholder="HT"
                      type="text"
                    />
                  </Item>
                  <Item className={`${glicemia(defaultExam?.glicemia)}`}>
                    <Field
                      defaultValue={defaultExam?.glicemia}
                      name="exam.glicemia"
                      onChange={onChange}
                      placeholder="Glicemia"
                      type="text"
                    />
                  </Item>
                  <Item>
                    <CustomField
                      className={`${ttog(defaultExam?.ttog)}`}
                      defaultValue={defaultExam?.ttog}
                      name="exam.ttog"
                      onChange={onChange}
                      placeholder="JEJ"
                      type="number"
                    />
                    <CustomField
                      className={`${ttog1(defaultExam?.ttog1)}`}
                      defaultValue={defaultExam?.ttog1}
                      name="exam.ttog1"
                      onChange={onChange}
                      placeholder="1h"
                      required={defaultExam?.ttog !== ''}
                      type="number"
                    />
                    <CustomField
                      className={`${ttog2(defaultExam?.ttog2)}`}
                      defaultValue={defaultExam?.ttog2}
                      name="exam.ttog2"
                      onChange={onChange}
                      placeholder="2h"
                      required={defaultExam?.ttog !== ''}
                      type="number"
                    />
                  </Item>
                  <Item className={`${vdrl(defaultExam?.vdrl)}`}>
                    <Field
                      defaultValue={defaultExam?.vdrl}
                      label={vdrl(defaultExam?.vdrl)}
                      name="exam.vdrl"
                      onChange={onChange}
                      type="vdrl"
                    />
                  </Item>
                  <Item className={`${ts(defaultExam?.ts)}`}>
                    <Field
                      defaultValue={defaultExam?.ts}
                      name="exam.ts"
                      onChange={onChange}
                      placeholder="TS"
                      type="text"
                    />
                  </Item>
                  <Item className={`${toxo(defaultExam?.toxo)}`}>
                    <Field
                      defaultValue={defaultExam?.toxo}
                      label={toxo(defaultExam?.toxo)}
                      name="exam.toxo"
                      onChange={onChange}
                      type="toxo"
                    />
                  </Item>
                  <Item className={`${rubeola(defaultExam?.rubeola)}`}>
                    <Field
                      defaultValue={defaultExam?.rubeola}
                      label={rubeola(defaultExam?.rubeola)}
                      name="exam.rubeola"
                      onChange={onChange}
                      type="rubeola"
                    />
                  </Item>
                  <Item className={`${cmv(defaultExam?.cmv)}`}>
                    <Field
                      defaultValue={defaultExam?.cmv}
                      label={cmv(defaultExam?.cmv)}
                      name="exam.cmv"
                      onChange={onChange}
                      type="cmv"
                    />
                  </Item>
                  <Item className={`${hiv(defaultExam?.hiv)}`}>
                    <Field
                      defaultValue={defaultExam?.hiv}
                      name="exam.hiv"
                      onChange={onChange}
                      placeholder="HIV"
                      type="text"
                    />
                  </Item>
                  <Item className={`${hcv(defaultExam?.hcv)}`}>
                    <Field
                      defaultValue={defaultExam?.hcv}
                      name="exam.hcv"
                      onChange={onChange}
                      placeholder="HCV"
                      type="text"
                    />
                  </Item>
                  <Item className={`${hbsag(defaultExam?.hbsag)}`}>
                    <Field
                      defaultValue={defaultExam?.hbsag}
                      label={hbsag(defaultExam?.hbsag)}
                      name="exam.hbsag"
                      onChange={onChange}
                      type="hbsag"
                    />
                  </Item>
                  <Item className={`${antihbs(defaultExam?.antihbs)}`}>
                    <Field
                      defaultValue={defaultExam?.antihbs}
                      label={antihbs(defaultExam?.antihbs)}
                      name="exam.antihbs"
                      onChange={onChange}
                      type="antihbs"
                    />
                  </Item>
                  <Item className={`${suuro(defaultExam?.suuro)}`}>
                    <Field
                      defaultValue={defaultExam?.suuro}
                      name="exam.suuro"
                      onChange={onChange}
                      placeholder="SU+URO"
                      type="text"
                    />
                  </Item>
                  <Item className={`${t4l(defaultExam?.t4l)}`}>
                    <Field
                      defaultValue={defaultExam?.t4l}
                      name="exam.t4l"
                      onChange={onChange}
                      placeholder="T4L (0,7-1,5)"
                      type="text"
                    />
                  </Item>
                  <Item className={`${tsh(defaultExam?.tsh)}`}>
                    <Field
                      defaultValue={defaultExam?.tsh}
                      name="exam.tsh"
                      onChange={onChange}
                      placeholder="TSH (0,3-2,5)"
                      type="text"
                    />
                  </Item>
                  <Item className={`${coombsind(defaultExam?.coombsind)}`}>
                    <Field
                      defaultValue={defaultExam?.coombsind}
                      name="exam.coombsind"
                      onChange={onChange}
                      placeholder="coombs ind"
                      type="text"
                    />
                  </Item>
                  <Item className={`${vitamind(defaultExam?.vitamind)}`}>
                    <Field
                      defaultValue={defaultExam?.vitamind}
                      name="exam.vitamind"
                      onChange={onChange}
                      placeholder="Vitamina D"
                      type="text"
                    />
                  </Item>
                  <Item className={`${ferritin(defaultExam?.ferritin)}`}>
                    <Field
                      defaultValue={defaultExam?.ferritin}
                      name="exam.ferritin"
                      onChange={onChange}
                      placeholder="Ferritin"
                      type="text"
                    />
                  </Item>
                </CustomList>
              </CustomItem>
            ) : (
              ''
            )}
            {/* // @ts-ignore */}
            {Array.isArray(exams) && exams.length
              ? exams
                  ?.sort((a: any, b: any) => b?.date - a?.date)
                  ?.map((exam: ExamType, index: number) => (
                    <CustomItem key={index}>
                      <Date>{date.parse(exam?.date) || ''}</Date>
                      <CustomList>
                        <Item className={`${hb(exam?.hb)}`}>
                          <Content>{exam?.hb || ''}</Content>
                        </Item>
                        <Item className={`${ht(exam?.ht)}`}>
                          <Content>{exam?.ht || ''}</Content>
                        </Item>
                        <Item className={`${glicemia(exam?.glicemia)}`}>
                          <Content>{exam?.glicemia || ''}</Content>
                        </Item>
                        {parseFloat(exam?.ttog || '') ? (
                          <Item>
                            <Content className={`${ttog(exam?.ttog)}`}>
                              {exam?.ttog || ''}
                            </Content>
                            <Content className={`${ttog1(exam?.ttog1)}`}>
                              {exam?.ttog1 || ''}
                            </Content>
                            <Content className={`${ttog2(exam?.ttog2)}`}>
                              {exam?.ttog2 || ''}
                            </Content>
                          </Item>
                        ) : (
                          <Item className={`${ttog(exam?.ttog)}`}>
                            <Content>{exam?.ttog || ''}</Content>
                          </Item>
                        )}
                        <Item className={`${vdrl(exam?.vdrl)}`}>
                          <Content>{exam?.vdrl || ''}</Content>
                        </Item>
                        <Item className={`${ts(exam?.ts)}`}>
                          <Content>{exam?.ts || ''}</Content>
                        </Item>
                        <Item className={`${toxo(exam?.toxo)}`}>
                          <Content>{exam?.toxo || ''}</Content>
                        </Item>
                        <Item className={`${rubeola(exam?.rubeola)}`}>
                          <Content>{exam?.rubeola || ''}</Content>
                        </Item>
                        <Item className={`${cmv(exam?.cmv)}`}>
                          <Content>{exam?.cmv || ''}</Content>
                        </Item>
                        <Item className={`${hiv(exam?.hiv)}`}>
                          <Content>{exam?.hiv || ''}</Content>
                        </Item>
                        <Item className={`${hcv(exam?.hcv)}`}>
                          <Content>{exam?.hcv || ''}</Content>
                        </Item>
                        <Item className={`${hbsag(exam?.hbsag)}`}>
                          <Content>{exam?.hbsag || ''}</Content>
                        </Item>
                        <Item className={`${antihbs(exam?.antihbs)}`}>
                          <Content>{exam?.antihbs || ''}</Content>
                        </Item>
                        <Item className={`${suuro(exam?.suuro)}`}>
                          <Content>{exam?.suuro || ''}</Content>
                        </Item>
                        <Item className={`${t4l(exam?.t4l)}`}>
                          <Content>{exam?.t4l || ''}</Content>
                        </Item>
                        <Item className={`${tsh(exam?.tsh)}`}>
                          <Content>{exam?.tsh || ''}</Content>
                        </Item>
                        <Item className={`${coombsind(exam?.coombsind)}`}>
                          <Content>{exam?.coombsind || ''}</Content>
                        </Item>
                        <Item className={`${vitamind(exam?.vitamind)}`}>
                          <Content>{exam?.vitamind || ''}</Content>
                        </Item>
                        <Item className={`${ferritin(exam?.ferritin)}`}>
                          <Content>{exam?.ferritin || ''}</Content>
                        </Item>
                      </CustomList>
                    </CustomItem>
                  ))
              : null}
          </CustomColumn>
        </CustomRow>
      </Box>
    </Exams>
  )
}
