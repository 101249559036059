import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Column from '../components/Column'
import Container from '../components/Container'
import Field from '../components/Field'
import Header from '../components/Header'
import Main from '../components/Main'
import Row from '../components/Row'
import useDoctor from '../hooks/doctor'
import useRegisterPregnant from '../hooks/registerPregnant'
import theme from '../utils/theme'

const RegisterForm = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  display: block;
  padding: ${theme?.main?.spacing} ${theme?.main?.spacing}
    calc(${theme?.main?.spacing} / 3);
  position: relative;
`
const Grid = styled.div`
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    div {
      width: 100%;
      & + div {
        margin-left: 20px;
      }
    }
  }
`
const CustomGridFirst66 = styled(Grid)`
  @media (min-width: 480px) {
    & > div {
      width: 33%;
      &:first-child {
        width: 66%;
      }
    }
  }
`
const CustomGridFirst33 = styled(Grid)`
  @media (min-width: 480px) {
    & > div {
      width: 66%;
      &:first-child {
        width: 33%;
      }
    }
  }
`
const ButtonGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  button + button {
    margin-left: 20px;
  }
`
const CustomGridAll33 = styled.div`
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    & > * {
      width: 100%;
      & + * {
        margin-left: 10px;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:nth-child(3) {
        width: 95%;
      }
    }
  }
`

export default () => {
  const [
    pregnant,
    handleChange,
    handleSubmit,
    handleClean,
    handleBack,
  ] = useRegisterPregnant()
  const [doctor] = useDoctor()
  const [activeForm, setActiveForm] = useState(0)

  const getShowform = (number: number): string =>
    activeForm === number ? '' : 'none'

  return (
    <Container>
      <Header />
      <Main>
        <Row>
          <Column className="column-10" />
          <Column>
            <RegisterForm>
              <div style={{ display: getShowform(0) }}>
                <Field label="Cadastro de paciente" type="legend" />
                <Field
                  defaultValue={pregnant?.name || ''}
                  label="Nome*"
                  name="name"
                  onChange={handleChange}
                  type="text"
                />
                <Grid>
                  <Field
                    defaultValue={pregnant?.birthdate || ''}
                    label="Data de nascimento*"
                    name="birthdate"
                    onChange={handleChange}
                    type="birthdate"
                  />
                  <Field
                    defaultValue={pregnant?.cpf || ''}
                    label="CPF*"
                    name="cpf"
                    onChange={handleChange}
                    type="cpf"
                  />
                </Grid>
                <Grid>
                  <Field
                    defaultValue={pregnant?.email || ''}
                    label="Email*"
                    name="email"
                    onChange={handleChange}
                    type="email"
                  />
                  <Field
                    defaultValue={pregnant?.phone || ''}
                    label="Telefone*"
                    name="phone"
                    onChange={handleChange}
                    type="phone"
                  />
                </Grid>
                <CustomGridFirst66>
                  <Field
                    defaultValue={pregnant?.address || ''}
                    label="Endereço"
                    name="address"
                    onChange={handleChange}
                    type="text"
                  />
                  <Field
                    defaultValue={pregnant?.naturality || ''}
                    label="Naturalidade"
                    name="naturality"
                    onChange={handleChange}
                    type="text"
                  />
                </CustomGridFirst66>
                <Grid>
                  <Field
                    defaultValue={pregnant?.job || ''}
                    label="Profissão"
                    name="job"
                    onChange={handleChange}
                    type="text"
                  />
                  <Field
                    defaultValue={pregnant?.healthPlans || ''}
                    label="Plano de Saúde"
                    name="healthPlans"
                    onChange={handleChange}
                    type="healthPlans"
                  />
                </Grid>
                <CustomGridFirst66>
                  <Field
                    defaultValue={pregnant?.husband || ''}
                    label="Nome do Companheiro"
                    name="husband"
                    onChange={handleChange}
                    type="text"
                  />
                  <Field
                    defaultValue={pregnant?.maritalStatus || ''}
                    label="Estado Cívil"
                    name="maritalStatus"
                    onChange={handleChange}
                    type="maritalStatus"
                  />
                </CustomGridFirst66>
              </div>
              <div style={{ display: getShowform(1) }}>
                <Field label="Dados gestacionais" type="legend" />
                <CustomGridFirst33>
                  <Field
                    defaultValue={pregnant?.dum || ''}
                    label="Data da Última Menstruação (DUM)"
                    name="dum"
                    onChange={handleChange}
                    type="date"
                  />
                  <Field
                    defaultValue={pregnant?.obstetricRisk || ''}
                    label="Risco Obstétrico (use ',' para separar os riscos)"
                    name="obstetricRisk"
                    onChange={handleChange}
                    placeholder="ex: diabetes, obesidade, hipertensão"
                    type="text"
                  />
                </CustomGridFirst33>
                <CustomGridFirst33>
                  <Field
                    defaultValue={pregnant?.d1usg || ''}
                    label="Data do 1º ultrassom (Data 1º USG)"
                    name="d1usg"
                    onChange={handleChange}
                    type="date"
                  />
                  <Field
                    defaultValue={pregnant?.previousPathological || ''}
                    label="História patológica pregressa"
                    name="previousPathological"
                    onChange={handleChange}
                    type="text"
                  />
                </CustomGridFirst33>
                <CustomGridFirst33>
                  <Field
                    defaultValue={
                      String(pregnant?.d1usg)?.length ? pregnant?.ig1usg : ''
                    }
                    disabled={String(pregnant?.d1usg)?.length ? false : true}
                    label="Idade gestacional no 1º ultrassom (IG 1º USG)"
                    name="ig1usg"
                    onChange={handleChange}
                    type="ig1usg"
                  />
                  <Field
                    defaultValue={pregnant?.obstetricBackground || ''}
                    label="Antecedentes obstétricos"
                    name="obstetricBackground"
                    onChange={handleChange}
                    type="text"
                  />
                </CustomGridFirst33>
                <CustomGridAll33>
                  <Field
                    defaultValue={pregnant?.gestationType || ''}
                    label="Tipo de Gestação"
                    name="gestationType"
                    onChange={handleChange}
                    type="gestationTypes"
                  />
                  <Field
                    defaultValue={pregnant?.height || ''}
                    label="Altura"
                    name="height"
                    onChange={handleChange}
                    placeholder="ex: 160 cm"
                    type="height"
                  />
                  <Field
                    defaultValue={pregnant?.weight || ''}
                    label="Peso"
                    name="weight"
                    onChange={handleChange}
                    placeholder="ex: 50,0 kg"
                    type="weight"
                  />
                </CustomGridAll33>
                <CustomGridFirst33>
                  <Field
                    defaultValue={pregnant?.baby || ''}
                    label="Nome do bebê"
                    name="baby"
                    onChange={handleChange}
                    type="text"
                  />
                  <Field
                    defaultValue={pregnant?.vaccines || ''}
                    label="Vacinas"
                    name="vaccines"
                    onChange={handleChange}
                    placeholder="Hepatite B, H1N1, DTPA"
                    type="text"
                  />
                </CustomGridFirst33>
                <CustomGridFirst33>
                  <Field
                    defaultValue={pregnant?.birthType || ''}
                    label="Tipo do parto"
                    name="birthType"
                    onChange={handleChange}
                    type="birthTypes"
                  />
                  <Field
                    defaultValue={pregnant?.allergy || ''}
                    label="Alergias"
                    name="allergy"
                    onChange={handleChange}
                    type="text"
                  />
                </CustomGridFirst33>
                <CustomGridFirst33>
                  <Field
                    defaultValue={pregnant?.place || ''}
                    label="Local do parto"
                    name="place"
                    onChange={handleChange}
                    type="text"
                  />
                  <Field
                    defaultValue={pregnant?.note || ''}
                    label="Observações"
                    name="note"
                    onChange={handleChange}
                    type="text"
                  />
                </CustomGridFirst33>
              </div>
            </RegisterForm>
            <ButtonGroup>
              <div>
                {doctor?.pregnants ? (
                  <Button onClick={handleBack} size="large">
                    Voltar
                  </Button>
                ) : null}
                <Button onClick={handleClean} size="large">
                  Limpar campos
                </Button>
              </div>
              <div>
                {activeForm === 0 ? (
                  <Button onClick={() => setActiveForm(1)} size="large">
                    Dados gestacionais
                  </Button>
                ) : (
                  <Button onClick={() => setActiveForm(0)} size="large">
                    Cadastro de paciente
                  </Button>
                )}
                <Button onClick={handleSubmit} size="large">
                  Salvar
                </Button>
              </div>
            </ButtonGroup>
          </Column>
          <Column className="column-10" />
        </Row>
      </Main>
    </Container>
  )
}
