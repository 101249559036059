import normalize from '../utils/normalize'

export default (height: any = '', weight: any = ''): number => {
  if (
    !height ||
    typeof height !== 'string' ||
    !weight ||
    typeof weight !== 'string'
  )
    return 0

  weight = normalize?.parseWeight(weight)
  height = normalize?.parseHeight(height)

  return parseFloat(((weight / (height * height)) * 10000).toFixed(2))
}
