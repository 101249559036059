import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  children?: any
}

const Main = styled.main`
  padding-top: calc(${theme?.main?.spacing} + 95px);
`

export default ({ children }: PropType) => <Main>{children}</Main>
