import React from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Column from '../components/Column'
import Field from '../components/Field'
import Logo from '../components/Logo'
import Row from '../components/Row'
import useLogin from '../hooks/login'
import theme from '../utils/theme'

const SignIn = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  display: block;
  left: 50%;
  max-width: 35rem;
  padding: calc(${theme?.main?.spacing} / 2) ${theme?.main?.spacing}
    calc(${theme?.main?.spacing} / 5);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  button {
    margin: ${theme?.main?.spacing} 0;
  }
  a {
    margin: calc(${theme?.main?.spacing} * 0.5) 0;
  }
`
const RegisterLink = styled.a`
  color: ${theme?.color?.primary};
  transition: all 0.2s linear;
  font-weight: bold;
`
const ForgotLink = styled.a`
  color: ${theme?.color?.primary};
  transition: all 0.2s linear;
  font-weight: bold;
  font-size: 12px;
`
const CustomField = styled(Field)`
  margin-bottom: calc(${theme?.main?.spacing} / 5) !important;
`
const LoginButton = styled(Button)`
  background: ${theme?.color?.primary};
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
`

export default () => {
  const [login, handleChange, handleSubmit] = useLogin()

  return (
    <SignIn onSubmit={handleSubmit}>
      <Logo />
      <Field
        defaultValue={login?.email}
        name="email"
        label="Digite seu email"
        onChange={handleChange}
        type="email"
      />
      <CustomField
        defaultValue={login?.password}
        name="password"
        label="Digite sua senha"
        onChange={handleChange}
        type="password"
      />
      <Row>
        <Column className="column-70" />
        <Column>
          <ForgotLink href="/recuperar-senha">Esqueceu a senha?</ForgotLink>
        </Column>
      </Row>
      <LoginButton size="large">Entrar</LoginButton>
      <Column>
        <RegisterLink href="/registrar">Ainda não se cadastrou?</RegisterLink>
      </Column>
    </SignIn>
  )
}
