import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import DoctorType from '../types/doctor'
import api from '../utils/api'
import dialog from '../utils/dialog'

export default (): [DoctorType, any, any] => {
  const history = useHistory()
  const { doctorId }: any = useParams()
  const [doctor, setDoctor] = useState(null)

  useEffect(() => {
    api.getDoctor(doctorId).then(data => {
      if (!data?.uid) history?.push('/login')
      else setDoctor(data)
      if (!data?.pregnants) history?.push(`/${data?.uid}/register`)
    })
  }, [doctorId, history])

  const handleChange = (event: any): void => {
    event?.preventDefault()
    event?.stopPropagation()
    let { name, value } = event?.target
    // @ts-ignore
    setDoctor({ ...doctor, [String(name)]: value })
  }

  const handleSubmit = (event: any): void => {
    event?.preventDefault()
    event?.stopPropagation()
    dialog?.confirm(() => {
      api.updateDoctor(doctor)
    })
  }

  return [doctor, handleChange, handleSubmit]
}
