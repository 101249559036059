import { useCallback, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import PregnantType from '../types/pregnant'

import RegisterPregantType from '../types/registerPregnant'
import api from '../utils/api'
import dialog from '../utils/dialog'
import storage from '../utils/storage'

const initialValue = {
  name: '',
  birthdate: '',
  cpf: '',
  email: '',
  phone: '',
  address: '',
  naturality: '',
  job: '',
  maritalStatus: 'Indefinido',
  husband: '',
  healthPlans: 'Amil',
  height: '',
  weight: '',
  obstetricRisk: '',
  previousPathological: '',
  obstetricBackground: '',
  vaccines: '',
  allergy: '',
  note: '',
  baby: '',
  birthType: 'Vaginal',
  place: '',
  dum: '',
  d1usg: '',
  ig1usg: '',
  gestationType: 'Única',
  isFinished: false,
}

interface IState {
  pregnants?: PregnantType[]
}

export default (): [RegisterPregantType, any, any, any, any] => {
  const location = useLocation()
  const pregnants = (location.state as IState)?.pregnants || []

  const history = useHistory()
  const { doctorId }: any = useParams()

  const [pregnant, setPregnant] = useState({
    ...initialValue,
    ...storage.get('registerPregnant'),
  })

  const handleChange = (event: any) => {
    event?.preventDefault()
    event?.stopPropagation()
    let { name, value } = event?.target
    var data = { ...pregnant, [String(name)]: value }
    setPregnant(data)
    storage.set('registerPregnant', data)
  }

  const handleSubmit = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      dialog?.confirm(() => {
        api
          .registerPregnant(doctorId, pregnant, pregnants)
          .then(newPregnant => {
            if (!newPregnant?.uid) return
            setPregnant(initialValue)
            storage.set('registerPregnant', initialValue)
            history?.push(
              `/${doctorId}/pregnants/${newPregnant?.uid}/gestations/${newPregnant?.gestations?.[0]?.uid}`,
            )
          })
      })
    },
    [doctorId, pregnant, pregnants, history],
  )

  const handleClean = (event: any) => {
    event?.preventDefault()
    event?.stopPropagation()
    dialog?.confirm(() => {
      setPregnant(initialValue)
      storage.remove('registerPregnant')
      history?.go(0)
    })
  }

  const handleBack = (event: any) => {
    event?.preventDefault()
    event?.stopPropagation()
    dialog?.confirm(() => {
      storage.remove('registerPregnant')
      history?.goBack()
    })
  }

  return [pregnant, handleChange, handleSubmit, handleClean, handleBack]
}
