export default (): any => {
  const hb = (value?: any): boolean => value && parseFloat(value) < 11
  const ht = (value?: any): boolean => false
  const glicemia = (value?: any): boolean => value && parseFloat(value) > 92
  const ttog = (value?: any): boolean =>
    value && (value.toLowerCase().includes('alter') || parseFloat(value) > 92)
  const ttog1 = (value?: any): boolean => value && parseFloat(value) > 180
  const ttog2 = (value?: any): boolean => value && parseFloat(value) > 153
  const vdrl = (value?: any): boolean =>
    value && value.toLowerCase().includes('pos')
  const ts = (value?: any): boolean => false
  const toxo = (value?: any): boolean =>
    value && value.toLowerCase().includes('infecção')
  const rubeola = (value?: any): boolean =>
    value && value.toLowerCase().includes('infecção')
  const cmv = (value?: any): boolean =>
    value && value.toLowerCase().includes('infecção')
  const hiv = (value?: any): boolean =>
    value && value.toLowerCase().includes('pos')
  const hcv = (value?: any): boolean =>
    value && value.toLowerCase().includes('pos')
  const hbsag = (value?: any): boolean =>
    value && value.toLowerCase().includes('pos')
  const antihbs = (value?: any): boolean =>
    value && value.toLowerCase().includes('neg')
  const suuro = (value?: any): boolean =>
    value && value.toLowerCase().includes('pos')
  const t4l = (value?: any): boolean =>
    value &&
    (value.replace('.', ',') < '0,7' || value.replace('.', ',') > '1,5')
  const tsh = (value?: any): boolean =>
    value &&
    (value.replace('.', ',') < '0,3' || value.replace('.', ',') > '2,5')
  const coombsind = (value?: any): boolean =>
    value && value.toLowerCase().includes('pos')
  const vitamind = (value?: any): boolean => value && parseFloat(value) < 30
  const ferritin = (value?: any): boolean => value && parseFloat(value) < 30

  return [
    hb,
    ht,
    glicemia,
    ttog,
    ttog1,
    ttog2,
    vdrl,
    ts,
    toxo,
    rubeola,
    cmv,
    hiv,
    hcv,
    hbsag,
    antihbs,
    suuro,
    t4l,
    tsh,
    coombsind,
    vitamind,
    ferritin,
  ]
}
