import { useEffect, useState } from 'react'

import useDoctor from '../hooks/doctor'
import PregnantType from '../types/pregnant'
import api from '../utils/api'

export default (): [Array<PregnantType> | null, Function] => {
  const [doctor] = useDoctor()
  const [pregnants, setPregnants] = useState(null)

  useEffect(() => {
    api.getPregnantsByDoctor(doctor).then(pregnants => {
      setPregnants(pregnants)
    })
  }, [doctor])

  return [pregnants, setPregnants]
}
