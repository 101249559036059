export const parse = (value: any): number => {
  if (!value || typeof value !== 'string') return 0

  return parseInt(
    String(value)
      .replace(/\D/g, '')
      .trim(),
  )
}

export default {
  parse,
}
