const locale = 'en-us'
const options = {}

function secondsDiff (startDate: any, endDate: any) {
  return Math.floor((startDate - endDate) / 1000)
}

function minutesDiff (startDate: any, endDate: any) {
  let seconds = secondsDiff(startDate, endDate)
  return Math.floor(seconds / 60)
}

function hoursDiff (startDate: any, endDate: any) {
  let minutes = minutesDiff(startDate, endDate)
  return Math.floor(minutes / 60)
}

function daysDiff2 (value1: string, value2: string) {
  if (!value1) return ''
  let startDate,
    endDate = new Date(value1)
  if (!value2 || typeof value2 !== 'string') {
    startDate = Date.now()
  } else {
    const [year, month, day] = value2.split('-')
    // @ts-ignore
    startDate = new Date(year, month - 1, day).getTime()
  }

  let days = daysDiff(startDate, endDate) % 7
  return days < 0 ? '' : days
}

function daysDiff (startDate: any, endDate: any) {
  let hours = hoursDiff(startDate, endDate)
  return Math.floor(hours / 24)
}

function weeksDiff2 (value1: string, value2: string) {
  if (!value1) return ''
  let startDate,
    endDate = new Date(value1)
  if (!value2 || typeof value2 !== 'string') {
    startDate = Date.now()
  } else {
    const [year, month, day] = value2.split('-')
    // @ts-ignore
    startDate = new Date(year, month - 1, day).getTime()
  }

  let weeks = weeksDiff(startDate, endDate)
  return weeks < 0 || weeks > 42 ? '' : weeks
}

function weeksDiff (startDate: any, endDate: any) {
  let days = daysDiff(startDate, endDate)
  return Math.floor(days / 7)
}

function yearsDiff (startDate: any, endDate: any) {
  let date1 = new Date(startDate)
  let date2 = new Date(endDate)
  return date2.getFullYear() - date1.getFullYear()
}

function monthsDiff (startDate: any, endDate: any) {
  let date1 = new Date(startDate)
  let date2 = new Date(endDate)
  let months = (date2.getFullYear() - date1.getFullYear()) * 12
  months += date2.getMonth() - date1.getMonth()
  return months % 12
}

export const getAge = (value: number): string => {
  const d1 = Date.now()
  const d2 = new Date(value)
  return `${String(yearsDiff(d1, d2))} anos e ${String(
    monthsDiff(d1, d2),
  )} meses`
}

export const getAllDaysOfThisWeek = () => {
  const curr = new Date()
  const first = curr.getDate() - curr.getDay()
  const dates = []
  for (var index = 0; index < 7; index++) {
    dates[index] = new Date(new Date().setDate(first + index))
      .toISOString()
      .split('T')[0]
  }
  return dates
}

export const getAgeByYear = (value: string = ''): string => {
  const startDate = new Date(value)
  const endDate = Date.now()
  return `${String(yearsDiff(startDate, endDate))} anos`
}

export const getAgeByWeek = (
  value1: string = '',
  value2: string = '',
  shouldHide: boolean = false,
  delimiter: string = '',
): string => {
  if (!value1) return ''
  let startDate,
    endDate = new Date(value1)
  if (!value2 || typeof value2 !== 'string') {
    startDate = Date.now()
  } else {
    const [year, month, day] = value2.split('-')
    // @ts-ignore
    startDate = new Date(year, month - 1, day).getTime()
  }

  let timeInWeeks = weeksDiff(startDate, endDate)
  let timeInDays = daysDiff(startDate, endDate) % 7

  return shouldHide && timeInWeeks >= 40
    ? ''
    : ` ${delimiter} ${String(timeInWeeks)} semanas e ${timeInDays} dias`
}

export const getDay = (date: any) => {
  return String(new Date(date)).length === 1 ? `0${date}` : date
}

export const getDaysDiff = (startDate: string, endDate: string): number => {
  const reverseFormat = (date: string): Date =>
    new Date(
      `${date.split('/')[2]}-${date.split('/')[1]}-${date.split('/')[0]}`,
    )
  return daysDiff(reverseFormat(startDate), reverseFormat(endDate))
}

export const getDayName = (date?: Date): string => {
  const days = [
    'Segunda',
    'Terca',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sabado',
    'Domingo',
  ]
  return days[new Date(date || getNewDate()).getDay()]
}

export const getDumDiffWeeks = (dum: string, weeks: number): string => {
  return getSumByDay(dum, 7 * weeks)
}

export const getLastAccompaniment = (accompaniments: any): string => {
  const lastAccompaniment =
    accompaniments
      ?.filter((a: any) => a?.date)
      ?.map((a: any) => a?.date)
      // @ts-ignore
      ?.sort((a: any, b: any) => new Date(b) - new Date(a))[0] || ''

  return lastAccompaniment
    ? `Última consulta dia ${parse(lastAccompaniment)}`
    : 'Não há registro da última consulta'
}

export const getNewDate = () => {
  let [month, day, year] = new Date()
    .toLocaleDateString(locale, options)
    .split('/')
  if (String(month).length === 1) month = `0${month}`
  if (String(day).length === 1) day = `0${day}`
  return `${year}-${month}-${day}`
}

export const getMonth = (date: number) => {
  return String(date++).length === 1 ? `0${date++}` : date++
}

export const getMonthName = (date: string = getNewDate()): string => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]
  return months[parseInt(date.split('-')[1]) - 1]
}

export const getSumByDay = (value: any = '', days: number = 0): string => {
  if (!value || typeof value !== 'string' || !value.includes('-')) return value
  const startDate = new Date(value)
  let [month, day, year] = new Date(
    startDate.setDate(startDate.getDate() + days + 1),
  )
    .toLocaleDateString(locale, options)
    .split('/')
  if (String(month).length === 1) month = `0${month}`
  if (String(day).length === 1) day = `0${day}`
  return `${day}/${month}/${year}`
}

export const getWeeksDiff = (value: string = ''): number => {
  const d1 = Date.now()
  const d2 = new Date(value)
  return weeksDiff(d1, d2)
}

function parse (value: any): string {
  if (!value || typeof value !== 'string' || !value.includes('-')) return value
  let [year, month, day] = value.split('-')

  return `${day}/${month}/${year}`
}

function format (value: any): string {
  if (!value || typeof value !== 'string' || !value.includes('/')) return value
  let [day, month, year] = value.split('/')

  return `${year}-${month}-${day}`
}

function parseCalendar (date: any): string {
  let [month, day, year] = new Date(date)
    .toLocaleDateString(locale, options)
    .split('/')
  if (month.length === 1) month = `0${month}`
  if (day.length === 1) day = `0${day}`
  return `${year}-${month}-${day}`
}

function min (date1: string, date2: string): string {
  let compareDate1 = format(date1)
  let compareDate2 = format(date2)

  if (compareDate1 >= compareDate2) return compareDate2
  else return compareDate1
}

export default {
  daysDiff,
  daysDiff2,
  getAge,
  getAllDaysOfThisWeek,
  getAgeByYear,
  getAgeByWeek,
  getDay,
  getDaysDiff,
  getDayName,
  getDumDiffWeeks,
  getLastAccompaniment,
  getNewDate,
  getMonth,
  getMonthName,
  getSumByDay,
  getWeeksDiff,
  parse,
  parseCalendar,
  weeksDiff2,
  min,
}
