import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Field from '../components/Field'
import List from '../components/List'
import usePregnants from '../hooks/pregnants'
import dateUtils from '../utils/date'
import theme from '../utils/theme'

const PregnantList = styled.div`
  background: #fbe7e8;
  border-radius: ${theme?.main?.radius};
  height: calc(100vh - 140px);
  overflow: hidden;
  padding: ${theme?.main?.spacing};
  position: relative;
  ul {
    display: block;
    height: calc(100vh - 350px);
    margin: 0;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 15px;
  }
`
const Search = styled(Field)`
  background: ${theme?.color?.initial} !important;
`
const Item = styled.li`
  background: ${theme?.color?.initial};
  border-radius: calc(${theme?.main?.radius} / 2);
  margin-bottom: calc(${theme?.main?.spacing} / 1.5);
  padding: calc(${theme?.main?.spacing} / 2.5) calc(${theme?.main?.spacing} / 2);
  &:last-child {
    margin-bottom: 0;
  }
  &.true {
    background: ${theme?.color?.primary};
    span {
      color: ${theme?.color?.initial};
    }
  }
`
const Name = styled.span`
  color: ${theme?.color?.primary};
  display: block;
  font-size: 110%;
  font-weight: ${theme?.font?.bold};
`
const Date = styled.span`
  display: block;
`
const Info = styled.span`
  display: block;
  font-size: 65%;
`
const Message = styled.div`
  text-align: center;
`
const Link = styled.div`
  cursor: pointer;
`
const Footer = styled.div`
  align-items: center;
  background: #fbe7e8;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
`
const CustomButton = styled(Button)`
  background: ${theme?.color?.primary};
  border-color: ${theme?.color?.primary};
  color: ${theme?.color?.initial};
  margin: 0;
  svg {
    fill: ${theme?.color?.initial};
    transform: translateY(-2px);
  }
`

export default () => {
  const history = useHistory()
  const { doctorId, pregnantId }: any = useParams()
  const [pregnants] = usePregnants()
  const [search, setSearch] = useState('')

  if (pregnants == null) return <></>

  pregnants.sort((a, b) =>
    a != null && b != null ? a.name.localeCompare(b.name, 'pt-BR') : 0,
  )

  return (
    <PregnantList>
      <Field placeholder="Pacientes" type="legend" />
      {Array.isArray(pregnants) && pregnants.length ? (
        <>
          <Search
            onChange={(event: any) =>
              setSearch(event?.target?.value.toLowerCase())
            }
            placeholder="Pesquisar paciente"
          />
          <List>
            {pregnants
              ?.map((pregnant: any) => ({
                ...pregnant,
                date: `${dateUtils.getAgeByYear(pregnant?.birthdate)}${
                  pregnant?.gestations?.[0]?.dum
                    ? pregnant?.gestations?.[0]?.isFinished
                      ? ''
                      : `${dateUtils.getAgeByWeek(
                          pregnant?.gestations?.[0]?.dum,
                          '',
                          true,
                          '|',
                        )}`
                    : ''
                }`,
                createdAt: dateUtils.parse(pregnant?.createdAt),
                updatedAt: dateUtils.parse(pregnant?.updatedAt),
                lastAccompaniment: dateUtils.getLastAccompaniment(
                  pregnant?.gestations?.[0]?.accompaniments,
                ),
                gestations: pregnant?.gestations?.map((gestation: any) => ({
                  ...gestation,
                  dum: dateUtils.parse(gestation?.dum),
                  d1usg: dateUtils.parse(gestation?.d1usg),
                })),
              }))
              ?.filter(pregnant =>
                JSON.stringify(pregnant)
                  .toLowerCase()
                  .includes(search),
              )
              ?.map(pregnant => (
                <Item
                  className={String(
                    pregnantId === pregnant?.uid ? true : false,
                  )}
                  key={pregnant?.uid}
                >
                  <Link
                    onClick={() => {
                      history?.push(`/${doctorId}/pregnants/${pregnant?.uid}`)
                    }}
                  >
                    <Name>{pregnant?.name}</Name>
                    <Date>{pregnant?.date}</Date>
                    <Info>{pregnant?.lastAccompaniment}</Info>
                  </Link>
                </Item>
              ))}
          </List>
        </>
      ) : (
        <Message>
          <p>Não há registro de pacientes.</p>
        </Message>
      )}
      <Footer>
        <CustomButton
          onClick={() =>
            history?.push(`/${doctorId}/register`, { pregnants: pregnants })
          }
        >
          Adicionar nova paciente
        </CustomButton>
      </Footer>
    </PregnantList>
  )
}
