import swal from 'sweetalert'

export default {
  alert: (message: string = '') => swal('Atenção', message),

  confirm: (
    confirm: Function = () => {},
    cancel: Function = () => {},
    message: string = '',
    title: string = 'Você tem certeza?',
  ) =>
    swal({
      title,
      text: message,
      buttons: ['Não', 'Sim'],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) confirm()
      else cancel()
    }),
}
