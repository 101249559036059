import React from 'react'
import styled from 'styled-components/macro'

import Accompaniments from '../components/Accompaniments'
import Button from '../components/Button'
import Column from '../components/Column'
import Container from '../components/Container'
import Exams from '../components/Exams'
import GestationTabs from '../components/GestationTabs'
import Header from '../components/Header'
import Loading from '../components/Loading'
import Main from '../components/Main'
import PersonalDetail from '../components/PersonalDetail'
import Row from '../components/Row'
import Ultrasonograms from '../components/Ultrasonograms'
import useConsultation from '../hooks/consultation'
import useGestation from '../hooks/gestation'
import usePregnant from '../hooks/pregnant'

const BackButton = styled(Button)`
  margin-top: 15px;
`
const SaveButton = styled(Button)`
  float: right;
  margin-top: 15px;
  margin-right: 10px;
`

const FinishButton = styled(Button)`
  float: right;
  margin-top: 15px;
`

function onKeyDown (keyEvent: {
  charCode: any
  keyCode: any
  preventDefault: () => void
}) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

export default () => {
  const [pregnant] = usePregnant()
  const [
    consultation,
    handleChange,
    handleSubmit,
    handleFinish,
    handleBack,
    loading,
  ] = useConsultation()
  const [gestation] = useGestation(pregnant)

  if (!gestation?.uid || loading) return <Loading />

  return (
    <Container>
      <Header />
      <Main>
        <GestationTabs pregnant={pregnant} />
        <Row>
          <Column className="column-25">
            <PersonalDetail pregnant={pregnant} />
            <BackButton onClick={handleBack} size="large">
              Voltar
            </BackButton>
          </Column>
          <Column>
            <form onKeyDown={onKeyDown} onSubmit={handleSubmit}>
              <Accompaniments
                accompaniments={gestation?.accompaniments}
                defaultAccompaniment={consultation?.accompaniment}
                onChange={handleChange}
                pregnant={pregnant}
              />
              <Row>
                <Column className="column-40">
                  <Ultrasonograms
                    isFinished={gestation?.isFinished}
                    defaultValue={consultation}
                    mainUltrasonograms={gestation?.mainUltrasonograms}
                    onChange={handleChange}
                    dum={gestation?.dum}
                    ultrasonograms={gestation?.ultrasonograms}
                  />
                </Column>
                <Column>
                  <Exams
                    isFinished={gestation?.isFinished}
                    dum={gestation?.dum}
                    defaultExam={consultation?.exam}
                    exams={gestation?.exams}
                    onChange={handleChange}
                  />
                </Column>
              </Row>
              {!gestation.isFinished ? (
                <FinishButton onClick={handleFinish} size="large">
                  Finalizar Consulta
                </FinishButton>
              ) : null}
              {!gestation.isFinished ? (
                <SaveButton size="large">Salvar</SaveButton>
              ) : null}
            </form>
          </Column>
        </Row>
      </Main>
    </Container>
  )
}
