import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import api from '../utils/api'

const initialUpdateGestation = {
  height: '',
  weight: '',
  dum: '',
  d1usg: '',
  ig1usg: '',
  name: '',
  birthType: 'Vaginal',
  place: '',
  gestationType: 'Única',
  isFinished: false,
  deliveryDate: '',
  finalWeight: '',
  result: '',
}

export default (gestation: any): [any, any, any] => {
  const { gestationId, pregnantId }: any = useParams()
  const [updateGestation, setUpdateGestation] = useState(
    initialUpdateGestation as any,
  )

  useEffect(() => {
    if (!updateGestation?.uid || updateGestation?.uid !== gestationId)
      setUpdateGestation({ ...initialUpdateGestation, ...gestation })
  }, [updateGestation, gestation])

  const handleChange = (event: any) => {
    const { name, value } = event?.target
    setUpdateGestation({ ...updateGestation, [String(name)]: value })
  }

  const handleSubmit = useCallback(
    async (event: any) => {
      event?.preventDefault()
      event?.stopPropagation()
      api.updateGestation({
        gestationId,
        pregnantId,
        ...updateGestation,
      })
    },
    [gestationId, pregnantId, updateGestation],
  )

  return [updateGestation, handleChange, handleSubmit]
}
