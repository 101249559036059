export const validation = (value: string): boolean => {
  if (typeof value !== 'string' || value > '2000-12-31' || value < '1930-12-31')
    return false

  return true
}

export default {
  validation,
}
