import { initialize, pageview, set } from 'react-ga'

import isLocalhost from './localhost'

if (!isLocalhost && String(process?.env?.REACT_APP_TRACKING || '')) {
  initialize(String(process?.env?.REACT_APP_TRACKING || ''))
}

export default (page: any) => {
  if (isLocalhost) return

  set({ page })
  pageview(page)
}
