import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import RecoverType from '../types/recover'
import api from '../utils/api'

const initialRecover = {
  email: '',
}

export default (): [RecoverType, any, any] => {
  const history = useHistory()
  const [recover, setRecover] = useState(initialRecover)

  const handleChange = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      const { name, value } = event?.target
      setRecover({ ...recover, [String(name)]: value })
    },
    [recover],
  )

  const handleSubmit = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      api.recover(recover?.email).then(() => history?.push('/login'))
    },
    [recover, history],
  )

  return [recover, handleChange, handleSubmit]
}
