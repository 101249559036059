export const get = (key: string): any => {
  return JSON.parse(localStorage.getItem(key) || '{}')
}

export const set = (key: string, value: any): void => {
  localStorage.setItem(key, JSON.stringify(value || {}))
}

export const remove = (key: string): void => {
  localStorage.removeItem(key)
}

export default {
  get,
  set,
  remove,
}
