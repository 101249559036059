import React from 'react'
import 'react-vis/dist/style.css'

import date from '../utils/date'
import AccompanimentType from '../types/accompaniment'
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
  LineMarkSeries,
  DiscreteColorLegend,
} from 'react-vis'
import Row from './Row'
import Container from './Container'

type PropType = {
  dum?: string
  gestationType?: string
  defaultAccompaniment?: AccompanimentType
  accompaniments?: AccompanimentType[]
}

const SUBTITTLES = [
  { title: 'Percentis 3 e 97', color: 'black', strokeWidth: '10' },
  { title: 'Percentis 10 e 90', color: 'red', strokeWidth: '10' },
  { title: 'Percentil 50', color: 'green', strokeWidth: '10' },
  { title: 'Feto', color: 'blue', strokeWidth: '10' },
]

const SUBTITLES2 = [
  { title: 'Percentis 3 e 97', color: 'black', strokeWidth: '10' },
  { title: 'Percentis 10 e 90', color: 'red', strokeWidth: '10' },
  { title: 'Percentil 50', color: 'green', strokeWidth: '10' },
  { title: 'Feto A', color: 'blue', strokeWidth: '10' },
  { title: 'Feto B', color: '#2DD9FB', strokeWidth: '10' },
]

const SUBTITLES3 = [
  { title: 'Percentis 3 e 97', color: 'black', strokeWidth: '10' },
  { title: 'Percentis 10 e 90', color: 'red', strokeWidth: '10' },
  { title: 'Percentil 50', color: 'green', strokeWidth: '10' },
  { title: 'Feto A', color: 'blue', strokeWidth: '10' },
  { title: 'Feto B', color: '#2DD9FB', strokeWidth: '10' },
  { title: 'Feto C', color: '#73CD5E', strokeWidth: '10' },
]

const threePercent = [
  { x: 10, y: 26 },
  { x: 11, y: 34 },
  { x: 12, y: 43 },
  { x: 13, y: 55 },
  { x: 14, y: 70 },
  { x: 15, y: 88 },
  { x: 16, y: 110 },
  { x: 17, y: 136 },
  { x: 18, y: 167 },
  { x: 19, y: 205 },
  { x: 20, y: 248 },
  { x: 21, y: 299 },
  { x: 22, y: 359 },
  { x: 23, y: 426 },
  { x: 24, y: 503 },
  { x: 25, y: 589 },
  { x: 26, y: 685 },
  { x: 27, y: 791 },
  { x: 28, y: 908 },
  { x: 29, y: 1034 },
  { x: 30, y: 1169 },
  { x: 31, y: 1313 },
  { x: 32, y: 1465 },
  { x: 33, y: 1622 },
  { x: 34, y: 1783 },
  { x: 35, y: 1946 },
  { x: 36, y: 2110 },
  { x: 37, y: 2271 },
  { x: 38, y: 2427 },
  { x: 39, y: 2576 },
  { x: 40, y: 2714 },
]

const tenPercent = [
  { x: 10, y: 29 },
  { x: 11, y: 37 },
  { x: 12, y: 48 },
  { x: 13, y: 61 },
  { x: 14, y: 77 },
  { x: 15, y: 97 },
  { x: 16, y: 121 },
  { x: 17, y: 150 },
  { x: 18, y: 185 },
  { x: 19, y: 227 },
  { x: 20, y: 275 },
  { x: 21, y: 331 },
  { x: 22, y: 398 },
  { x: 23, y: 471 },
  { x: 24, y: 556 },
  { x: 25, y: 652 },
  { x: 26, y: 758 },
  { x: 27, y: 879 },
  { x: 28, y: 1004 },
  { x: 29, y: 1145 },
  { x: 30, y: 1294 },
  { x: 31, y: 1453 },
  { x: 32, y: 1621 },
  { x: 33, y: 1794 },
  { x: 34, y: 1973 },
  { x: 35, y: 2154 },
  { x: 36, y: 2335 },
  { x: 37, y: 2513 },
  { x: 38, y: 2686 },
  { x: 39, y: 2851 },
  { x: 40, y: 3004 },
]

const fiftyPercent = [
  { x: 10, y: 35 },
  { x: 11, y: 45 },
  { x: 12, y: 58 },
  { x: 13, y: 73 },
  { x: 14, y: 93 },
  { x: 15, y: 117 },
  { x: 16, y: 146 },
  { x: 17, y: 181 },
  { x: 18, y: 223 },
  { x: 19, y: 273 },
  { x: 20, y: 331 },
  { x: 21, y: 399 },
  { x: 22, y: 478 },
  { x: 23, y: 568 },
  { x: 24, y: 670 },
  { x: 25, y: 785 },
  { x: 26, y: 913 },
  { x: 27, y: 1055 },
  { x: 28, y: 1210 },
  { x: 29, y: 1379 },
  { x: 30, y: 1559 },
  { x: 31, y: 1751 },
  { x: 32, y: 1953 },
  { x: 33, y: 2162 },
  { x: 34, y: 2377 },
  { x: 35, y: 2595 },
  { x: 36, y: 2813 },
  { x: 37, y: 3028 },
  { x: 38, y: 3236 },
  { x: 39, y: 3435 },
  { x: 40, y: 3619 },
]

const ninetyPercent = [
  { x: 10, y: 41 },
  { x: 11, y: 53 },
  { x: 12, y: 68 },
  { x: 13, y: 85 },
  { x: 14, y: 109 },
  { x: 15, y: 137 },
  { x: 16, y: 171 },
  { x: 17, y: 212 },
  { x: 18, y: 261 },
  { x: 19, y: 319 },
  { x: 20, y: 387 },
  { x: 21, y: 467 },
  { x: 22, y: 559 },
  { x: 23, y: 665 },
  { x: 24, y: 784 },
  { x: 25, y: 918 },
  { x: 26, y: 1068 },
  { x: 27, y: 1234 },
  { x: 28, y: 1416 },
  { x: 29, y: 1613 },
  { x: 30, y: 1824 },
  { x: 31, y: 2049 },
  { x: 32, y: 2285 },
  { x: 33, y: 2530 },
  { x: 34, y: 2781 },
  { x: 35, y: 3036 },
  { x: 36, y: 3291 },
  { x: 37, y: 3543 },
  { x: 38, y: 3786 },
  { x: 39, y: 4019 },
  { x: 40, y: 4239 },
]

const ninetySevenPercent = [
  { x: 10, y: 44 },
  { x: 11, y: 56 },
  { x: 12, y: 73 },
  { x: 13, y: 91 },
  { x: 14, y: 116 },
  { x: 15, y: 146 },
  { x: 16, y: 183 },
  { x: 17, y: 226 },
  { x: 18, y: 279 },
  { x: 19, y: 341 },
  { x: 20, y: 414 },
  { x: 21, y: 499 },
  { x: 22, y: 598 },
  { x: 23, y: 710 },
  { x: 24, y: 838 },
  { x: 25, y: 981 },
  { x: 26, y: 1141 },
  { x: 27, y: 1319 },
  { x: 28, y: 1513 },
  { x: 29, y: 1728 },
  { x: 30, y: 1949 },
  { x: 31, y: 2189 },
  { x: 32, y: 2441 },
  { x: 33, y: 2703 },
  { x: 34, y: 2971 },
  { x: 35, y: 3244 },
  { x: 36, y: 3516 },
  { x: 37, y: 3785 },
  { x: 38, y: 4045 },
  { x: 39, y: 4294 },
  { x: 40, y: 4524 },
]

export default ({
  dum = '',
  gestationType = 'Única',
  defaultAccompaniment,
  accompaniments = [],
}: PropType) => {
  var array = []

  for (var i = 0; i < accompaniments.length; i++) array[i] = accompaniments[i]

  if (
    defaultAccompaniment &&
    defaultAccompaniment?.date &&
    (defaultAccompaniment?.fetalWeight ||
      (gestationType === 'Gemelar' && defaultAccompaniment?.fetalWeight2) ||
      (gestationType === 'Trigemelar' &&
        (defaultAccompaniment?.fetalWeight2 ||
          defaultAccompaniment?.fetalWeight3)))
  )
    array.push(defaultAccompaniment)

  var tickTotal
  var tickValues

  var filterThreePercent
  var filterTenPercent
  var filterFiftyPercent
  var filterNinetyPercent
  var filterNinetySevenPercent

  tickTotal = 21
  tickValues = [
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
  ]

  filterThreePercent = threePercent.filter(d => {
    return d.x >= 20 && d.x <= 40
  })

  filterTenPercent = tenPercent.filter(d => {
    return d.x >= 20 && d.x <= 40
  })

  filterFiftyPercent = fiftyPercent.filter(d => {
    return d.x >= 20 && d.x <= 40
  })

  filterNinetyPercent = ninetyPercent.filter(d => {
    return d.x >= 20 && d.x <= 40
  })

  filterNinetySevenPercent = ninetySevenPercent.filter(d => {
    return d.x >= 20 && d.x <= 40
  })

  // Curve 1

  var filter =
    dum === ''
      ? null
      : array.filter(d => {
          var ig1usg = date.daysDiff(new Date(d.date), new Date(dum)) / 7
          return (
            d.fetalWeight != null &&
            d.fetalWeight.length > 0 &&
            ig1usg >= 20 &&
            ig1usg <= 40
          )
        })

  var data =
    filter == null
      ? null
      : filter.map(d => {
          return {
            x: date.daysDiff(new Date(d.date), new Date(dum)) / 7,
            y:
              d.fetalWeight == null || d.fetalWeight === ''
                ? 0
                : parseFloat(d.fetalWeight),
          }
        })

  data?.sort((a, b) => (a.x > b.x ? 1 : -1))

  // Curve 2 (if gestationType != Unica)
  var data2

  if (gestationType && gestationType !== 'Única') {
    var filter2 =
      dum === ''
        ? null
        : array.filter(d => {
            var ig1usg = date.daysDiff(new Date(d.date), new Date(dum)) / 7
            return (
              d.fetalWeight2 != null &&
              d.fetalWeight2.length > 0 &&
              ig1usg >= 20 &&
              ig1usg <= 40
            )
          })

    data2 =
      filter2 == null
        ? null
        : filter2.map(d => {
            return {
              x: date.daysDiff(new Date(d.date), new Date(dum)) / 7,
              y:
                d.fetalWeight2 == null || d.fetalWeight2 === ''
                  ? 0
                  : parseFloat(d.fetalWeight2),
            }
          })

    data2?.sort((a, b) => (a.x > b.x ? 1 : -1))
  }

  // Curve 3 (if gestationType == Trigemelar)
  var data3

  if (gestationType === 'Trigemelar') {
    var filter3 =
      dum === ''
        ? null
        : array.filter(d => {
            var ig1usg = date.daysDiff(new Date(d.date), new Date(dum)) / 7
            return (
              d.fetalWeight3 != null &&
              d.fetalWeight3.length > 0 &&
              ig1usg >= 20 &&
              ig1usg <= 40
            )
          })

    data3 =
      filter3 == null
        ? null
        : filter3.map(d => {
            return {
              x: date.daysDiff(new Date(d.date), new Date(dum)) / 7,
              y:
                d.fetalWeight3 == null || d.fetalWeight3 === ''
                  ? 0
                  : parseFloat(d.fetalWeight3),
            }
          })

    data3?.sort((a, b) => (a.x > b.x ? 1 : -1))
  }

  return (
    <Container>
      <Row>
        <XYPlot margin={{ left: 45 }} width={900} height={300} color="black">
          <VerticalGridLines tickTotal={tickTotal} />
          <HorizontalGridLines />
          <XAxis
            title="Idade Gestacional (Em Semanas)"
            tickValues={tickValues}
            tickFormat={function tickFormat (d) {
              return new Intl.NumberFormat().format(d)
            }}
          />
          <YAxis title="Peso Fetal (Em Gramas)" />
          <LineSeries
            color="black"
            curve="curveNatural"
            data={filterThreePercent}
          />
          <LineSeries
            color="red"
            curve="curveNatural"
            data={filterTenPercent}
          />
          <LineSeries
            color="green"
            curve="curveNatural"
            data={filterFiftyPercent}
          />
          <LineSeries
            color="red"
            curve="curveNatural"
            data={filterNinetyPercent}
          />
          <LineSeries
            color="black"
            curve="curveNatural"
            data={filterNinetySevenPercent}
          />
          <LineMarkSeries
            style={{
              strokeWidth: '3px',
            }}
            lineStyle={{ stroke: 'blue' }}
            markStyle={{ stroke: 'blue' }}
            curve="curveNatural"
            data={data == null ? [] : data}
          />
          <LineMarkSeries
            style={{
              strokeWidth: '3px',
            }}
            lineStyle={{ stroke: '#2DD9FB' }}
            markStyle={{ stroke: '#2DD9FB' }}
            curve="curveNatural"
            data={data2 == null ? [] : data2}
          />
          <LineMarkSeries
            style={{
              strokeWidth: '3px',
            }}
            lineStyle={{ stroke: '#73CD5E' }}
            markStyle={{ stroke: '#73CD5E' }}
            curve="curveNatural"
            data={data3 == null ? [] : data3}
          />
        </XYPlot>
      </Row>
      <Row>
        <DiscreteColorLegend
          orientation="horizontal"
          items={
            gestationType === 'Única'
              ? SUBTITTLES
              : gestationType === 'Gemelar'
              ? SUBTITLES2
              : SUBTITLES3
          }
        />
      </Row>
    </Container>
  )
}
