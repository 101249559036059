import { useEffect, useState } from 'react'

import AuthType from '../types/auth'
import api from '../utils/api'

export default (): [AuthType] => {
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    const response = api.getAuth((data: any) => setAuth(data))
    return response
  }, [])

  return [auth]
}
