import imc from '../utils/imc'
import number from '../utils/number'

export const expected = (height: string = '', weight: string = ''): string => {
  const currentIMC = imc(height, weight)
  if (!height || !weight || !currentIMC) return 'Aguardando dados'
  if (currentIMC < 20) return '12,5 kg - 18 kg'
  if (currentIMC >= 20 && currentIMC < 26) return '11,5 kg - 16 kg'
  if (currentIMC >= 26 && currentIMC < 29) return '7 kg - 11,5 kg'
  return '5 kg - 9 kg'
}

export const parse = (value: any): string => {
  if (!value || typeof value !== 'string') return ''
  value = number?.parse(value)

  if (value.length > 3) return `${value.slice(0, 3)} kg`

  return `${value} kg`
}

export const parseFetalWeight = (value: string): string => {
  if (!value || typeof value !== 'string') return ''
  value = value.replace(/\D/g, '').trim()

  if (value.length > 4) return `${value.slice(0, 4)} g`

  return `${value} g`
}

export default {
  expected,
  parse,
  parseFetalWeight,
}
