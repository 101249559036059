import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Box from '../components/Box'
import Button from '../components/Button'
import Loading from '../components/Loading'
import Modal from '../components/Modal'
import PersonalDetailModal from './PersonalDetailModal'
import useGestation from '../hooks/gestation'
import PregnantyType from '../types/pregnant'
import date from '../utils/date'
import weight from '../utils/weight'
import imc from '../utils/imc'
import theme from '../utils/theme'
import FinishGestationModal from './FinishGestationModal'

type PropType = {
  pregnant: PregnantyType | any
}

const PersonalDetail = styled(Box)`
  height: 858px;
  overflow: hidden;
  overflow-y: scroll;
  .Box {
    background: transparent;
    padding: 0;
    &:not(:last-child) {
      margin-bottom: calc(${theme?.main?.spacing} * 1.5);
    }
    &:first-child {
      .Title {
        font-size: 16px;
        text-transform: capitalize;
      }
    }
  }
`
const Tag = styled(Button)`
  background: ${theme?.color?.primary};
  border-color: ${theme?.color?.primary};
  color: ${theme?.color?.initial};
  cursor: inherit;
  font-size: 70%;
  font-weight: ${theme?.font?.regular};
  height: 24px;
  margin: 0 5px 0\0;
  padding: 0 10px;
  &:active,
  &:focus,
  &:hover {
    background: ${theme?.color?.primary};
    border-color: ${theme?.color?.primary};
    color: ${theme?.color?.initial};
    opacity: 1;
  }
  &.button-outline {
    background: transparent;
    border-color: ${theme?.color?.primary};
    color: ${theme?.color?.primary};
    &:active,
    &:focus,
    &:hover {
      background: transparent;
      border-color: ${theme?.color?.primary};
      color: ${theme?.color?.primary};
      opacity: 1;
    }
  }
`
const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  & + div {
    margin-top: 10px;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
`
const Item = styled.div``
const Title = styled.div`
  color: ${theme?.color?.primary};
`
const Description = styled.div``
const ModalButton = styled.span`
  color: ${theme?.color?.primary};
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
`
const Age = styled.div`
  margin-bottom: 20px;
  margin-top: -10px;
`

export default ({ pregnant }: PropType) => {
  const [gestation] = useGestation(pregnant)
  const [toggleSeeMore, setToggleSeeMore] = useState(false)
  const [toggleEdit, setToggleEdit] = useState(false)

  if (!pregnant?.uid || !gestation) return <Loading />

  return (
    <PersonalDetail>
      <Box title={pregnant?.name}>
        <>
          <ModalButton onClick={() => setToggleEdit(true)}>editar</ModalButton>
          <Age>
            <strong>
              {date.getAgeByYear(pregnant?.birthdate)}
              {gestation?.isFinished
                ? ' | Finalizada' +
                  (gestation?.deliveryDate
                    ? ' em ' + date.parse(gestation?.deliveryDate)
                    : '')
                : gestation?.dum
                ? `${date.getAgeByWeek(gestation?.dum, '', true, '|')}`
                : ''}
            </strong>
          </Age>
          <Grid>
            <Item>
              <Title>Tipo de gestação</Title>
              <Description>{gestation?.gestationType || 'Única'}</Description>
            </Item>
            {!gestation?.isFinished ? (
              <Item>
                <Title>Opção de parto</Title>
                <Description>{gestation?.birthType || 'Vaginal'}</Description>
              </Item>
            ) : (
              <Item>
                <Title>Resultado</Title>
                <Description>
                  {gestation?.result || gestation?.birthType || 'Vaginal'}
                </Description>
              </Item>
            )}
          </Grid>
          <Grid>
            <Item>
              <Title>Local do parto</Title>
              <Description>
                {gestation?.place || 'Aguardando dados'}
              </Description>
            </Item>
            <Item>
              <Title>Plano de Saúde</Title>
              <Description>
                {pregnant?.healthPlans || 'Aguardando dados'}
              </Description>
            </Item>
          </Grid>
          <Grid>
            {gestation?.isFinished ? (
              <Item>
                <Title>
                  {!gestation?.gestationType ||
                  gestation?.gestationType === 'Única'
                    ? `Peso do bebê`
                    : `Peso dos bebês`}
                </Title>
                <Description>
                  {gestation?.finalWeight
                    ? gestation?.finalWeight + ' g'
                    : 'Aguardando dados'}
                </Description>
              </Item>
            ) : null}
            <Item>
              <Title>
                {!gestation?.gestationType ||
                gestation?.gestationType === 'Única'
                  ? `Nome do bebê`
                  : `Nomes dos bebês`}
              </Title>
              <Description>{gestation?.name || 'Aguardando dados'}</Description>
            </Item>
          </Grid>
        </>
      </Box>
      <Box title="Risco Obstétrico">
        <>
          <ModalButton onClick={() => setToggleSeeMore(true)}>
            veja mais
          </ModalButton>
          {pregnant?.obstetricRisk ? (
            pregnant?.obstetricRisk.includes(',') ? (
              pregnant?.obstetricRisk
                ?.split(',')
                ?.map((item: string, index: number) => (
                  <Tag key={index}>{item.trim()}</Tag>
                ))
            ) : (
              <Tag>{pregnant?.obstetricRisk}</Tag>
            )
          ) : (
            'Não há registro'
          )}
          {toggleSeeMore && (
            <Modal
              className={String(toggleSeeMore)}
              onClick={() => setToggleSeeMore(!toggleSeeMore)}
              title="História Obstétrica"
            >
              <>
                <p>
                  <strong>Risco Obstétrico:</strong> <br />
                  {pregnant?.obstetricRisk || 'Não há registro'}
                </p>
                <p>
                  <strong>História patológica pregressa:</strong> <br />
                  {pregnant?.previousPathological || 'Não há registro'}
                </p>
                <p>
                  <strong>Antecedentes obstétricos:</strong> <br />
                  {pregnant?.obstetricBackground || 'Não há registro'}
                </p>
                <p>
                  <strong>Vacinas:</strong> <br />
                  {pregnant?.vaccines || 'Não há registro'}
                </p>
                <p>
                  <strong>Alergias:</strong> <br />
                  {pregnant?.allergy || 'Não há registro'}
                </p>
                <p>
                  <strong>Observações:</strong> <br />
                  {pregnant?.note || 'Não há registro'}
                </p>
              </>
            </Modal>
          )}
        </>
      </Box>
      <Box title="Medidas">
        <Grid>
          <Item>
            <Title>Altura</Title>
            <Description>
              {gestation?.height
                ? `${gestation?.height} cm`
                : 'Aguardando dados'}
            </Description>
          </Item>
          <Item>
            <Title>Peso pré-gravídico</Title>
            <Description>
              {gestation?.weight
                ? `${gestation?.weight} kg`
                : 'Aguardando dados'}
            </Description>
          </Item>
          <Item>
            <Title>IMC</Title>
            <Description>
              {imc(gestation?.height, gestation?.weight) || 'Aguardando dados'}
            </Description>
          </Item>
          <Item>
            <Title>Ganho de peso recomendado</Title>
            <Description>
              {weight.expected(gestation?.height, gestation?.weight) ||
                'Aguardando dados'}
            </Description>
          </Item>
        </Grid>
      </Box>
      <Box title="Informações Gerais">
        <Grid>
          <Item>
            <Title>DUM</Title>
            <Description>
              {gestation?.dum
                ? date.parse(gestation?.dum)
                : 'Aguardandos dados'}
            </Description>
          </Item>
          <Item>
            <Title>DPP</Title>
            <Description>
              {date.getSumByDay(gestation?.dum, 280) || 'Aguardando dados'}
            </Description>
          </Item>
          <Item>
            <Title>Data 1º USG</Title>
            <Description>
              {gestation?.d1usg
                ? date.parse(gestation?.d1usg)
                : 'Aguardandos dados'}
            </Description>
          </Item>
          <Item>
            <Title>DPP - 1º USG</Title>
            <Description>
              {gestation?.ig1usg && gestation?.d1usg
                ? date.getSumByDay(
                    gestation?.d1usg,
                    280 - 7 * gestation?.ig1usg,
                  )
                : 'Aguardandos dados'}
            </Description>
          </Item>
          <Item>
            <Title>IG 1º USG</Title>
            <Description>
              {gestation?.ig1usg && gestation?.d1usg
                ? parseFloat(gestation?.ig1usg).toFixed(1)
                : 'Aguardandos dados'}
            </Description>
          </Item>
          <Item>
            <Title>DPP USG</Title>
            <Description>
              {gestation?.dum
                ? `37º - ${date.getSumByDay(
                    gestation?.dum,
                    280 - 21,
                  )}\n38º - ${date.getSumByDay(
                    gestation?.dum,
                    280 - 14,
                  )}\n39º - ${date.getSumByDay(gestation?.dum, 280 - 7)}`
                : 'Aguardando dados'}
            </Description>
          </Item>
        </Grid>
        <PersonalDetailModal
          gestation={gestation}
          onClick={() => setToggleEdit(false)}
          open={!gestation?.isFinished && toggleEdit}
        />
        <FinishGestationModal
          pregnant={pregnant}
          gestation={gestation}
          onClick={() => setToggleEdit(false)}
          open={gestation?.isFinished && toggleEdit}
        />
      </Box>
    </PersonalDetail>
  )
}
