import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import UpdateEmailType from '../types/updateEmail'
import api from '../utils/api'
import dialog from '../utils/dialog'

const initialEmail = {
  currentEmail: '',
  newEmail: '',
  password: '',
} as UpdateEmailType

export default (): [UpdateEmailType, any, any] => {
  const history = useHistory()
  const [updateEmail, setEmail] = useState(initialEmail)

  const handleChangeEmail = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      const { name, value } = event?.target
      setEmail({ ...updateEmail, [String(name)]: value })
    },
    [updateEmail],
  )

  const handleSubmitEmail = useCallback(
    async event => {
      event?.preventDefault()
      event?.stopPropagation()
      const currentEmail = event?.target?.elements?.currentEmail?.value
      dialog?.confirm(() => {
        api
          .updateEmail({ ...updateEmail, currentEmail })
          .then(() => history?.go(0))
      })
    },
    [updateEmail, history],
  )

  return [updateEmail, handleChangeEmail, handleSubmitEmail]
}
