import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import RegisterDoctorType from '../types/registerDoctor'
import api from '../utils/api'
import storage from '../utils/storage'

const defaultRegister = {
  birthdate: '',
  cpf: '',
  crm: '',
  email: '',
  gender: 'male',
  name: '',
  password: '',
  phone: '',
  showPhone: false,
}

export default (): [RegisterDoctorType, any, any] => {
  const history = useHistory()

  const [register, setRegister] = useState({
    ...defaultRegister,
    ...storage.get('register'),
  })

  const handleChange = (event: any): void => {
    event?.preventDefault()
    event?.stopPropagation()
    let { name, value } = event?.target
    setRegister({ ...register, [String(name)]: value })
    storage.set('register', { ...register, [String(name)]: value })
  }

  const handleSubmit = (event: any): void => {
    event?.preventDefault()
    event?.stopPropagation()
    api.registerDoctor(register).then(response => {
      if (!response?.uid) return
      history?.push(`/login`)
      storage.remove('register')
    })
  }

  return [register, handleChange, handleSubmit]
}
