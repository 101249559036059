import dialog from '../utils/dialog'

const defaultMessage =
  'Há algo errado com nosso servidor. Por favor, tente novamente mais tarde'

function parseError (code: string): string {
  switch (code) {
    case 'auth/email-already-in-use':
      return 'O endereço de e-mail já está cadastrado.'
    case 'auth/missing-field':
      return 'Por favor, preencha todos os campos requeridos'
    case 'auth/invalid-email':
      return 'Email inválido'
    case 'auth/invalid-birthdate':
      return 'Data de nascimento inválido'
    case 'auth/invalid-cpf':
      return 'CPF inválido'
    case 'auth/duplicate-cpf':
      return 'Este CPF já está em uso. Por favor, verifique se o paciente ja foi inserido anteriormente.'
    case 'auth/invalid-phone':
      return 'Telefone inválido'
    case 'auth/wrong-password':
      return 'Senha inválida'
    case 'auth/user-not-found':
      return 'Usuário inválido ou excluído'
    case 'auth/recover-password':
      return 'Por favor, verifique seu email! Enviamos um link para você recuperar sua senha.'
    case 'auth/email-updated':
      return 'Seu email foi atualizado com sucesso'
    case 'register/missing-field':
      return 'Por favor, preencha os campos requeridos com asterisco (*).'
    case 'register/invalid-name':
      return 'Nome inválido'
    case 'register/invalid-email':
      return 'Email inválido'
    case 'register/invalid-cpf':
      return 'CPF inválido'
    case 'register/invalid-phone':
      return 'Telefone inválido'
    case 'register/invalid-height':
      return 'Altura inválido'
    case 'register/invalid-weight':
      return 'Peso inválido'
    case 'data/missing-field':
      return 'Obstetra não encontrado'
    case 'notes/invalid-user':
      return 'Usuário inválido'
    case 'notes/invalid-content':
      return 'Conteúdo inválido'
    case 'auth/unauthorized-email':
      return 'O email inserido não está autorizado para cadastro. Por favor, verifique com a equipe técnica do Segonha.'
    default:
      return defaultMessage
  }
}

export default (error: { code?: string }): void => {
  const message = !error?.code ? defaultMessage : parseError(error.code)
  dialog?.alert(message)
}
