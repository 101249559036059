import React from 'react'
import 'react-vis/dist/style.css'

import date from '../utils/date'
import AccompanimentType from '../types/accompaniment'
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  AreaSeries,
  LineSeries,
  LineMarkSeries,
  DiscreteColorLegend,
} from 'react-vis'
import imc from '../utils/imc'
import Row from './Row'
import Container from './Container'

type PropType = {
  dum?: string
  height?: string
  defaultAccompaniment?: AccompanimentType
  accompaniments?: AccompanimentType[]
}

const SUBTITTLES = [
  { title: 'Baixo Peso', color: '#DDD872', strokeWidth: '10' },
  { title: 'Adequado', color: '#BDFAC6', strokeWidth: '10' },
  { title: 'Sobrepeso', color: '#BEBBFE', strokeWidth: '10' },
  { title: 'Obesidade', color: '#FE94A2', strokeWidth: '10' },
]

const lowWeight = [
  { x: 6, y: 19.9, y0: 17 },
  { x: 8, y: 20.1, y0: 17 },
  { x: 10, y: 20.2, y0: 17 },
  { x: 11, y: 20.3, y0: 17 },
  { x: 12, y: 20.4, y0: 17 },
  { x: 13, y: 20.6, y0: 17 },
  { x: 14, y: 20.7, y0: 17 },
  { x: 15, y: 20.8, y0: 17 },
  { x: 16, y: 21, y0: 17 },
  { x: 17, y: 21.1, y0: 17 },
  { x: 18, y: 21.2, y0: 17 },
  { x: 19, y: 21.4, y0: 17 },
  { x: 20, y: 21.5, y0: 17 },
  { x: 21, y: 21.7, y0: 17 },
  { x: 22, y: 21.8, y0: 17 },
  { x: 23, y: 22, y0: 17 },
  { x: 24, y: 22.2, y0: 17 },
  { x: 25, y: 22.4, y0: 17 },
  { x: 26, y: 22.6, y0: 17 },
  { x: 27, y: 22.7, y0: 17 },
  { x: 28, y: 22.9, y0: 17 },
  { x: 29, y: 23.1, y0: 17 },
  { x: 30, y: 23.3, y0: 17 },
  { x: 31, y: 23.4, y0: 17 },
  { x: 32, y: 23.6, y0: 17 },
  { x: 33, y: 23.8, y0: 17 },
  { x: 34, y: 23.9, y0: 17 },
  { x: 35, y: 24.1, y0: 17 },
  { x: 36, y: 24.2, y0: 17 },
  { x: 37, y: 24.4, y0: 17 },
  { x: 38, y: 24.5, y0: 17 },
  { x: 39, y: 24.7, y0: 17 },
  { x: 40, y: 24.9, y0: 17 },
  { x: 41, y: 25, y0: 17 },
  { x: 42, y: 25, y0: 17 },
]

const optimalWeight = [
  { x: 6, y: 24.9, y0: 19.9 },
  { x: 8, y: 25, y0: 20.1 },
  { x: 10, y: 25.2, y0: 20.2 },
  { x: 11, y: 25.3, y0: 20.3 },
  { x: 12, y: 25.4, y0: 20.4 },
  { x: 13, y: 25.6, y0: 20.6 },
  { x: 14, y: 25.7, y0: 20.7 },
  { x: 15, y: 25.8, y0: 20.8 },
  { x: 16, y: 25.9, y0: 21 },
  { x: 17, y: 26, y0: 21.1 },
  { x: 18, y: 26.1, y0: 21.2 },
  { x: 19, y: 26.2, y0: 21.4 },
  { x: 20, y: 26.3, y0: 21.5 },
  { x: 21, y: 26.4, y0: 21.7 },
  { x: 22, y: 26.6, y0: 21.8 },
  { x: 23, y: 26.8, y0: 22 },
  { x: 24, y: 26.9, y0: 22.2 },
  { x: 25, y: 27, y0: 22.4 },
  { x: 26, y: 27.2, y0: 22.6 },
  { x: 27, y: 27.3, y0: 22.7 },
  { x: 28, y: 27.5, y0: 22.9 },
  { x: 29, y: 27.6, y0: 23.1 },
  { x: 30, y: 27.8, y0: 23.3 },
  { x: 31, y: 27.9, y0: 23.4 },
  { x: 32, y: 28, y0: 23.6 },
  { x: 33, y: 28.1, y0: 23.8 },
  { x: 34, y: 28.3, y0: 23.9 },
  { x: 35, y: 28.4, y0: 24.1 },
  { x: 36, y: 28.5, y0: 24.2 },
  { x: 37, y: 28.7, y0: 24.4 },
  { x: 38, y: 28.8, y0: 24.5 },
  { x: 39, y: 28.9, y0: 24.7 },
  { x: 40, y: 29.1, y0: 24.9 },
  { x: 41, y: 29.2, y0: 25 },
  { x: 42, y: 29.2, y0: 25 },
]

const overWeight = [
  { x: 6, y: 30, y0: 24.9 },
  { x: 8, y: 30.1, y0: 25 },
  { x: 10, y: 30.2, y0: 25.2 },
  { x: 11, y: 30.3, y0: 25.3 },
  { x: 12, y: 30.3, y0: 25.4 },
  { x: 13, y: 30.4, y0: 25.6 },
  { x: 14, y: 30.5, y0: 25.7 },
  { x: 15, y: 30.6, y0: 25.8 },
  { x: 16, y: 30.7, y0: 25.9 },
  { x: 17, y: 30.8, y0: 26 },
  { x: 18, y: 30.9, y0: 26.1 },
  { x: 19, y: 30.9, y0: 26.2 },
  { x: 20, y: 31, y0: 26.3 },
  { x: 21, y: 31.1, y0: 26.4 },
  { x: 22, y: 31.2, y0: 26.6 },
  { x: 23, y: 31.3, y0: 26.8 },
  { x: 24, y: 31.5, y0: 26.9 },
  { x: 25, y: 31.6, y0: 27 },
  { x: 26, y: 31.7, y0: 27.2 },
  { x: 27, y: 31.8, y0: 27.3 },
  { x: 28, y: 31.9, y0: 27.5 },
  { x: 29, y: 32, y0: 27.6 },
  { x: 30, y: 32.1, y0: 27.8 },
  { x: 31, y: 32.2, y0: 27.9 },
  { x: 32, y: 32.3, y0: 28 },
  { x: 33, y: 32.4, y0: 28.1 },
  { x: 34, y: 32.5, y0: 28.3 },
  { x: 35, y: 32.6, y0: 28.4 },
  { x: 36, y: 32.7, y0: 28.5 },
  { x: 37, y: 32.8, y0: 28.7 },
  { x: 38, y: 32.9, y0: 28.8 },
  { x: 39, y: 33, y0: 28.9 },
  { x: 40, y: 33.1, y0: 29.1 },
  { x: 41, y: 33.2, y0: 29.2 },
  { x: 42, y: 33.2, y0: 29.2 },
]

const obesityWeight = [
  { x: 6, y: 40, y0: 30 },
  { x: 8, y: 40, y0: 30.1 },
  { x: 10, y: 40, y0: 30.2 },
  { x: 11, y: 40, y0: 30.3 },
  { x: 12, y: 40, y0: 30.3 },
  { x: 13, y: 40, y0: 30.4 },
  { x: 14, y: 40, y0: 30.5 },
  { x: 15, y: 40, y0: 30.6 },
  { x: 16, y: 40, y0: 30.7 },
  { x: 17, y: 40, y0: 30.8 },
  { x: 18, y: 40, y0: 30.9 },
  { x: 19, y: 40, y0: 30.9 },
  { x: 20, y: 40, y0: 31 },
  { x: 21, y: 40, y0: 31.1 },
  { x: 22, y: 40, y0: 31.2 },
  { x: 23, y: 40, y0: 31.3 },
  { x: 24, y: 40, y0: 31.5 },
  { x: 25, y: 40, y0: 31.6 },
  { x: 26, y: 40, y0: 31.7 },
  { x: 27, y: 40, y0: 31.8 },
  { x: 28, y: 40, y0: 31.9 },
  { x: 29, y: 40, y0: 32 },
  { x: 30, y: 40, y0: 32.1 },
  { x: 31, y: 40, y0: 32.2 },
  { x: 32, y: 40, y0: 32.3 },
  { x: 33, y: 40, y0: 32.4 },
  { x: 34, y: 40, y0: 32.5 },
  { x: 35, y: 40, y0: 32.6 },
  { x: 36, y: 40, y0: 32.7 },
  { x: 37, y: 40, y0: 32.8 },
  { x: 38, y: 40, y0: 32.9 },
  { x: 39, y: 40, y0: 33 },
  { x: 40, y: 40, y0: 33.1 },
  { x: 41, y: 40, y0: 33.2 },
  { x: 42, y: 40, y0: 33.2 },
]

const tickValues = [
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
]

export default ({
  dum = '',
  height = '',
  defaultAccompaniment,
  accompaniments = [],
}: PropType) => {
  var array = []

  for (var i = 0; i < accompaniments.length; i++) array[i] = accompaniments[i]

  if (
    defaultAccompaniment &&
    defaultAccompaniment?.date &&
    defaultAccompaniment?.weight
  )
    array.push(defaultAccompaniment)

  var filterByWeight =
    array.length === 0
      ? null
      : array.filter(d => {
          return d?.weight != null && d?.weight.length > 0
        })

  var data =
    dum === '' || height === '' || filterByWeight == null
      ? null
      : filterByWeight.map(d => {
          var imcValue = imc(height, d?.weight)

          return {
            x: date.daysDiff(new Date(d.date), new Date(dum)) / 7,
            y: imcValue < 17 ? 17 : imcValue > 40 ? 40 : imcValue,
          }
        })

  var filterByAge =
    data == null
      ? null
      : data.filter(d => {
          return d.x >= 6 && d.x <= 42
        })

  filterByAge?.sort((a, b) => (a.x > b.x ? 1 : -1))

  return (
    <Container>
      <Row>
        <XYPlot margin={{ right: 45 }} width={900} height={400} color="black">
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis
            title="Tempo Gestacional (Em Semanas)"
            tickFormat={function tickFormat (d) {
              return new Intl.NumberFormat().format(d)
            }}
          />
          <YAxis
            title="I M C (Índice de Massa Corpórea)"
            tickValues={tickValues}
          />
          <YAxis
            title="I M C (Índice de Massa Corpórea)"
            orientation="right"
            tickValues={tickValues}
          />
          <AreaSeries color="#00000000" fill="#DDD8728C" data={lowWeight} />
          <AreaSeries color="#00000000" fill="#BDFAC68C" data={optimalWeight} />
          <AreaSeries color="#00000000" fill="#BEBBFE8C" data={overWeight} />
          <AreaSeries color="#00000000" fill="#FE94A28C" data={obesityWeight} />
          <LineSeries color="black" curve="curveNatural" data={lowWeight} />
          <LineSeries color="black" curve="curveNatural" data={optimalWeight} />
          <LineSeries color="black" curve="curveNatural" data={overWeight} />
          <LineMarkSeries
            style={{
              strokeWidth: '3px',
            }}
            lineStyle={{ stroke: 'blue' }}
            markStyle={{ stroke: 'blue' }}
            curve="curveNatural"
            data={filterByAge == null ? [] : filterByAge}
          />
        </XYPlot>
      </Row>
      <Row>
        <DiscreteColorLegend orientation="horizontal" items={SUBTITTLES} />
      </Row>
    </Container>
  )
}
