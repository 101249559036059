import React from 'react'
import styled from 'styled-components/macro'

import Icon from '../components/Icon'
import theme from '../utils/theme'

const Loading = styled.div`
  align-items: center;
  background: ${theme?.color?.secondary};
  bottom: 0;
  color: white;
  display: flex;
  left: 0;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
`
const LoadingBackground = styled.div`
  bottom: 0;
  left: 0;
  opacity: 0.9;
  position: fixed;
  right: 0;
  top: 0;
`
const LoadingIcon = styled(Icon)`
  animation-name: spin;
  animation-duration: 200ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  opacity: 0.5;
  @keyframes spin {
    from {
      transform: rotate(0deg) scale(3.5);
    }
    to {
      transform: rotate(360deg) scale(3.5);
    }
  }
`

export default () => (
  <Loading>
    <LoadingBackground />
    <LoadingIcon type="loading" />
  </Loading>
)
