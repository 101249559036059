import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  children?: any
  className?: string
}

const Column = styled.div`
  @media (max-width: 400px) {
    flex: 1 1 auto !important;
    margin-bottom: ${theme?.main?.spacing};
    max-width: 100% !important;
  }
`

export default ({ children = '', className = '' }: PropType) => (
  <Column className={`column ${className}`.trim()}>{children}</Column>
)
