import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Field from '../components/Field'
import NewGestationModal from '../components/NewGestationModal'
import Icon from '../components/Icon'
import usePregnant from '../hooks/pregnant'
import date from '../utils/date'
import theme from '../utils/theme'
import FinishGestationModal from './FinishGestationModal'

const PregnantProfile = styled.div`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  height: calc(100vh - 140px);
  padding: ${theme?.main?.spacing};
  position: relative;
`
const Scroll = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 100px;
`
const PregnantProfileCentering = styled(PregnantProfile)`
  align-items: center;
  display: flex;
  justify-content: center;
  svg {
    height: 500px;
  }
`
const Grid = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  @media (min-width: 480px) {
    & > * {
      width: 100%;
      & + * {
        margin-left: 20px;
      }
    }
  }
`
const Item = styled.div`
  padding: 5px 0;
  width: 70%;
`
const Label = styled.div`
  font-size: ${theme?.font?.small};
  font-weight: ${theme?.font?.bold};
  text-transform: uppercase;
`
const Value = styled.div`
  color: ${theme?.color?.tertiary};
  font-size: 18px;
  min-height: 20px;
  span {
    cursor: pointer;
    &:hover {
      color: ${theme?.color?.primary};
    }
    &:not(:last-child) {
      &:after {
        content: ',';
      }
    }
    & + span {
      margin-left: 20px;
    }
  }
`
const Footer = styled.div`
  align-items: center;
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
`
const CustomButton = styled(Button)`
  background: ${theme?.color?.primary};
  border-color: ${theme?.color?.primary};
  color: ${theme?.color?.initial};
  margin: 0;
  svg {
    fill: ${theme?.color?.initial};
    transform: translateY(-2px);
  }
`
const getGestationName = (
  value: number = -1,
  size: number = 0,
  isFinished: boolean = false,
) => {
  if (value === -1 || size === 0 || value === size - 1)
    return isFinished ? `Última Gestação` : `Gestação Atual`
  return `${value + 1}º Gestação`
}

export default () => {
  const history = useHistory()
  const { doctorId, pregnantId }: any = useParams()
  const [toggleAddModal, setToggleAddModal] = useState(false)
  const [toggleFinishModal, setToggleFinishModal] = useState(false)
  const [pregnant] = usePregnant()

  if (pregnant == null || !pregnantId)
    return (
      <PregnantProfileCentering>
        <Icon type="logo" />
      </PregnantProfileCentering>
    )

  return (
    <PregnantProfile>
      <Scroll>
        <Field placeholder="Informações da paciente" type="legend" />
        <Grid>
          <div>
            <Label>Nome</Label>
            <Value>{pregnant?.name}</Value>
          </div>
        </Grid>
        <Grid>
          <Item>
            <Label>Data de nascimento</Label>
            <Value>{date.parse(pregnant?.birthdate)}</Value>
          </Item>
          <Item>
            <Label>Idade</Label>
            <Value>{date.getAgeByYear(pregnant?.birthdate)}</Value>
          </Item>
          <Item>
            <Label>Naturalidade</Label>
            <Value>{pregnant?.naturality}</Value>
          </Item>
        </Grid>
        <Grid>
          <div>
            <Label>Endereço</Label>
            <Value>{pregnant?.address}</Value>
          </div>
        </Grid>
        <Grid>
          <Item>
            <Label>CPF</Label>
            <Value>{pregnant?.cpf}</Value>
          </Item>
          <Item>
            <Label>Telefone</Label>
            <Value>{pregnant?.phone}</Value>
          </Item>
          <Item>
            <Label>Plano de Saúde</Label>
            <Value>{pregnant?.healthPlans}</Value>
          </Item>
        </Grid>
        <Grid>
          <Item>
            <Label>Profissão</Label>
            <Value>{pregnant?.job}</Value>
          </Item>
          <Item>
            <Label>Estado Cívil</Label>
            <Value>{pregnant?.maritalStatus}</Value>
          </Item>
          <Item>
            <Label>Nome do Companheiro</Label>
            <Value>{pregnant?.husband}</Value>
          </Item>
        </Grid>
        <Grid>
          <div>
            <Label>Gestações</Label>
            <Value>
              {pregnant?.gestations
                ?.slice()
                .reverse()
                .map(({ uid, isFinished }, index, array) => (
                  <span
                    key={uid}
                    onClick={() =>
                      history?.push(
                        `/${doctorId}/pregnants/${pregnant?.uid}/gestations/${uid}`,
                      )
                    }
                  >
                    {getGestationName(index, array.length, isFinished)}
                  </span>
                ))}
            </Value>
          </div>
        </Grid>
        <br />
        <br />
        <Field label="História Obstétrica" type="legend" />
        <Grid>
          <Item>
            <Label>Risco Obstétrico</Label>
            <Value>{pregnant?.obstetricRisk}</Value>
          </Item>
          <Item>
            <Label>História patológica pregressa</Label>
            <Value>{pregnant?.previousPathological}</Value>
          </Item>
          <Item>
            <Label>Antecedentes obstétricos</Label>
            <Value>{pregnant?.obstetricBackground}</Value>
          </Item>
        </Grid>
        <Grid>
          <Item>
            <Label>Vacinas</Label>
            <Value>{pregnant?.vaccines}</Value>
          </Item>
          <Item>
            <Label>Alergias</Label>
            <Value>{pregnant?.allergy}</Value>
          </Item>
          <Item>
            <Label>Observações</Label>
            <Value>{pregnant?.note}</Value>
          </Item>
        </Grid>
      </Scroll>
      <Footer>
        <CustomButton
          onClick={() =>
            history?.push(`/${doctorId}/pregnants/${pregnant?.uid}/edit`)
          }
        >
          {/* <Icon type="edit" /> */}
          Editar
        </CustomButton>
        <div>
          <CustomButton
            onClick={() =>
              history?.push(
                `/${doctorId}/pregnants/${pregnant?.uid}/gestations/${pregnant?.gestations?.[0]?.uid}`,
              )
            }
          >
            Acompanhamento
          </CustomButton>{' '}
          {pregnant?.gestations?.[0]?.isFinished ? (
            <CustomButton onClick={() => setToggleAddModal(true)}>
              Adicionar nova gestação
            </CustomButton>
          ) : (
            <CustomButton onClick={() => setToggleFinishModal(true)}>
              Finalizar gestação atual
            </CustomButton>
          )}
          <NewGestationModal
            onClick={() => setToggleAddModal(false)}
            open={toggleAddModal}
          />
          <FinishGestationModal
            pregnant={pregnant}
            gestation={pregnant?.gestations?.[0]}
            onClick={() => setToggleFinishModal(false)}
            open={toggleFinishModal}
          />
        </div>
      </Footer>
    </PregnantProfile>
  )
}
