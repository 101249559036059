import React from 'react'
import 'react-vis/dist/style.css'

import date from '../utils/date'
import AccompanimentType from '../types/accompaniment'
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
  LineMarkSeries,
  DiscreteColorLegend,
} from 'react-vis'
import Row from './Row'
import Container from './Container'

type PropType = {
  dum?: string
  defaultAccompaniment?: AccompanimentType
  accompaniments?: AccompanimentType[]
}

const SUBTITTLES = [
  { title: 'Percentil 10', color: 'red', strokeWidth: '10' },
  { title: 'Percentil 50', color: 'green', strokeWidth: '10' },
  { title: 'Percentil 90', color: 'red', strokeWidth: '10' },
]

const tenPercent = [
  { x: 20, y: 18.8 },
  { x: 21, y: 19.7 },
  { x: 22, y: 20.6 },
  { x: 23, y: 21.5 },
  { x: 24, y: 22.4 },
  { x: 25, y: 23.2 },
  { x: 26, y: 24 },
  { x: 27, y: 24.8 },
  { x: 28, y: 25.6 },
  { x: 29, y: 26.3 },
  { x: 30, y: 27.1 },
  { x: 31, y: 27.8 },
  { x: 32, y: 28.5 },
  { x: 33, y: 29.2 },
  { x: 34, y: 29.8 },
  { x: 35, y: 30.4 },
  { x: 36, y: 31.1 },
  { x: 37, y: 31.7 },
  { x: 38, y: 32.2 },
  { x: 39, y: 32.8 },
  { x: 40, y: 33.3 },
  { x: 41, y: 33.8 },
  { x: 42, y: 34.3 },
]

const fiftyPercent = [
  { x: 20, y: 20.7 },
  { x: 21, y: 21.6 },
  { x: 22, y: 22.5 },
  { x: 23, y: 23.4 },
  { x: 24, y: 24.2 },
  { x: 25, y: 25.1 },
  { x: 26, y: 25.9 },
  { x: 27, y: 26.7 },
  { x: 28, y: 27.5 },
  { x: 29, y: 28.2 },
  { x: 30, y: 29 },
  { x: 31, y: 29.7 },
  { x: 32, y: 30.4 },
  { x: 33, y: 31 },
  { x: 34, y: 31.7 },
  { x: 35, y: 32.4 },
  { x: 36, y: 32.9 },
  { x: 37, y: 33.5 },
  { x: 38, y: 34.1 },
  { x: 39, y: 34.7 },
  { x: 40, y: 35.2 },
  { x: 41, y: 35.7 },
  { x: 42, y: 36.2 },
]

const ninetyPercent = [
  { x: 20, y: 22.6 },
  { x: 21, y: 23.5 },
  { x: 22, y: 24.4 },
  { x: 23, y: 25.3 },
  { x: 24, y: 26.1 },
  { x: 25, y: 27 },
  { x: 26, y: 27.8 },
  { x: 27, y: 28.6 },
  { x: 28, y: 29.4 },
  { x: 29, y: 30.1 },
  { x: 30, y: 30.8 },
  { x: 31, y: 31.6 },
  { x: 32, y: 32.2 },
  { x: 33, y: 32.9 },
  { x: 34, y: 33.6 },
  { x: 35, y: 34.2 },
  { x: 36, y: 34.8 },
  { x: 37, y: 35.4 },
  { x: 38, y: 36 },
  { x: 39, y: 36.6 },
  { x: 40, y: 37.1 },
  { x: 41, y: 37.6 },
  { x: 42, y: 38.1 },
]

export default ({
  dum = '',
  defaultAccompaniment,
  accompaniments = [],
}: PropType) => {
  var array = []

  for (var i = 0; i < accompaniments.length; i++) array[i] = accompaniments[i]

  if (
    defaultAccompaniment &&
    defaultAccompaniment?.date &&
    defaultAccompaniment?.uterineHeight
  )
    array.push(defaultAccompaniment)

  var tickTotal
  var tickValues
  var filter

  var filterTenPercent
  var filterFiftyPercent
  var filterNinetyPercent

  tickTotal = 23
  tickValues = [
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
  ]

  filter =
    dum === ''
      ? null
      : array.filter(d => {
          var ig1usg = date.daysDiff(new Date(d.date), new Date(dum)) / 7
          return (
            d.uterineHeight != null &&
            d.uterineHeight.length > 0 &&
            ig1usg >= 20 &&
            ig1usg <= 42
          )
        })

  filterTenPercent = tenPercent.filter(d => {
    return d.x >= 20 && d.x <= 42
  })

  filterFiftyPercent = fiftyPercent.filter(d => {
    return d.x >= 20 && d.x <= 42
  })

  filterNinetyPercent = ninetyPercent.filter(d => {
    return d.x >= 20 && d.x <= 42
  })

  var data =
    filter == null
      ? null
      : filter.map(d => {
          return {
            x: date.daysDiff(new Date(d.date), new Date(dum)) / 7,
            y:
              d.uterineHeight == null || d.uterineHeight === ''
                ? 0
                : parseFloat(d.uterineHeight),
          }
        })

  data?.sort((a, b) => (a.x > b.x ? 1 : -1))

  return (
    <Container>
      <Row>
        <XYPlot margin={{ left: 45 }} width={900} height={300} color="black">
          <VerticalGridLines tickTotal={tickTotal} />
          <HorizontalGridLines />
          <XAxis
            title="Idade Gestacional (Em Semanas)"
            tickValues={tickValues}
            tickFormat={function tickFormat (d) {
              return new Intl.NumberFormat().format(d)
            }}
          />
          <YAxis title="Altura Uterina (Em Centímetros)" />
          <LineSeries
            color="red"
            curve="curveNatural"
            data={filterTenPercent}
          />
          <LineSeries
            color="green"
            curve="curveNatural"
            data={filterFiftyPercent}
          />
          <LineSeries
            color="red"
            curve="curveNatural"
            data={filterNinetyPercent}
          />
          <LineMarkSeries
            style={{
              strokeWidth: '3px',
            }}
            lineStyle={{ stroke: 'blue' }}
            markStyle={{ stroke: 'blue' }}
            curve="curveNatural"
            data={data == null ? [] : data}
          />
        </XYPlot>
      </Row>
      <Row>
        <DiscreteColorLegend orientation="horizontal" items={SUBTITTLES} />
      </Row>
    </Container>
  )
}
