import 'firebase/auth'
import 'firebase/database'
import { auth, database, initializeApp } from 'firebase/app'

import ConfigType from '../types/config'
import ConsultationType from '../types/consultation'
import DoctorType from '../types/doctor'
import GestationType from '../types/gestation'
import LoginType from '../types/login'
import PregnantType from '../types/pregnant'
import RegisterDoctorType from '../types/registerDoctor'
import RegisterGestationType from '../types/registerGestation'
import RegisterPregnantType from '../types/registerPregnant'
import MainUltrasonogramType from '../types/mainUltrasonogram'
import UpdateEmailType from '../types/updateEmail'
import birthdateUtils from '../utils/birthdate'
import cpfUtils from '../utils/cpf'
import dateUtils from '../utils/date'
import dialog from '../utils/dialog'
import normalize from '../utils/normalize'
import number from '../utils/number'
import phoneUtil from '../utils/phone'
import warning from '../utils/warning'

export const initialize = () =>
  initializeApp({
    apiKey: process?.env?.REACT_APP_FIREBASE_KEY,
    authDomain: process?.env?.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process?.env?.REACT_APP_FIREBASE_DATABASE,
    projectId: process?.env?.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process?.env?.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process?.env?.REACT_APP_FIREBASE_SENDER_ID,
  })

export const get = async (reference: string) => {
  try {
    const snapshot: any = await database()
      ?.ref(reference)
      ?.once('value')
    return snapshot?.val() || {}
  } catch (error) {
    warning(error)
  }
}

export const post = async (reference: string, value: any) => {
  try {
    const date = dateUtils?.getNewDate()
    await database()
      ?.ref(reference)
      ?.set({
        ...value,
        createdAt: date,
        updatedAt: date,
      })
    return value
  } catch (error) {
    warning(error)
  }
}

export const sync = async (reference: string) => {
  try {
    return new Promise(resolve => {
      database()
        ?.ref()
        ?.child(reference)
        ?.on('value', (response: any) => resolve(response.val()))
    })
  } catch (error) {
    warning(error)
  }
}

export const update = async (reference: string, value: any) => {
  try {
    const updatedAt = dateUtils?.getNewDate()
    const oldValue = await get(reference)
    if (!value?.createdAt) value.createdAt = updatedAt
    const newValue = { ...oldValue, ...value, updatedAt }
    await database()
      ?.ref(reference)
      ?.set(newValue)
    return newValue
  } catch (error) {
    warning(error)
  }
}

export const getAuth = (callback: any) => {
  try {
    return auth()?.onAuthStateChanged(callback)
  } catch (error) {
    warning(error)
  }
}

export const getConfig = async (): Promise<ConfigType | any> => {
  try {
    const config = await get(`/config`)
    return normalize?.config(config)
  } catch (error) {
    warning(error)
  }
}

export const getDoctor = async (
  doctorId: string = '',
): Promise<DoctorType | any> => {
  try {
    return await get(`/doctors/${doctorId}`)
  } catch (error) {
    warning(error)
  }
}

export const getPregnant = async (
  pregnantId: string,
): Promise<PregnantType | any> => {
  try {
    const pregnant = await get(`/pregnants/${pregnantId}`)
    return normalize?.pregnant(pregnant)
  } catch (error) {
    warning(error)
  }
}

export const getPregnantsByDoctor = async (
  doctor?: DoctorType,
): Promise<Array<PregnantType> | any> => {
  if (!doctor?.pregnants) return []
  try {
    const pregnants = await get(`/pregnants`)
    return normalize
      ?.parseArray(pregnants)
      ?.filter(
        ({ uid }: any) =>
          uid && Object.keys(doctor?.pregnants || {})?.includes(uid),
      )
      ?.map(pregnant => normalize?.pregnant(pregnant))
      ?.sort(
        // @ts-ignore
        (a, b) => new Date(b?.updatedAt || '') - new Date(a?.updatedAt || ''),
      ) as Array<PregnantType>
  } catch (error) {
    warning(error)
  }
}

export const login = async ({
  email = '',
  password = '',
}: LoginType): Promise<string | any> => {
  try {
    const response = await auth()?.signInWithEmailAndPassword(email, password)
    const doctor = await getDoctor(response?.user?.uid)
    if (!doctor?.crm) return Promise?.reject(null)
    return doctor
  } catch (error) {
    warning(error)
  }
}

export const logout = async () => {
  try {
    await auth()?.signOut()
  } catch (error) {
    warning(error)
  }
}

export const recover = async (email: string) => {
  try {
    await auth()?.sendPasswordResetEmail(email)
    dialog?.alert(
      'Por favor, verifique seu email! Enviamos um link para você recuperar sua senha. ',
    )
  } catch (error) {
    warning(error)
  }
}

export const registerDoctor = async ({
  birthdate = '',
  cpf = '',
  crm = '',
  email = '',
  gender = '',
  name = '',
  password = '',
  phone = '',
  showPhone = true,
}: RegisterDoctorType): Promise<DoctorType | any> => {
  if (
    !birthdate ||
    !cpf ||
    !crm ||
    !email ||
    !gender ||
    !name ||
    !password ||
    !phone
  ) {
    warning({ code: 'register/missing-field' })
    return Promise?.reject(null)
  }
  if (!birthdateUtils?.validation(birthdate)) {
    warning({ code: 'auth/invalid-birthdate' })
    return Promise?.reject(null)
  }
  if (!cpfUtils.validation(cpf)) {
    warning({ code: 'auth/invalid-cpf' })
    return Promise?.reject(null)
  }
  if (!phoneUtil.validation(phone)) {
    warning({ code: 'auth/invalid-phone' })
    return Promise?.reject(null)
  }
  if (!(await authorized(email))) {
    warning({ code: 'auth/unauthorized-email' })
    return Promise?.reject(null)
  }

  try {
    const response = await auth()?.createUserWithEmailAndPassword(
      email,
      password,
    )
    const date = dateUtils?.getNewDate()
    return await post(`/doctors/${response?.user?.uid || cpf}`, {
      birthdate,
      cpf,
      crm,
      gender,
      name,
      phone,
      showPhone,
      uid: response?.user?.uid,
      createdAt: date,
      updatedAt: date,
    })
  } catch (error) {
    warning(error)
  }
}

export const registerGestation = async (
  doctorId: string,
  pregnantId: string,
  {
    height = '',
    weight = '',
    name = '',
    birthType = 'Vaginal',
    place = '',
    dum = '',
    d1usg = '',
    ig1usg = '',
    obstetricRisk = '',
    previousPathological = '',
    obstetricBackground = '',
    vaccines = '',
    allergy = '',
    note = '',
    gestationType = 'Única',
    isFinished = false,
  }: RegisterGestationType,
): Promise<RegisterGestationType | any> => {
  if (!doctorId || !pregnantId) {
    warning({ code: 'register/missing-field' })
    return Promise?.reject(null)
  }

  try {
    await update(`/pregnants/${pregnantId}`, {
      obstetricRisk,
      previousPathological,
      obstetricBackground,
      vaccines,
      allergy,
      note,
    })
    const uid = String(Date.now())
    await post(`/pregnants/${pregnantId}/gestations/${uid}`, {
      height,
      weight,
      name,
      birthType,
      place,
      dum,
      d1usg,
      ig1usg,
      uid,
      gestationType,
      isFinished,
    })
    return { uid }
  } catch (error) {
    warning(error)
  }
}

export const registerPregnant = async (
  doctorId: string,
  pregnant: RegisterPregnantType,
  pregnants: PregnantType[],
): Promise<PregnantType | any> => {
  if (
    !doctorId ||
    !pregnant?.name ||
    !pregnant?.birthdate ||
    !pregnant?.cpf ||
    !pregnant?.email ||
    !pregnant?.phone
  ) {
    warning({ code: 'register/missing-field' })
    return Promise?.reject(null)
  }
  if (!birthdateUtils?.validation(pregnant?.birthdate)) {
    warning({ code: 'auth/invalid-birthdate' })
    return Promise?.reject(null)
  }
  if (!cpfUtils.validation(pregnant?.cpf)) {
    warning({ code: 'auth/invalid-cpf' })
    return Promise?.reject(null)
  }
  if (!phoneUtil.validation(pregnant?.phone)) {
    warning({ code: 'auth/invalid-phone' })
    return Promise?.reject(null)
  }
  if (pregnants != null && pregnants?.length > 0) {
    for (var i = 0; i < pregnants?.length; i++)
      if (pregnants[i]?.cpf && pregnants[i]?.cpf === pregnant?.cpf) {
        warning({ code: 'auth/duplicate-cpf' })
        return Promise?.reject(null)
      }
  }

  try {
    const newDate = dateUtils?.getNewDate()
    const gestationId = String(Date.now())
    const fallback = String(number?.parse(pregnant?.cpf || Date.now()))
    const { user } = (await auth()?.createUserWithEmailAndPassword(
      pregnant?.email,
      fallback,
    )) || { user: { uid: fallback } }
    const newPregnant = {
      name: pregnant?.name,
      birthdate: pregnant?.birthdate,
      cpf: pregnant?.cpf,
      phone: pregnant?.phone,
      address: pregnant?.address,
      naturality: pregnant?.naturality,
      job: pregnant?.job,
      maritalStatus: pregnant?.maritalStatus,
      husband: pregnant?.husband,
      healthPlans: pregnant?.healthPlans,
      doctor: doctorId,
      obstetricRisk: pregnant?.obstetricRisk,
      previousPathological: pregnant?.previousPathological,
      obstetricBackground: pregnant?.obstetricBackground,
      vaccines: pregnant?.vaccines,
      allergy: pregnant?.allergy,
      note: pregnant?.note,
      gestations: {
        [gestationId]: {
          uid: gestationId,
          name: pregnant?.baby,
          birthType: pregnant?.birthType,
          place: pregnant?.place,
          dum: pregnant?.dum,
          d1usg: pregnant?.d1usg,
          ig1usg: pregnant?.ig1usg,
          height: pregnant?.height,
          weight: pregnant?.weight,
          gestationType: pregnant?.gestationType,
          isFinished: pregnant?.isFinished,
          createdAt: newDate,
          updatedAt: newDate,
        },
      },
      uid: user?.uid,
      createdAt: newDate,
      updatedAt: newDate,
    }
    await post(`/pregnants/${user?.uid}`, newPregnant)
    await post(`/doctors/${doctorId}/pregnants/${newPregnant?.uid}`, 0)
    await auth()?.sendPasswordResetEmail(pregnant?.email)

    return normalize?.pregnant(newPregnant)
  } catch (error) {
    warning(error)
  }
}

export const registerConsultation = async ({
  gestationId = '',
  pregnantId = '',
  consultation,
}: {
  gestationId: string
  pregnantId: string
  consultation: ConsultationType
}) => {
  if (!pregnantId || !gestationId || !consultation) {
    warning({ code: '' })
    return Promise?.reject(null)
  }
  const hasLength = (value: any): boolean => {
    return normalize?.parseArray(value)?.filter((item: any) => item.length)
      .length > 1
      ? true
      : false
  }
  let {
    accompaniment,
    exam,
    mainUltrasonograms,
    ultrasonogram,
  }: any = consultation

  if (!ultrasonogram?.date) ultrasonogram = {}
  if (!exam?.date || !hasLength(exam)) exam = {}
  if (!accompaniment?.date || !hasLength(accompaniment)) accompaniment = {}

  try {
    const uid = String(Date.now())

    if (ultrasonogram && typeof ultrasonogram?.status === 'undefined')
      ultrasonogram.status = 'true'

    if (!!ultrasonogram?.date)
      await post(
        `/pregnants/${pregnantId}/gestations/${gestationId}/ultrasonograms/${uid}`,
        ultrasonogram,
      )
    if (!!exam?.date)
      await post(
        `/pregnants/${pregnantId}/gestations/${gestationId}/exams/${uid}`,
        exam,
      )
    if (!!accompaniment?.date)
      await post(
        `/pregnants/${pregnantId}/gestations/${gestationId}/accompaniments/${uid}`,
        accompaniment,
      )

    await updateMainUltrasonograms({
      gestationId,
      pregnantId,
      mainUltrasonograms,
    })

    return Promise?.resolve(consultation)
  } catch (error) {
    warning(error)
  }
}

export const updateMainUltrasonograms = async ({
  gestationId = '',
  pregnantId = '',
  mainUltrasonograms,
}: {
  gestationId: string
  pregnantId: string
  mainUltrasonograms: MainUltrasonogramType
}) => {
  if (!gestationId || !pregnantId || !mainUltrasonograms) return
  let { morfo1t, morfo2t, ecofetal, doppler } = mainUltrasonograms

  try {
    if (!!morfo1t?.date)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo1t`,
        { date: morfo1t?.date },
      )
    if (!!morfo1t?.status)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo1t`,
        { status: morfo1t?.status },
      )
    if (!!morfo1t?.igDay)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo1t`,
        { igDay: morfo1t?.igDay },
      )
    if (!!morfo1t?.igWeek)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo1t`,
        { igWeek: morfo1t?.igWeek },
      )
    if (!!morfo1t?.details)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo1t`,
        { details: morfo1t?.details?.trim() },
      )

    if (!!morfo2t?.date)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo2t`,
        { date: morfo2t?.date },
      )
    if (!!morfo2t?.date)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo2t`,
        { status: morfo2t?.status },
      )
    if (!!morfo2t?.igDay)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo2t`,
        { igDay: morfo2t?.igDay },
      )
    if (!!morfo2t?.igWeek)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo2t`,
        { igWeek: morfo2t?.igWeek },
      )
    if (!!morfo2t?.details)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/morfo2t`,
        { details: morfo2t?.details },
      )

    if (!!ecofetal?.date)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/ecofetal`,
        { date: ecofetal?.date },
      )
    if (!!ecofetal?.date)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/ecofetal`,
        { status: ecofetal?.status },
      )
    if (!!ecofetal?.igDay)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/ecofetal`,
        { igDay: ecofetal?.igDay },
      )
    if (!!ecofetal?.igWeek)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/ecofetal`,
        { igWeek: ecofetal?.igWeek },
      )
    if (!!ecofetal?.details)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/ecofetal`,
        { details: ecofetal?.details },
      )

    if (!!doppler?.date)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/doppler`,
        { date: doppler?.date },
      )
    if (!!doppler?.status)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/doppler`,
        { status: doppler?.status },
      )
    if (!!doppler?.igDay)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/doppler`,
        { igDay: doppler?.igDay },
      )
    if (!!doppler?.igWeek)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/doppler`,
        { igWeek: doppler?.igWeek },
      )
    if (!!doppler?.details)
      await update(
        `/pregnants/${pregnantId}/gestations/${gestationId}/mainUltrasonograms/doppler`,
        { details: doppler?.details },
      )

    return Promise?.resolve(mainUltrasonograms)
  } catch (error) {
    warning(error)
  }
}

export const updateEmail = async ({
  currentEmail = '',
  newEmail = '',
  password = '',
}: UpdateEmailType) => {
  try {
    await auth()?.signInWithEmailAndPassword(currentEmail, password)
    await auth()?.currentUser?.updateEmail(newEmail)
    return newEmail
  } catch (error) {
    warning(error)
  }
}

export const updateDoctor = async ({
  birthdate,
  cpf,
  crm,
  gender,
  name,
  phone,
  showPhone,
  uid,
}: DoctorType | any): Promise<DoctorType | any> => {
  if (
    !birthdate ||
    !cpf ||
    !crm ||
    !gender ||
    !name ||
    !phone ||
    !showPhone ||
    !uid
  ) {
    warning({ code: 'auth/missing-field' })
    return Promise?.reject(null)
  }
  if (!birthdateUtils?.validation(birthdate)) {
    warning({ code: 'auth/invalid-birthdate' })
    return Promise?.reject(null)
  }
  if (!cpfUtils.validation(cpf)) {
    warning({ code: 'auth/invalid-cpf' })
    return Promise?.reject(null)
  }
  if (!phoneUtil.validation(phone)) {
    warning({ code: 'auth/invalid-phone' })
    return Promise?.reject(null)
  }

  try {
    return await update(`/doctors/${uid}`, {
      birthdate,
      cpf,
      crm,
      gender,
      name,
      phone,
      showPhone,
    })
  } catch (error) {
    warning(error)
  }
}

export const updateGestation = async ({
  pregnantId = '',
  gestationId = '',
  ...gestation
}: {
  pregnantId: string
  gestationId: string
} & GestationType) => {
  if (!pregnantId || !gestationId) {
    warning({ code: 'auth/missing-field' })
    return Promise?.reject(null)
  }

  try {
    await update(
      `/pregnants/${pregnantId}/gestations/${gestationId}`,
      gestation,
    )
    return gestation
  } catch (error) {
    warning(error)
  }
}

export const finishGestation = async ({
  pregnantId = '',
  gestationId = '',
  ...gestation
}: {
  pregnantId: string
  gestationId: string
  deliveryDate: string
  place: string
  finalWeight: string
  result: string
}) => {
  if (!pregnantId || !gestationId) {
    warning({ code: 'auth/missing-field' })
    return Promise?.reject(null)
  }

  try {
    await update(`/pregnants/${pregnantId}/gestations/${gestationId}`, {
      isFinished: true,
      result: gestation.result,
      finalWeight: gestation.finalWeight,
      deliveryDate: gestation.deliveryDate,
      place: gestation.place,
    })
    return gestation
  } catch (error) {
    warning(error)
  }
}

export const updatePregnant = async (
  pregnant: PregnantType,
  pregnants: PregnantType[] | null,
  saveBackgroundOnly: boolean,
): Promise<PregnantType | any> => {
  if (!saveBackgroundOnly) {
    if (
      !pregnant?.name ||
      !pregnant?.birthdate ||
      !pregnant?.cpf ||
      !pregnant?.phone
    ) {
      warning({ code: 'register/missing-field' })
      return Promise?.reject(null)
    }
    if (!birthdateUtils?.validation(pregnant?.birthdate)) {
      warning({ code: 'auth/invalid-birthdate' })
      return Promise?.reject(null)
    }
    if (!cpfUtils.validation(pregnant?.cpf)) {
      warning({ code: 'auth/invalid-cpf' })
      return Promise?.reject(null)
    }
    if (!phoneUtil.validation(pregnant?.phone)) {
      warning({ code: 'auth/invalid-phone' })
      return Promise?.reject(null)
    }
    if (pregnants != null && pregnants?.length > 0) {
      for (var i = 0; i < pregnants?.length; i++)
        if (
          pregnants[i]?.uid !== pregnant?.uid &&
          pregnants[i]?.cpf &&
          pregnants[i]?.cpf === pregnant?.cpf
        ) {
          warning({ code: 'auth/duplicate-cpf' })
          return Promise?.reject(null)
        }
    }
  }

  try {
    await update(`/pregnants/${pregnant?.uid}`, {
      name: pregnant?.name,
      birthdate: pregnant?.birthdate,
      cpf: pregnant?.cpf,
      phone: pregnant?.phone,
      address: pregnant?.address,
      naturality: pregnant?.naturality,
      job: pregnant?.job,
      maritalStatus: pregnant?.maritalStatus,
      husband: pregnant?.husband,
      healthPlans: pregnant?.healthPlans,
      obstetricRisk: pregnant?.obstetricRisk,
      previousPathological: pregnant?.previousPathological,
      obstetricBackground: pregnant?.obstetricBackground,
      vaccines: pregnant?.vaccines,
      allergy: pregnant?.allergy,
      note: pregnant?.note,
    })
    return Promise?.resolve(pregnant)
  } catch (error) {
    warning(error)
  }
}

export const authorized = async (email: string = '') => {
  try {
    const users = await get(`/authorized`)
    return normalize
      ?.authorizedUsers(users)
      ?.some(user => email.toLowerCase() === user.toLowerCase())
  } catch (error) {
    return false
  }
}

export default {
  getAuth,
  getConfig,
  getDoctor,
  getPregnant,
  getPregnantsByDoctor,
  initialize,
  login,
  logout,
  recover,
  registerDoctor,
  registerGestation,
  registerPregnant,
  registerConsultation,
  updateEmail,
  updateDoctor,
  updateGestation,
  updatePregnant,
  finishGestation,
}
